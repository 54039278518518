import axios from 'axios';
import * as jQuery from 'jquery';
import {get, map, range, sum} from 'lodash';
import * as React from 'react';
import {Repository} from '../../repository/repository';
import {ModalComponent} from '../modal/modal.component';
import './multi-upload.scss';
import {ProgressTileComponent} from './progress-tile.component';

const CancelToken = axios.CancelToken;

export class MultiUploadComponent extends React.Component<any, any> {
    private modalRef: ModalComponent;
    private imageInputRef: HTMLInputElement;
    private audioInputRef: HTMLInputElement;
    private progresses: any = {};

    public state: any = {
        overAllProgress: 0,
        error: false,
        success: false,
        preventClose: false,
        createMediaType: this.props.createMediaType,
        videoUrl: '',
        mustHaveImage: '',
        mustHaveAudio: ''
    };

    private updateVideoUrl(event: any) {
        this.setState({
            videoUrl: event.target.value
        });
    }

    private getBase64FromFile(file: File): Promise<string> {
        // @ts-ignore
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', (event: any) => resolve(event.target.result));
            reader.addEventListener('error', (err) => reject(err));
            reader.readAsDataURL(file);
        });
    }

    private async startUpload(base64Data: string) {
        const uploadId = 'upload-' + Math.floor(Math.random() * 100000000);

        this.progresses[uploadId] = {
            cancelToken: CancelToken.source(),
            data: base64Data,
            progress: 0
        };

        try {
            const response: any = await Repository.post('/media-item', {
                media: this.progresses[uploadId].data
            }, {
                cancelToken: this.progresses[uploadId].cancelToken.token,
                onUploadProgress: (progressEvent: any) => {
                    this.progresses[uploadId].progress = progressEvent.loaded / progressEvent.total;
                    this.calcOverAllProgress();
                }
            });

            // @ts-ignore
            return Promise.resolve(response);
        } catch (err) {
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    private calcOverAllProgress() {
        try {
            this.setState({
                overAllProgress: (Math.floor((sum(map(this.progresses, 'progress')) / (Object.keys(this.progresses).length)) * 100)) || 0
            });
        } catch (e) {
            this.setState({
                overAllProgress: 0
            });
        }
    }

    private toggleMediaSelector(inputRef: HTMLInputElement): Promise<string[]> {
        // @ts-ignore
        return new Promise((resolve, reject) => {
            if (inputRef) {
                const elementRef = jQuery(inputRef);

                document.body.onfocus = (event: any) => {
                    setTimeout(() => {
                        console.log(elementRef);
                        elementRef.val('');
                        elementRef.unbind('change');

                        this.setState({
                            preventClose: false
                        });
                    }, 100);
                };

                // @ts-ignore
                elementRef.on('change', async (event: any) => {
                    const fileList: FileList = (event.target.files as FileList);

                    setTimeout(() => {
                        this.setState({
                            preventClose: true
                        });
                    }, 100);

                    const files: any[] = range(fileList.length).map((index: number) => fileList.item(index));
                    // @ts-ignore
                    const base64s = await Promise.all(files.map(async (file: File) => {
                        return this.getBase64FromFile(file);
                    }));

                    jQuery(inputRef).val('');
                    jQuery(inputRef).unbind('change');
                    resolve(base64s);
                });
                this.setState({progress: 1});
                elementRef.trigger('click');
            }
        });
    }

    async handleUploadImageClick() {
        try {
            this.setState({
                error: false,
                success: false,
                overAllProgress: 0,
                preventClose: true
            });

            await this.toggleMediaSelector(this.imageInputRef).then(async (datas) => {
                console.log('promiseResolve: ', datas);
                // @ts-ignore
                let results = await Promise.all(datas.map(async (data: string) => this.startUpload(data)));

                this.progresses = {};
                this.calcOverAllProgress();

                if (this.state.createMediaType) {
                    // @ts-ignore
                    results = await Promise.all((results || []).map(async (mi: any) => {
                        return Repository.post('/media-type', {
                            media: mi['@id'],
                            type: 'image'
                        });
                    }));
                } else {
                    results = (results || []).map((mi: any) => ({
                        media: mi,
                        type: 'image'
                    }));
                }

                if (this.props.onSuccess) {
                    this.props.onSuccess(results);
                }

                this.setState({
                    error: false,
                    success: true,
                    preventClose: false
                }, () => {
                    setTimeout(() => {
                        this.modalRef.close();
                    }, 1000);
                });
            });
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            map(this.progresses, (progress: any) => {
                progress.cancelToken.cancel();
            });

            this.progresses = {};
            this.calcOverAllProgress();

            this.setState({
                error: true,
                success: false,
                preventClose: false
            });
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    async handleUploadAudioClick() {
        try {
            this.setState({
                error: false,
                success: false,
                overAllProgress: 0,
                preventClose: true
            });

            const datas = await this.toggleMediaSelector(this.audioInputRef);
            // @ts-ignore
            let results = await Promise.all(datas.map(async (data: string) => this.startUpload(data)));

            this.progresses = {};
            this.calcOverAllProgress();

            if (this.state.createMediaType) {
                // @ts-ignore
                results = await Promise.all((results || []).map(async (mi: any) => {
                    return Repository.post('/media-type', {
                        media: mi['@id'],
                        type: 'audio'
                    });
                }));
            } else {
                results = (results || []).map((mi: any) => ({
                    media: mi,
                    type: 'audio'
                }));
            }

            if (this.props.onSuccess) {
                this.props.onSuccess(results);
            }

            this.setState({
                error: false,
                success: true,
                preventClose: false
            }, () => {
                setTimeout(() => {
                    this.modalRef.close();
                }, 1000);
            });

            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            map(this.progresses, (progress: any) => {
                progress.cancelToken.cancel();
            });

            this.progresses = {};
            this.calcOverAllProgress();

            this.setState({
                error: true,
                success: false,
                preventClose: false
            }, () => {
                setTimeout(() => {
                    this.modalRef.close();
                }, 1000);
            });
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    async handleVideoClick() {
        try {
            this.setState({
                error: false,
                success: false,
                overAllProgress: 0,
                preventClose: true
            });

            this.progresses = {};
            this.calcOverAllProgress();

            const result = await Repository.post('/media-type', {
                type: 'video',
                videoUrl: this.state.videoUrl
            });

            if (this.props.onSuccess) {
                this.props.onSuccess([result]);
            }

            this.setState({
                error: false,
                success: true,
                preventClose: false
            }, () => {
                setTimeout(() => {
                    this.modalRef.close();
                }, 1000);
            });

            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            map(this.progresses, (progress: any) => {
                progress.cancelToken.cancel();
            });

            this.progresses = {};
            this.calcOverAllProgress();

            this.setState({
                error: true,
                success: false,
                preventClose: false
            });
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    componentWillUnmount() {
        this.desctroyComponent();
    }

    desctroyComponent() {
        map(this.progresses, (progress: any) => {
            progress.cancelToken.cancel();
        });

        this.progresses = {};

        this.setState({
            error: true,
            success: false,
            overAllProgress: 0
        });
    }

    handleClose() {
        this.modalRef.close();
        this.desctroyComponent();
    }

    componentDidMount() {
        this.setState({
            error: false,
            success: false,
            overAllProgress: 0,
            videoUrl: this.props.videoUrl
        });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.createMediaType !== prevProps.createMediaType) {
            this.setState({
                createMediaType: this.props.createMediaType
            });
        }
        if (this.props.videoUrl !== prevProps.videoUrl) {
            this.setState({videoUrl: this.props.videoUrl});
        }
    }

    render() {
        return <div className={this.props.className}>
            <button className="btn-atas" onClick={() => this.modalRef.open()}>
                {this.props.title || 'Upload'}
            </button>

            <ModalComponent ref={(ref: any) => (this.modalRef = ref)} buttons={[
                this.state.preventClose ?
                    <button key={0} className="btn-atas disabled greyscale">Close</button> :
                    <button className="btn-atas" key={0} onClick={() => this.handleClose()}>Close</button>
            ]}>

                <div className="w-100 flex m-upload-wrapper">
                    {this.props.enableImage &&
                    <button className="btn-atas" onClick={() => this.handleUploadImageClick()}>Select Image</button>}
                    {this.props.enableAudio &&
                    <button className="btn-atas" onClick={() => this.handleUploadAudioClick()}>Select Audio</button>}
                    {this.props.enableVideo && <div className="video-input-wrapper">
                        <input type="text" placeholder="Copy a URL from Vimeo"
                               value={get(this.state, 'videoUrl')}
                               onChange={(event: any) => this.updateVideoUrl(event)}/>
                        {this.state.videoUrl &&
                        <button className="btn-atas" onClick={() => this.handleVideoClick()}>Upload Video</button>}
                        {!this.state.videoUrl && <button className="btn-atas disabled">Upload Video</button>}
                    </div>}
                </div>

                <div className="w-100 flex m-upload-wrapper">
                    {!this.state.success && <ProgressTileComponent
                        error={this.state.error}
                        success={this.state.success}
                        percentage={this.state.overAllProgress}/>}

                    {this.state.success && <p>Success!</p>}

                </div>

                <input className="d-none" type="file" accept="image/*" multiple={!!this.props.multi}
                       value={this.state.mustHaveImage}
                       ref={(ref: any) => (this.imageInputRef = ref)}/>
                <input className="d-none" type="file" accept="audio/mpeg3" multiple={!!this.props.multi}
                       value={this.state.mustHaveAudio}
                       ref={(ref: any) => (this.audioInputRef = ref)}/>
            </ModalComponent>
        </div>;
    }
}

/*
<div className="card">
    <div className="card-header">LKAFLSKJASF</div>
    <div className="card-body">
        <h3>Hello</h3>
    </div>
</div>;
*/
