export class Slide {
    public id: number;
    public '@id': string;
    public mediaItem: string | any;
    public position: number = 0;

    constructor(obj: any) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.mediaItem = obj.mediaItem;
        this.position = obj.position;
    }
}

export class SlideshowConfig {
    public id: number;
    public '@id': string;
    public title: string;
    public text: string;
    public icon: string;
    public slides: Slide[];

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.title = obj.title || '';
        this.text = obj.text;
        this.icon = obj.icon;

        this.slides = (obj.slides || []).map(i => new Slide(i));
    }
}
