import * as React from 'react';
import './feedback-toast.scss';

export class FeedbackToast extends React.Component<any, any> {
    private timeOut: any;
    public state: any = {
        isShow: false,
        type: this.props.type || '',
        message: this.props.message || 'Lorem toast message'
    };

    componentDidMount() {
/*
        this.setState({});
*/
    }

    public closeToast() {
        this.setState({
            isShow: false
        });
        clearTimeout(this.timeOut);
    }

    public showToast(message: string, type: string) {
        this.setState({
            isShow: true,
            message,
            type
        });

        this.timeOut = setTimeout(() => {
            this.closeToast();
        }, 5000);
    }

    public render() {
        return <div className={`feedback-toast ${this.state.isShow ? 'show' : ''}`}>
            <div className={`feedback-content ${this.state.type}`}>
                <i className="close far fa-times-circle" onClick={() => this.closeToast()}/>
                <span>{this.state.message}</span>
            </div>
        </div>;
    }
}
