import { get } from 'lodash';
import * as React from 'react';
import { AtasTextfield } from '../../../../src/libs/elements/textfield/atas-textfield';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import { TaskPresenterPropsInterface } from '../task-presenter/task-presenter-props.interface';
import './question-and-answer.scss';

export class QuestionAndAnswerPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    public state: any = {
        initialized: false,
        answer: ''
    };

    componentDidMount() {
        if (get(this.props, 'config.title')) {
            this.setState({
                initialized: true,
                error: false
            });
        } else {
            this.setState({
                error: true
            });
        }
    }

    handleTaskStateChange(key: string, value: string) {
        this.props.config.taskState.set(key, value);

        this.setState({
            [key]: value
        });

        if (this.props.onState) {
            this.props.onState();
        }
    }

    public validateTask(): Promise<any> {
        return Promise.resolve(true);
    }

    public render() {
        return <div className="questionAndAnswerPresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {this.state.initialized && <div className="question">
                <span className="letter">Q:</span>
                <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3></div>}
            {this.state.initialized && <div className="description"
                                            dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}
            {this.state.initialized && <div className="w-100">
                <span className="letter letter-a">A:</span>
                <AtasTextfield
                    value={this.state.answer}
                    className="col"
                    onChange={(e: any) => this.handleTaskStateChange('answer', e.target.value)}
                    placeholder="Type your answer here…"/>
            </div>}
        </div>;
    }
}
