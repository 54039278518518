import { Location } from 'history';
import * as React from 'react';
import { RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Subscription } from 'rxjs/index';
import { RouterLib } from '../../libs/router.lib';
import './main-navigation.component.scss';

const source: any = require('./source.json') || [];

interface MainNavigationComponentProps {
    routeProps?: RouteProps;
    isCompanyAdmin?: boolean;
}

export class MainNavigationComponent extends React.Component<MainNavigationComponentProps, any> {
    private subscriptions$: Subscription[] = [];
    private routerLib = new RouterLib();

    public state: any = {
        location: this.routerLib.getLocation()
    };

    private isActive(path: string): boolean {
        return this.state.location.pathname === path;
    }

    componentDidMount() {
        this.subscriptions$.push(
            this.routerLib.onChange$.subscribe((location: Location) => this.setState({
                location
            }))
        );
    }

    componentWillUnmount() {
        this.subscriptions$.forEach((s: Subscription) => s.unsubscribe());
    }

    render() {
        return <div className="mainNavigationComponent sticky-top">
            <div className="main-nav-wrapper w-100">
                {source.map((item: any, index: number) => (
                    (item.code !== 'settings' || this.props.isCompanyAdmin) ? (<Link key={index} to={item.link} className={`item ${this.isActive(item.link) ? 'active' : ''}`}>
                        <i className={item.icon}/>
                        <span>{item.title}</span>
                    </Link>) : null
                ))}
            </div>
        </div>;
    }
}
