import {get, isEqual} from 'lodash';
import * as React from 'react';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Helper} from '../../../libs/helper';
import {DragAndDropGroup} from '../../../mock/entities/drag-and-drop-group';
import './task-setup.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class DragAndDropTypeSetup extends React.Component<any, any> {
    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public createNewGroup() {
        this.state.task.config.dragAndDropGroups.push(new DragAndDropGroup({
            title: 'NEW GROUP'
        }));
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public handleAddImageToGroup(mts: any, groupIndex: number) {
        (mts || []).forEach((mt) => this.state.task.config.dragAndDropGroups[groupIndex].items.push(mt.media));
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public handleRemoveImageFromGroup(imageIndex: number, groupIndex: number) {
        this.state.task.config.dragAndDropGroups[groupIndex].items = Helper.removeFrom(this.state.task.config.dragAndDropGroups[groupIndex].items, imageIndex);
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public deleteGroup(groupIndex: number) {
        this.state.task.config.dragAndDropGroups = Helper.removeFrom(this.state.task.config.dragAndDropGroups, groupIndex);
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public handleGroupTitleChanged(groupIndex: number, value: string) {
        this.state.task.config.dragAndDropGroups[groupIndex].title = value;
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public render() {
        return <div className="dragAndDropSetup">
            <div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>
                <button className="btn-atas add-group-btn" onClick={() => this.createNewGroup()}>Add group</button>
                {
                    get(this.state, 'task.config.dragAndDropGroups', []).map((group: DragAndDropGroup, groupIndex: number) => {
                        return <div key={groupIndex} className="card">
                            <div className="card-header">
                                <AtasTextfield
                                    onChange={(event: any) => this.handleGroupTitleChanged(groupIndex, event.target.value)}
                                    label="Group title"
                                    value={group.title}
                                />

                                <MultiUploadComponent
                                    multi={true}
                                    enableImage={true}
                                    title="Select Image"
                                    onSuccess={(mts: any[]) => this.handleAddImageToGroup(mts, groupIndex)}
                                    createMediaType={false}/>

                                <button className="far fa-trash-alt delete-group-btn"
                                        onClick={() => this.deleteGroup(groupIndex)}/>
                            </div>
                            <div className="card-body">
                                {group.items.map((item: any, imageIndex: number) => {
                                    return <div className="dd-image" key={imageIndex}>
                                        <span className="far fa-minus"
                                              onClick={() => this.handleRemoveImageFromGroup(imageIndex, groupIndex)}/>
                                        <img className="magic-image" src={item.images['300_300']}/>
                                    </div>;
                                })}
                            </div>
                        </div>;
                    })
                }
            </div>
        </div>;
    }
}
