import { MediaItem } from '../media-item';
import {get} from 'lodash';
export class InformativeSectionConfig {
    public id: string;
    public media: any | null;
    public videoUrl: string | null;
    public type: string;
    public '@id': string;

    constructor(obj: any = {}) {
        this.id = (obj || {}).id;
        this['@id'] = (obj || {})['@id'];
        this.media = get(obj, 'media') ? new MediaItem(obj.media || {}) : null;
        this.videoUrl = get(obj, 'videoUrl') || null;
        this.type = get(obj, 'type');
        if (!this.media && !this.videoUrl) {
            this.ignoreMediaItem();
        }
    }

    public ignoreMediaItem() {
        delete this.media;
        delete this.videoUrl;
    }
}
