import { get, isEqual } from 'lodash';
import * as React from 'react';
import { TabNavigationComponent, TabNavigationItemComponent } from '../../libs/elements/tab-navigation.component';
import { TaskTypeCard } from '../../libs/elements/task-type-card/task-type.card';
import { Repository } from '../../libs/repository/repository';
import { AudioConfig } from '../../mock/entities/task-configs/audio.config';
import { CompleteSentenceConfig } from '../../mock/entities/task-configs/complete-sentence.config';
import { DragAndDropConfig } from '../../mock/entities/task-configs/drag-and-drop.config';
import { InformativeSectionConfig } from '../../mock/entities/task-configs/informative-section.config';
import { JournalConfig } from '../../mock/entities/task-configs/journal.config';
import { MultipleChoiceQuestionConfig } from '../../mock/entities/task-configs/multiple-choice-question.config';
import { QuizConfig } from '../../mock/entities/task-configs/quiz.config';
import { SlideshowConfig } from '../../mock/entities/task-configs/slideshow.config';
import { VideoConfig } from '../../mock/entities/task-configs/video.config';
import { TaskTypes } from '../../task.types';
import './task-creator.component.scss';
import { AudioTypeSetup } from './task-setups/audio-type-setup';
import { CompleteSentenceTypeSetup } from './task-setups/complete-sentence-type-setup';
import { DragAndDropTypeSetup } from './task-setups/drag-and-drop-type-setup';
import { InformativeSectionTypeSetup } from './task-setups/informative-section-type-setup';
import { JournalTypeSetup } from './task-setups/journal-type-setup';
import { MultipleChoiceQuestionTypeSetup } from './task-setups/multiple-choice-question-type-setup';
import { QuestionAndAnswerTypeSetup } from './task-setups/question-and-answer-type-setup';
import { QuizTypeSetup } from './task-setups/quiz-type-setup';
import { SlideTypeSetup } from './task-setups/slide-type-setup';
import { VideoTypeSetup } from './task-setups/video-type-setup';

const availableType = [
    TaskTypes.AUDIO,
    TaskTypes.VIDEO,
    TaskTypes.COMPLETE_SENTENCE,
    TaskTypes.CHOICE,
    TaskTypes.INFORMATIVE_SECTION,
    TaskTypes.DRAG_AND_DROP,
    TaskTypes.JOURNAL,
    TaskTypes.QUIZ,
    TaskTypes.SLIDE
];

export class TaskCreatorComponent extends React.Component<any, any> {
    public state: any = {
        task: this.props.task,
        tab: 0
    };

    public selectTab(index: number) {
        this.setState({
            tab: index
        });
    }

    componentDidMount() {
        if (this.state && this.state.task && this.state.task.type) {
            this.setTaskType(this.state.task.type);
        } else {
            this.setTaskType(TaskTypes.AUDIO);
        }
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    public setTaskType(type: any) {
        switch (type) {
            case TaskTypes.AUDIO: {
                this.state.task.config = new AudioConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.VIDEO: {
                this.state.task.config = new VideoConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.JOURNAL: {
                this.state.task.config = new JournalConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.INFORMATIVE_SECTION: {
                this.state.task.config = new InformativeSectionConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.COMPLETE_SENTENCE: {
                this.state.task.config = new CompleteSentenceConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.CHOICE: {
                this.state.task.config = new MultipleChoiceQuestionConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.SLIDE: {
                this.state.task.config = new SlideshowConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.QUIZ: {
                this.state.task.config = new QuizConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.QUESTION_AND_ANSWER: {
                // this.state.task.config = new AudioConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
            case TaskTypes.DRAG_AND_DROP: {
                this.state.task.config = new DragAndDropConfig(this.props.isCreate ? {} : this.state.task.config);
                this.state.task.type = type;
                this.setState({task: this.state.task});
                break;
            }
        }
    }

    public templateSelectType(disableSelect: boolean) {
        return <div className="row">
            {availableType.map((item: any) => {
                return <div key={item} className={`col-md-4 ${disableSelect ? 'grayscale disabled' : ''}`}
                            onClick={disableSelect ? (() => null) : () => this.setTaskType(item)}>
                    <TaskTypeCard selected={item === this.state.task.type} type={item}/>
                </div>;
            })}
        </div>;
    }

    public templateTaskSetup() {
        switch (this.state.task.type) {
            case TaskTypes.AUDIO: {
                return <AudioTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.VIDEO: {
                return <VideoTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.JOURNAL: {
                return <JournalTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.INFORMATIVE_SECTION: {
                return <InformativeSectionTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.COMPLETE_SENTENCE: {
                return <CompleteSentenceTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.CHOICE: {
                return <MultipleChoiceQuestionTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.SLIDE: {
                return <SlideTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.QUIZ: {
                return <QuizTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.QUESTION_AND_ANSWER: {
                return <QuestionAndAnswerTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            case TaskTypes.DRAG_AND_DROP: {
                return <DragAndDropTypeSetup
                    task={this.state.task}
                    onChange={(task: any) => this.onTaskChange(task)}/>;
            }
            default: {
                return <h3>Unknown TaskType</h3>;
            }
        }
    }

    onTaskChange(task: any) {
        this.setState({
            task
        });
    }

    public templateLessonTaskSetup() {
        return <h3>templateLessonTaskSetup</h3>;
    }

    async submitTask(): Promise<any> {
        if (this.props.isCreate) {
            try {
                const response = await Repository.post('/task', this.state.task);
                return Promise.resolve(response);
            } catch (err) {
                return Promise.reject(err);
            }
        } else {
            try {
                const response = await Repository.put(get(this.state.task, '@id'), this.state.task);
                return Promise.resolve(response);
            } catch (err) {
                return Promise.reject(err);
            }
        }
    }

    render() {
        return <div className="taskCreatorWrapper">

            {this.props.isCreate && <h3>Create new task</h3>}
            {!this.props.isCreate && <h3>Update task</h3>}

            <TabNavigationComponent
                active={this.state.tab}
                onActiveChange={(active) => this.selectTab(active)}
                items={[
                    <TabNavigationItemComponent key={0} onClick={() => this.selectTab(0)} title="Task Type"/>,
                    <TabNavigationItemComponent key={1} onClick={() => this.selectTab(1)} title="Task Setup"/>
                ]}/>

            {(this.state.tab === 0) && this.templateSelectType(!this.props.isCreate)}
            {(this.state.tab === 1) && this.templateTaskSetup()}
            {(this.state.tab === 2) && this.templateLessonTaskSetup()}
        </div>;
    }
}
