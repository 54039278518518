import { withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import * as React from 'react';

const style: any = {
    root: {
        color: '#bcbcbc',
        '&$checked': {
            color: '#23c580'
        }
    },
    checked: {}
};

export class AtasCheckBox extends React.Component<CheckboxProps, any> {
    private component: any = withStyles(style)(Checkbox as any);

    render() {
        return React.createElement(this.component, this.props);
    }
}
