import {compact, get} from 'lodash';
import * as React from 'react';
import {CookieStorage} from '../../libs/cookie-storage';
import {FeedbackToast} from '../../libs/elements/feedback-toast/feedback-toast';
import {Storage} from '../../libs/storage';
import {CompanyLessonsPresenter} from './company-lessons.presenter.tsx/company-lessons.presenter';
import {SettingsHeroComponent} from './settings-hero/settings-hero.component';
import './settings.scss';
import {CompanyTasksPresenter} from './company-tasks.presenter/company-tasks.presenter';
import {TabNavigationComponent, TabNavigationItemComponent} from '../../libs/elements/tab-navigation.component';
import {CompanyTaskArchivePresenter} from './company-tasks.presenter/company-task-archive.presenter';

export class SettingsComponent extends React.Component<any, any> {
    private cookieStorage: CookieStorage = new CookieStorage();
    private localStorage: Storage = new Storage();
    private toastRef: any;
    public state: any = {
        companySiteSpecId: this.cookieStorage.getItem('CompanySiteSpecId'),
        availableCompanies: [],
        tab: 0
    };

    async componentDidMount() {
        const userProfile = await this.localStorage.getItem('UserProfile');
        const companies = compact(get(userProfile, 'personCompany').map((item: any) => {
            const company = get(item, 'company');
            if (get(company, 'admin')) {
                return company;
            } else {
                return;
            }
        }));
        this.setState({availableCompanies: companies});
    }

    public selectTab(index: number) {
        this.setState({
            tab: index
        });
    }

    public handleLessonError(err: any) {
        this.toastRef.showToast(err.toString(), 'error');
    }

    public handleSiteIdChange(value: any) {
        this.cookieStorage.setItem('CompanySiteSpecId', value);
        this.setState({companySiteSpecId: value});

        setTimeout(() => {
            location.reload();
        }, 500);
    }

    render() {
        return <div className="settings">
            <SettingsHeroComponent
                title="Manage Lessons"
                description=""
            />
            <div className="container settings-content">
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className="form-group atas-select">
                            <label htmlFor="selectTask">Select company:</label>
                            <i className="fas fa-caret-down"/>
                            <select className="form-control"
                                    onChange={(event: any) => this.handleSiteIdChange(event.target.value)}
                                    value={this.state.companySiteSpecId} name="company-select"
                                    id="companySiteSpecSelector">
                                {this.state.availableCompanies.map((company: any, index: number) => {
                                    return <option key={index} value={company.companySiteSpec}>{company.name}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <TabNavigationComponent
                        active={this.state.tab}
                        onActiveChange={(active) => this.selectTab(active)}
                        items={[
                            <TabNavigationItemComponent key={0} onClick={() => this.selectTab(0)} title="Lessons"/>,
                            <TabNavigationItemComponent key={1} onClick={() => this.selectTab(1)} title="Tasks"/>,
                            <TabNavigationItemComponent key={2} onClick={() => this.selectTab(2)}
                                                        title="Archived Tasks"/>
                        ]}/>
                </div>
                <div>
                    {(this.state.tab === 0) &&
                    <CompanyLessonsPresenter onError={(err: any) => this.handleLessonError(err)}
                                             companyId={parseInt(this.state.companySiteSpecId, 10)}/>}
                    {(this.state.tab === 1) &&
                    <CompanyTasksPresenter onError={(err: any) => this.handleLessonError(err)}
                                           companyId={parseInt(this.state.companySiteSpecId, 10)}/>}
                    {(this.state.tab === 2) &&
                    <CompanyTaskArchivePresenter onError={(err: any) => this.handleLessonError(err)}
                                                 companyId={parseInt(this.state.companySiteSpecId, 10)}/>}
                </div>
            </div>
            <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
        </div>;
    }
}
