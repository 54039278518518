/**
 * WEBPACK HACK
 * Please do not modify it!
 */
let _env = 'prod';

if (_env === '___WEBPACK_ENVIRONMENT_REPLACE_STRING') {
    _env = 'test';
}

const _envs = {
    mock: require('../environment/mock.json'),
    test: require('../environment/test.json'),
    local: require('../environment/local.json'),
    dev: require('../environment/dev.json'),
    prod: require('../environment/prod.json'),
    uat: require('../environment/uat.json')
};

export const environment: any = _envs[_env] || {};
