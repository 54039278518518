import {get, isEqual} from 'lodash';
import * as React from 'react';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {RangeSliderComponent} from '../../../libs/elements/range-slider.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Repository} from '../../../libs/repository/repository';
import {Slide} from '../../../mock/entities/task-configs/slideshow.config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class SlideTypeSetup extends React.Component<any, any> {

    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    handleSubtitleChange(s: any[]) {
        this.setState({
            task: {
                ...this.state.task,
                subtitles: s
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handleBreakpointChange(s: any[]) {
        this.setState({
            task: {
                ...this.state.task,
                breakpoints: s
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    addSlide() {
        this.state.task.config.slides.push(new Slide({
            position: this.state.task.config.slides.length
        }));

        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public async slideMediaItemChange(mt: any, slideIndex: number) {
        try {
            this.state.task.config.slides[slideIndex].mediaItem = await Repository.get(get(mt, 'media.@id'));
            this.setState({
                task: this.state.task
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    public removeSlide(index: number) {
        this.state.task.config.slides = (this.state.task.config.slides || []).filter((i, ind: number) => (ind !== index));

        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public render() {
        return <div className="slideTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('maxDuration', parseInt(event.target.value, 10))}
                        label="Duration (seconds per slide)"
                        value={this.state.task.maxDuration}
                        multiline={true}
                    />
                </div>
                <div className="col-xs-12">

                    <div className="card">
                        <div className="card-header flex justify-content-between align-items-center">
                            <span>Slides</span>
                            <button className="btn-atas" onClick={() => this.addSlide()}>Add Slide</button>
                        </div>
                        <div className="card-body row">

                            {(this.state.task.config.slides || []).map((item: any, index: number) => <div
                                className="col-xs-12 relative flex-wrap d-inline-flex center-content align-items-center justify-content-center bHighlight"
                                key={index}>

                                <div
                                    className=" flex-wrap d-inline-flex center-content align-items-center justify-content-center">
                                    <img className="img-responsive" src={get(item, 'mediaItem.images[300_300]')}
                                         alt=""/>

                                    <MultiUploadComponent
                                        className="position-absolute"
                                        multi={false}
                                        enableImage={true}
                                        title="Add Image"
                                        onSuccess={(mts: any[]) => this.slideMediaItemChange(mts[0], index)}
                                        createMediaType={false}/>

                                    <i className="fas fa-trash-alt slideRemove"
                                       onClick={() => this.removeSlide(index)}/>
                                </div>

                            </div>)}

                        </div>
                    </div>

                    <br/>

                    <div className="card">
                        <div className="card-header flex justify-content-between align-items-center">
                            <span>Settings</span>

                        </div>
                        <div className="card-body">
                            <RangeSliderComponent
                                onBreakpoints={(b: any[]) => this.handleBreakpointChange(b)}
                                onSubtitles={(s: any[]) => this.handleSubtitleChange(s)}
                                breakpoints={this.state.task.breakpoints}
                                subtitles={this.state.task.subtitles}
                                time={this.state.time}
                                allTime={(((this.state.task.maxDuration || 1) * (this.state.task.config.slides || []).length) * 1000) || 100000}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>;
    }
}
