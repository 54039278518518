import * as React from 'react';
import {Repository} from '../../../libs/repository/repository';
import {get} from 'lodash';
import {EmptyList} from '../../../libs/elements/empty-list/empty-list';
import './company-tasks.scss';
import {Task} from '../../../mock/entities/task';
import {Loader} from '../../../libs/elements/loader/loader';
import {Pagination} from '../../../libs/elements/pagination/pagination';
import {TaskSettingCardPresenter} from './task-setting-card.presenter';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';

export class CompanyTaskArchivePresenter extends React.Component<any, any> {
    private taskDeleteModalRef: ModalComponent;
    private taskUnHideModalRef: ModalComponent;
    public state: any = {
        tasks: [],
        page: 1,
        fetched: false,
        limit: 12,
        totalItems: 0,
        isLoading: false,
        isError: false,
        currentTask: null
    };

    private async fetchTasks(page?: number, limit?: number): Promise<any> {
        try {
            this.setState({isLoading: true});
            const query: any = {};
            (page) ? query.page = page : query.page = 1;
            (limit) ? query.limit = limit : query.limit = 12;
            query.hidden = true;
            query.companyId = this.props.companyId;
            if (this.state.typeFilter !== '') {
                query.type = this.state.typeFilter;
            }
            const response = await Repository.get('/task', query);
            this.state.tasks = [];
            this.state.totalItems = get(response, 'totalItems', 0);
            this.forceUpdate();

            await Promise.all(get(response, 'member').map((item: any) => {
                this.state.tasks.push({
                    task: new Task(item)
                });
            }));

            this.setState(this.state);
            this.setState({fetched: true, isLoading: false});

            return Promise.resolve(true);

        } catch (err) {
            this.setState({isLoading: false});
            if (this.props.onError) {
                this.props.onError();
            }
            return Promise.reject(err);
        }
    }

    async componentDidMount() {
        await this.fetchTasks();
        this.forceUpdate();
    }

    /* DELTE TASK */

    public handleDelete(task: any) {
        this.setState({currentTask: task}, () => {
            this.taskDeleteModalRef.open();
        });
    }

    public deleteTask() {
        if (this.state.currentTask) {
            console.log(this.state.currentTask);
        } else {
            console.log('Error');
        }
        this.taskDeleteModalRef.close();
    }

    public cancelDelete() {
        this.setState({currentTask: null}, () => this.taskDeleteModalRef.close());
    }

    /* UNHIDE TASK */

    public handleUnHide(task: any) {
        this.setState({currentTask: task}, () => {
            this.taskUnHideModalRef.open();
        });
    }

    public async unHideTask() {
        try {
            if (this.state.currentTask) {
                console.log(this.state.currentTask);
                await Repository.put(get(this.state, 'currentTask.@id'), {hidden: false});
                this.setState({currentTask: null}, () => this.fetchTasks());
            } else {
                console.log('Error');
            }
            this.taskUnHideModalRef.close();
        } catch (e) {
            this.setState({currentTask: null}, () => this.fetchTasks());
            this.taskUnHideModalRef.close();
        }

    }

    public cancelUnHide() {
        this.setState({currentTask: null}, () => this.taskUnHideModalRef.close());

    }

    public render() {
        return <div className="relative">
            <div className="task-list-header">
                <h2>Archived Tasks</h2>
                <br/>
                <p>These task are hidden from the task selector list, and aren't assignable to a lesson, but stay
                    available in the previously created lessons.</p>
                <br/>
            </div>
            <div className="taskPreviewRoll row">
                {!!this.state.tasks.length && this.state.tasks.map((item: any, index: number) => {
                    return <div key={index}
                                className="taskPreviewCardContainer"
                                onClick={(event: any) => console.log}>
                        <TaskSettingCardPresenter
                            task={get(item, 'task')}
                            onDelete={(task: any) => this.handleDelete(task)}
                            onUnHide={(task: any) => this.handleUnHide(task)}
                        />
                    </div>;
                })}
                {!this.state.tasks.length && <EmptyList elText="No available task found!"/>}

            </div>
            <Pagination
                totalItems={this.state.totalItems}
                limit={this.state.limit}
                currentPage={this.state.page}
                onPageChange={(page: number) => this.fetchTasks(page, this.state.limit)}
            />
            <Loader isLoading={this.state.isLoading}/>

            <div>
                {/*MODALS*/}
                <ModalComponent
                    ref={(ref: any) => (this.taskDeleteModalRef = ref)}
                    buttons={[
                        <button key={0} className="btn-atas warning-red"
                                onClick={() => this.deleteTask()}>Delete
                        </button>,
                        <button key={1} className="btn-atas"
                                onClick={() => this.cancelDelete()}>Cancel</button>
                    ]}>
                </ModalComponent>

                <ModalComponent
                    ref={(ref: any) => (this.taskUnHideModalRef = ref)}
                    buttons={[
                        <button key={0} className="btn-atas"
                                onClick={() => this.unHideTask()}>Unhide
                        </button>,
                        <button key={1} className="btn-atas"
                                onClick={() => this.cancelUnHide()}>Cancel</button>
                    ]}>
                    <div>
                        <h3>Are you sure, you want to set this task as available on Task selector list?</h3>
                    </div>
                </ModalComponent>
            </div>
        </div>;
    }
}
