import * as React from 'react';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import './unknown-tasktype.scss';

export class UnknownTasktype extends React.Component {

    public validateTask(): Promise<any> {
        return Promise.resolve(true);
    }

    public render() {
        return <div className="w-100 relative videoPresenter relative task-presenter">
            <Placeholder.Bar size="huge"/>
            <Placeholder.Bar size="huge"/>
            <TaskError description="Unknown TaskType!"/>
        </div>;
    }
}
