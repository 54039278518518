import * as faker from 'faker';
import { environment } from '../../../libs/environment';
import { Choice } from '../choice';

export class MultipleChoiceQuestionConfig {
    public id: number;
    public '@id': string;
    public multi: boolean;
    public question: string = '';
    public choices: Choice[] = [];

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.question = faker.lorem.sentence(6);
            this.choices = [
                new Choice({choice: faker.lorem.sentence(4)}),
                new Choice({choice: faker.lorem.sentence(4)}),
                new Choice({choice: faker.lorem.sentence(4)}),
                new Choice({choice: faker.lorem.sentence(4)})
            ];
        } else {
            this.multi = !!obj.multi;
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.question = obj.question || '';
            this.choices = (obj.choices || []).map((item: any) => new Choice(item));
        }
    }
}
