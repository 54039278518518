import * as React from 'react';
import './feedback-bubble.scss';

export class FeedbackBubble extends React.Component<any, any> {
    public state: any = {
        success: false
    };

    componentDidMount() {
        this.setState({
            success: this.props.success
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.success !== this.props.success) {
            this.setState({
                success: this.props.success
            });
        }
    }

    public render() {
        return <div className="feedbackBubbleElement">
            {this.state.success && <div className="feedbackBubble fb-success">{this.props.textCorrect || 'That\'s correct!'}</div>}
            {!this.state.success && <div className="feedbackBubble fb-fail">{this.props.textWrong || 'Sorry, that\'s incorrect! Please try again.'}</div>}
        </div>;
    }
}
