import * as Cookies from 'js-cookie';

export class CookieStorage {
    private provider: any = Cookies;
    private prefix: string = 'jmg';

    constructor() {
        if (!this.provider) {
            alert('Your browser does not support LocalStorage API!');
        }
    }

    public getItem(key: string): any {
        try {
            return this.provider.get(`${this.prefix}-${key}`);
        } catch (e) {
            return null;
        }
    }

    public setItem(key: string, value: string): void {
        try {
            this.provider.set(`${this.prefix}-${key}`, value);
        } catch (e) {
            console.error('Failed to [cookie-storage].setItem(key, value)', key, value);
            console.error(e);
        }
    }

    public removeItem(key: string): void {
        try {
            return this.provider.remove(`${this.prefix}-${key}`);
        } catch (e) {
            console.error('Failed to [cookie-storage].removeItem(key)', key);
            console.error(e);
        }
    }

}
