export class MediaItem {
    id: number;
    '@id': string;
    title: string;
    url: string;
    alt: string;
    media: string;
    directory: string;
    mime: string;
    tag: any[];
    images: any;
    source: any;
    category: any;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;

    constructor(obj: any = {}) {

        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.title = obj.title;
        this.alt = obj.alt;
        this.url = obj.url;
        this.media = obj.media;
        this.directory = obj.directory;
        this.mime = obj.mime;
        this.tag = obj.tag || [];
        this.images = obj.images;
        this.source = obj.source;
        this.category = obj.category;
        this.createdAt = obj.createdAt;
        this.updatedAt = obj.updatedAt;
        this.deletedAt = obj.deletedAt;
    }
}
