import * as React from 'react';
import { CookieStorage } from '../../cookie-storage';
import { Storage } from '../../storage';
import './unauthorized.scss';

export class Unauthorized extends React.Component<any, any> {
    private cookieStorage = new CookieStorage();
    private localStorage = new Storage();

    handleTryAgain() {
        this.cookieStorage.removeItem('Code');
        this.cookieStorage.removeItem('UserId');
        this.cookieStorage.removeItem('CompanySiteSpecId');
        this.localStorage.removeItem('CompanySiteSpecIds');
        this.localStorage.removeItem('UserProfile');
        window.location.reload();
    }

    render() {
        return <div className="unauthorizedComponent">
            <div className="alert-icon">
                <img className="img-fluid" src={require('../../../../assets/icons/alert.svg')} alt=""/>
            </div>
            <h3>{this.props.title || 'Something went wrong!'}</h3>
            <p>{this.props.description}</p>
            <button className="btn-atas" onClick={() => this.handleTryAgain()}>Try Again</button>
        </div>;
    }
}
