import * as React from 'react';
import './loader.scss';

export class Loader extends React.Component<any, any> {
    public state: any = {
        isLoading: false
    };
    componentDidMount() {
        this.setState({
            isLoading: this.props.isLoading
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.isLoading !== this.props.isLoading) {
            this.setState({
                isLoading: this.props.isLoading
            });
        }
    }
    public render() {
        return <div className="loader">
            {this.state.isLoading && <div className="spinner">
                <i className="fal fa-spinner fa-spin"/>
            </div>}
            {!this.state.isLoading && <div className="d-none"/>}
        </div>;
    }
}
