import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CookieStorage } from '../../../libs/cookie-storage';
import { Repository } from '../../../libs/repository/repository';
import './end-of-module.scss';
import { RatingComponent } from './rating/rating.component';
// import { ReviewJournalComponent } from './review-journal/review-journal.component';

export class EndOfModulePresenter extends React.Component<any, any> {
    private cookieStorage: CookieStorage = new CookieStorage();
    public state: any = {
        reward: this.props.reward || 'gold',
        icon: '',
        badge: {}
    };

    async componentDidMount() {
        const userId = this.cookieStorage.getItem('UserId');
        const lessonIri = get(this.props, 'lesson.@id');
        const userLesson = await Repository.get(`/user/${userId}${lessonIri}`);
        if (userLesson.badge) {
            const badge = await Repository.get(userLesson.badge);

            this.setState({badge}, () => {
                switch (get(this.state, 'badge.code', 'gold')) {
                    case 'gold':
                        this.setState({
                            reward: 'gold',
                            badge: {
                                ...this.state.badge,
                                icon: require('../../../../assets/icons/gold.svg')
                            }
                        });
                        break;
                    case 'silver':
                        this.setState({
                            reward: 'silver',
                            badge: {
                                ...this.state.badge,
                                icon: require('../../../../assets/icons/silver.svg')
                            }
                        });
                        break;
                    case 'bronze':
                        this.setState({
                            reward: 'bronze',
                            badge: {
                                ...this.state.badge,
                                icon: require('../../../../assets/icons/bronze.svg')
                            }
                        });
                        break;
                    default:
                        this.setState({
                            reward: 'gold',
                            badge: {
                                ...this.state.badge,
                                icon: ''
                            }
                        });
                        break;
                }
            });
        }
    }

    public async submitRating(rating: number) {
        try {
            await Repository.put(get(this.props, 'lesson.@id'), {rating});
        } catch (err) {
            console.log(err);
        }
    }

    public render() {
        return <div className="endOfModule container">
            <div className="row">
                <div className="col-lg-9">
                    <h1>{get(this.props, 'lesson.endTitle', '')}</h1>
                    <p className="lead-text">{get(this.props, 'lesson.endLead', '')}</p>
                    <p className="intro-text">{get(this.props, 'lesson.endBody', '')}</p>
                    {/*<h2>Awarded Risus Vestibulum Mollis</h2>*/}
                    {/*<div className="result">*/}
                    {/*<div className="result-text">*/}
                    {/*<h3>{this.state.title}</h3>*/}
                    {/*<p>{this.state.description}</p>*/}
                    {/*</div>*/}
                    {/*<div className="medal">*/}
                    {/*<img src={this.state.icon} alt=""/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<hr/>*/}
                    {this.state.badge && <div className="result">
                        <div className="result-text">
                            <h3>{get(this.state, 'badge.title', '')}</h3>
                            <p>{get(this.state, 'badge.description', '')}</p>
                        </div>
                        <div className="medal">
                            <img src={get(this.state, 'badge.icon', '')} alt=""/>
                        </div>
                    </div>}
                    <Link to="/dashboard" className="btn-atas close-lesson">End Lesson</Link>
                </div>
                <div className="col-lg-3">
                    {!!this.props.lesson && <RatingComponent lessonIri={get(this.props, 'lesson.@id')} userId={this.cookieStorage.getItem('UserId')}/>}
                    {/*<ReviewJournalComponent/>*/}
                </div>
            </div>
        </div>;
    }
}
