import { range } from 'lodash';
import * as React from 'react';
import './bar.scss';

export class Bar extends React.Component<any, any> {
    render() {
        return range(this.props.rows || 1).map((index: number) => <div
            key={index}
            className={`placeholder-component-bar ${this.props.size || 'sm'} ${this.props.error ? 'error' : ''}`}>
            <div className="animated-background"/>
        </div>);
    }
}
