import {isEqual, get} from 'lodash';
import * as React from 'react';
import {FeedbackToast} from '../../../libs/elements/feedback-toast/feedback-toast';
import {Loader} from '../../../libs/elements/loader/loader';
import {LessonCardPresenter} from '../../e-modules/lesson/lesson-card.presenter';
import './my-modules.scss';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {Link} from 'react-router-dom';
import {LessonPreview} from '../lesson-preview/lesson-preview';

export class MyModulesPresenter extends React.Component<any, any> {
    private lessonPreviewModalRef: any;
    private toastRef: any;
    public state: any = {
        open: true,
        inProgressLessons: this.props.inProgressLessons || [],
        finishedLessons: this.props.finishedLessons || [],
        userId: null,
        isLoading: false
    };

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.inProgressLessons, this.props.inProgressLessons)) {
            this.setState({inProgressLessons: this.props.inProgressLessons});
        }
        if (!isEqual(prevProps.finishedLessons, this.props.finishedLessons)) {
            this.setState({finishedLessons: this.props.finishedLessons});
        }
        if (!isEqual(prevProps.isLoading, this.props.isLoading)) {
            this.setState({isLoading: this.props.isLoading});
        }
    }

    public openPreview(lesson: any) {
        this.setState({previewLesson: lesson}, () => this.lessonPreviewModalRef.open());
    }

    render() {
        return (<div className="myModulesPresenter relative">
            <ModalComponent
                ref={(ref: any) => (this.lessonPreviewModalRef = ref)}
                buttons={[
                    (get(this.state, 'previewLesson.status', 0) === 1) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic atas">Start Lesson</Link> : false,
                    (get(this.state, 'previewLesson.status', 0) === 2) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic inProgress atas">Resume Lesson</Link> : false,
                    (get(this.state, 'previewLesson.status', 0) === 3) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic complete atas">Restart Lesson</Link> : false
                ]}>
                <LessonPreview lesson={this.state.previewLesson} onClose={() => this.lessonPreviewModalRef.close()}/>
            </ModalComponent>
            <div className="modules-open">
                <h2>My Lessons</h2>
                <button className={this.state.open ? 'far fa-chevron-circle-up' : 'far fa-chevron-circle-down'}
                        onClick={() => this.toggle()}/>
            </div>
            <div className={`collapsible ${this.state.open ? '' : 'hide'}`}>
                <div className="row">
                    {this.state.inProgressLessons.map((lesson: any, index: number) => {
                        return <div className="col-sm-6 col-lg-4 cardcol" key={index}>
                            <LessonCardPresenter learnMore={() => this.openPreview(lesson)}
                                                 linkTo={`/cs/${lesson.companyId}/${lesson.id}`} lesson={lesson}/>
                        </div>;
                    })}
                    {this.state.finishedLessons.map((lesson: any, index: number) => {
                        return <div className="col-sm-6 col-lg-4 cardcol" key={index}>
                            <LessonCardPresenter learnMore={() => this.openPreview(lesson)}
                                                 linkTo={`/cs/${lesson.companyId}/${lesson.id}`} lesson={lesson}/>
                        </div>;
                    })}
                </div>
            </div>
            <Loader isLoading={this.state.isLoading}/>
            <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
        </div>);
    }
}
