import { Task } from './task';

export class LessonTask {
    public id: number;
    public '@id': string;
    public lessonPreset: string;
    public task: Task;
    public position: number;

    constructor(obj: LessonTask | any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.position = obj.position;
        this.lessonPreset = obj.lessonPreset;

        if (obj.task) {
            obj.task.lessonPreset = obj.lessonPreset;
            this.task = new Task(obj.task || {});
        }
    }
}
