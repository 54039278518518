import { get } from 'lodash';
import * as React from 'react';
import './lesson-preview.scss';

export class LessonPreview extends React.Component<any, any> {

    public getLessonStatus() {
        switch (get(this.props, 'lesson.status')) {
            case 1:
                return 'readyToStart';
            case 2:
                return 'inProgress';
            case 3:
                return 'complete';
            default:
                return '';
        }
    }

    public render() {
        return <div className="lessonPreview">
            <div className={`lesson-preview-image ${this.getLessonStatus()}`}>
                <img className="magic-image" src={get(this.props, 'lesson.cover.images.original', '')} alt=""/>
                {this.props.lesson.status === 1 && <div className="flag">Ready to start</div>}
                {this.props.lesson.status === 2 && <div className="flag">In Progress</div>}
                {this.props.lesson.status === 3 && <div className="flag">Complete</div>}
            </div>
            <div className="lesson-preview-text">
                <h2>{this.props.lesson.title || ''}</h2>
                {/*<p className="lead-text">Nullam quis risus eget urna mollis ornare vel eu leo. Vestibulum id ligula porta felis euismod semper.</p>*/}
                {/*<h4>What does this lesson cover?</h4>*/}
                <p>{this.props.lesson.description || ''}</p>
                <h4>Average rating for this lesson</h4>
                <div className="stars">
                    {[1, 2, 3, 4, 5].map((item: number, index: number) => {
                        if ((this.props.lesson.rating - item) >= 0) {
                            return <div key={index} className="fas fa-star star marked"/>;
                        } else if ((this.props.lesson.rating - item) >= -0.5) {
                            return <i key={index} className="fas fa-star-half-alt star marked"/>;
                        } else {
                            return <div key={index} className="fas fa-star star"/>;
                        }
                    })}
                </div>
            </div>
            <span onClick={() => this.props.onClose()} className="lesson-preview-close fal fa-times"/>
        </div>;
    }
}
