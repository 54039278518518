import * as React from 'react';
import './dashboard-hero.scss';

export class DashboardHeroComponent extends React.Component<any, any> {
    render() {
        return <div className="hero-basic container-fluid">
            <div className="hero-basic-content">
                <h1>{this.props.title}</h1>
                <p>{this.props.description}</p>
            </div>
        </div>;
    }
}
