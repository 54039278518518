import { get, range } from 'lodash';
import * as React from 'react';
import './pagination.scss';

export interface PaginationProps {
    currentPage?: number;
    limit?: number;
    totalItems?: number;
    onPageChange?: any;
}

export class Pagination extends React.Component<PaginationProps | any, any> {

    public state: any = {
        currentPage: get(this.props, 'currentPage', 1) || 1,
        limit: get(this.props, 'limit', 99),
        totalItems: Math.ceil(get(this.props, 'totalItems') / get(this.props, 'limit', 1)) || 0
    };

    private goToPage(page: number) {
        if (page >= 1 && page <= this.state.totalItems) {
            this.state.currentPage = page;
            this.forceUpdate();

            if (this.props.onPageChange) {
                this.props.onPageChange(this.state.currentPage, this.state.limit);
            }

        }
        return this.state.currentPage;
    }

    componentDidUpdate(prevProps: any) {
        if ((prevProps.totalItems !== this.props.totalItems) || prevProps.limit !== this.props.limit) {
            this.setState({
                totalItems: (Math.ceil(get(this.props, 'totalItems') / get(this.props, 'limit', 1))) || 0
            });
        }

        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({
                currentPage: this.props.currentPage
            });
        }
    }

    actualPage() {
        return `Page ${this.state.currentPage} of ${this.state.totalItems}`;
    }

    public render() {
        return <div className="paginationElement">
            {this.state.totalItems > 1 && <div className="pagination">
                <div className="d-flex align-items-center">
                    <button className="far fa-chevron-left" onClick={() => this.goToPage(this.state.currentPage - 1)}/>
                    {(range(this.state.totalItems) || []).map((item: any, index: number) => {
                        if (this.state.totalItems <= 7) {
                            return <span className={index === (this.state.currentPage - 1) ? 'active' : ''} key={index} onClick={() => this.goToPage(index + 1)}>{index + 1}</span>;
                        } else {
                            if (index === 0) {
                                return <span className={index === (this.state.currentPage - 1) ? 'active' : ''} key={index} onClick={() => this.goToPage(index + 1)}>{index + 1}</span>;
                            } else if (index === this.state.totalItems - 1) {
                                return <span className={index === (this.state.currentPage - 1) ? 'active' : ''} key={index} onClick={() => this.goToPage(index + 1)}>{index + 1}</span>;
                            } else {
                                if (index >= this.state.currentPage - 2 && index <= this.state.currentPage) {
                                    return <span className={index === (this.state.currentPage - 1) ? 'active' : ''} key={index} onClick={() => this.goToPage(index + 1)}>{index + 1}</span>;
                                } else if (index === this.state.currentPage - 3 || index === this.state.currentPage + 1) {
                                    return <span key={index}>...</span>;
                                } else {
                                    return;
                                }
                            }
                        }

                    })}
                    <button className="far fa-chevron-right" onClick={() => this.goToPage(this.state.currentPage + 1)}/>
                </div>
{/*
                <span className="actual-pages index-text">{this.actualPage()}</span>
*/}
            </div>}
        </div>;
    }
}
