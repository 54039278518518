import {get, random} from 'lodash';
import * as React from 'react';
import * as WaveSurfer from 'wavesurfer.js';
import {Placeholder} from '../../../libs/elements/placeholder/placeholder';
import {TaskError} from '../../../libs/elements/task-error/task-error';
import {Repository} from '../../../libs/repository/repository';
import {Subtitle} from '../../../mock/entities/subtitle';
import {TaskPresenterPropsInterface} from '../task-presenter/task-presenter-props.interface';
import './audio.presenter.scss';
import {SubtitlePresenter} from '../../../libs/elements/subtitle/subtitle.presenter';

export class AudioPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    private canvasDomId: string = `audioTask-Canvas-${random(1000, 9999)}`;
    private ws: any;
    private interval;

    public state: any = {
        url: null,
        initialized: false,
        error: false,
        noAudio: false,
        pause: true,
        play: false,
        duration: 0,
        time: 0,
        subtitles: get(this.props, 'config.subtitles', [])
    };

    emitSubmitable(val: boolean) {
        this.props.config.taskState.set('submitable', val);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    initializePlayer(src: string) {
        if (this.props.onState) {
            this.props.onState();
        }

        try {
            this.ws.unAll();
            this.ws.destroy();
            this.setState({
                error: false,
                initialized: false
            });
        } catch (err) {
            //
        }

        try {
            this.ws = WaveSurfer.create({
                container: `#${this.canvasDomId}`,
                waveColor: '#bcbcbc',
                progressColor: '#212224',
                responsive: true,
                hideScrollbar: true,
                barWidth: 1,
                cursorWidth: 1
            });

            // Validate Config
            if (src) {
                this.ws.load(src);

                this.ws.on('ready', (info: any) => {
                    this.setState({
                        initialized: true,
                        duration: this.ws.getDuration()
                    }, () => {
                        this.emitState();

                        this.interval = setInterval(() => {
                            if (this.state.play) {
                                this.setState({
                                    time: this.ws.getCurrentTime()
                                });
                                this.emitState();
                            }
                        });
                    });
                });

                this.ws.on('pause', () => {
                    this.setState({
                        play: false,
                        pause: true
                    }, () => this.emitState());
                });

                this.ws.on('play', () => {
                    this.setState({
                        play: true,
                        pause: false
                    }, () => this.emitState());
                });

                this.ws.on('seek', (event: any) => {
                    this.setState({
                        time: this.ws.getCurrentTime()
                    });
                    this.emitState();
                });

                this.ws.on('error', (event: any) => {
                    console.log('Audio cannot be loaded!');
                });
            } else {
                // handle problems
                this.setState({
                    error: true,
                    noAudio: true

                });
            }

        } catch (err) {
            console.log(err.message);
        }
    }

    componentDidMount() {
        this.emitSubmitable(true);

        this.setState({
            url: get(this.props, 'config.config.media.url')
        }, () => this.initializePlayer(get(this.props, 'config.config.media.url')));
    }

    componentDidUpdate(prevProps) {
        if (get(this.props, 'config.config.media.url') !== this.state.url) {
            this.setState({
                url: get(this.props, 'config.config.media.url')
            }, () => this.initializePlayer(this.state.url));
        }
    }

    componentWillUnmount() {
        this.ws.stop();
        this.ws.unAll();
        this.ws.destroy();
        clearInterval(this.interval);
    }

    onPlayButtonClick() {
        this.ws.play();
    }

    onPauseButtonClick() {
        this.ws.pause();
    }

    public emitState() {
        this.props.config.taskState.set('time', this.state.time);
        this.props.config.taskState.set('duration', this.state.duration);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    public async validateTask(): Promise<any> {
        try {
            const validationResponse = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: []
            });

            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public handleSubtitleChange(s: Subtitle[]) {
        this.props.config.subtitles = s;
        if (this.props.onState) {
            this.props.onState();
        }
    }

    public render() {
        return <div className="audioPresenter relative task-presenter">
            {(this.state.error && !this.state.noAudio) && <TaskError description="Task configuration is incorrect"/>}
            {(this.state.error && this.state.noAudio) && <TaskError title="No Audio uploaded" description=""/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {!this.state.initialized && <div className="flex align-items-center">
                <Placeholder.Circle className="col-mw-none flex-1"/> &nbsp;&nbsp;&nbsp;
                <Placeholder.Bar size="lg"/>
            </div>}

            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}

            <div className={`w-100 frequencyVisualizer ${this.state.initialized ? '' : 'hidden absolute'}`}
                 id={this.canvasDomId}/>

            {this.state.initialized && <div className="controls w-100">
                {!this.state.play && <button className="audioPlayerButton btn" onClick={() => this.onPlayButtonClick()}>
                    <i className="fas fa-play"/>
                </button>}
                {!this.state.pause &&
                <button className="audioPlayerButton btn" onClick={() => this.onPauseButtonClick()}>
                    <i className="fas fa-pause"/>
                </button>}

                <span className="trackTitle">
                    {this.parseSecondToTime(this.state.time)} / {this.parseSecondToTime(this.state.duration)} - <b>Track Title</b>
                </span>
            </div>}
            {this.state.initialized && get(this.props, 'config.config.isEditorMode', false) &&
            <div className="controls w-100 audioTranscript">
                <i className="fal fa-file-alt"/>
                <span>Click to read audio transcript</span>
            </div>}
            <SubtitlePresenter time={this.state.time * 1000} fileType="audio"
                               subtitles={get(this.state, 'subtitles', [])}/>

        </div>;
    }

    public parseSecondToTime(time: number) {
        const hours = Math.floor(time / (60 * 60));
        const minutes = Math.floor(time / (60)) - (60 * hours);
        const seconds = Math.floor(time - (60 * 60 * hours) - (60 * minutes));
        return `${(`${hours}`.length > 1) ? hours : `0${hours}`}:${(`${minutes}`.length > 1) ? minutes : `0${minutes}`}:${(`${seconds}`.length > 1) ? seconds : `0${seconds}`}`;
    }
}
