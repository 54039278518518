import * as faker from 'faker';
import { environment } from '../../libs/environment';

export class Synonym {
    public id: number;
    public '@id': string;
    public word: string = '';
    public task: string;
    public position: number = 0;
    public groupID: number = 0;
    public sentence: string = '';

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.word = faker.lorem.word();
            this.position = 0;
            this.groupID = 0;
            this.sentence = '';
        } else {
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.word = obj.word;
            this.position = obj.position;
            this.groupID = obj.groupID;
            this.sentence = obj.sentence;

            if (obj.task) {
                this.task = obj.task;
            }
        }
    }
}
