export abstract class TaskTypes {
    public static AUDIO = 'audio';
    public static VIDEO = 'video';
    public static DRAG_AND_DROP = 'drag-and-drop';
    public static COMPLETE_SENTENCE = 'complete-sentence';
    public static JOURNAL = 'journal';
    public static INFORMATIVE_SECTION = 'informative-section';
    public static QUIZ = 'quiz';
    public static CHOICE = 'choice';
    public static QUESTION_AND_ANSWER = 'question-answer';
    public static SLIDE = 'slide';

    public static taskTypesConfig() {
        return {
            [TaskTypes.AUDIO]: {
                icon: require('../assets/icons/audio.svg'),
                name: 'Audio',
                info: 'This is an Audio task'
            },
            [TaskTypes.VIDEO]: {
                icon: require('../assets/icons/video.svg'),
                name: 'Video',
                info: 'This is a Video task'
            },
            [TaskTypes.JOURNAL]: {
                icon: require('../assets/icons/journal.svg'),
                name: 'Journal Entry',
                info: 'This is a Journal task'
            },
            [TaskTypes.INFORMATIVE_SECTION]: {
                icon: require('../assets/icons/informative.svg'),
                name: 'Informative Section',
                info: 'This is a Informative Section task'
            },
            [TaskTypes.COMPLETE_SENTENCE]: {
                icon: require('../assets/icons/complete-the-sentence.svg'),
                name: 'Complete The Sentence',
                info: 'This is a Complete the Sentence task'
            },
            [TaskTypes.CHOICE]: {
                icon: require('../assets/icons/multiple-choice-question.svg'),
                name: 'Multiple Choice Question',
                info: 'This is a Multiple Choice Question task'
            },
            [TaskTypes.SLIDE]: {
                icon: require('../assets/icons/slideshow.svg'),
                name: 'Slideshow',
                info: 'This is a Slideshow task'
            },
            [TaskTypes.QUIZ]: {
                icon: require('../assets/icons/quiz.svg'),
                name: 'End Of Module Quiz',
                info: 'This is Quiz task'
            },
            [TaskTypes.QUESTION_AND_ANSWER]: {
                icon: require('../assets/icons/question-answer.svg'),
                name: 'Questions & Answer',
                info: 'This is a Q&A task'
            },
            [TaskTypes.DRAG_AND_DROP]: {
                icon: require('../assets/icons/drag-and-drop.svg'),
                name: 'Drag & Drop',
                info: 'This is a Drag and Drop task'
            }
        };
    }

    public static toArray() {
        return [
            TaskTypes.AUDIO,
            TaskTypes.JOURNAL,
            TaskTypes.VIDEO,
            TaskTypes.INFORMATIVE_SECTION,
            TaskTypes.QUESTION_AND_ANSWER,
            TaskTypes.DRAG_AND_DROP,
            TaskTypes.CHOICE,
            TaskTypes.COMPLETE_SENTENCE,
            TaskTypes.SLIDE,
            TaskTypes.QUIZ
        ];
    }
}
