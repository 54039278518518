import TextField from '@material-ui/core/TextField';
import * as d3 from 'd3';
import 'd3-transition';
import {range} from 'lodash';
import * as React from 'react';
import {TaskSelectorComponent} from '../../components/e-module-creator/lesson-creator/task-selector/task-selector.component';
import {Breakpoint} from '../../mock/entities/breakpoint';
import {Subtitle} from '../../mock/entities/subtitle';
import {FormLib} from '../form.lib';
import {ModalComponent} from './modal/modal.component';
import './range-slider.scss';

const listStyle: any = {
    backgroundColor: '#ededed82',
    borderLeft: '1px solid #ededed',
    borderRight: '1px solid #ededed',
    marginBottom: '30px'
};

const listItemStyle: any = {
    borderBottom: '1px solid #ededed',
    padding: '15px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    text: {
        fontFamily: '"Hind", sans-serif',
        color: '#3f4049'
    },
    number: {
        fontFamily: '"Hind", sans-serif',
        color: '#fff',
        backgroundColor: '#989898',
        padding: '7px',
        borderRadius: '4px'
    },
    icon: {
        color: '#989898'
    },
    breakpointinfo: {
        minWidth: '80%',
        display: 'flex'
    },
    title: {
        marginLeft: '25px',
        fontWeight: 'bold'
    }
};

export class RangeSliderComponent extends React.Component<any, any> {
    private componentRef: HTMLDivElement;
    private createSubtitleModalRef: ModalComponent;
    private createBreakpointModalRef: ModalComponent;
    private taskSelectorComponentModalRef: ModalComponent;
    private taskSelectorComponentRef: TaskSelectorComponent;
    private millisecondsInAView = 10000;
    private svg;
    private selectedBreakpointIndex: any = null;
    public actionRowG: any;
    public timeRowG: any;
    public createSubtitleForm = new FormLib({
        start: {
            value: 0
        },
        end: {
            value: 0
        },
        text: {
            value: 'Subtitle Text'
        }
    });
    public createBreakpointForm = new FormLib({
        start: {
            value: 0
        },
        end: {
            value: 0
        },
        title: {
            value: 'Breakpoint Text'
        }
    });

    public state: any = {
        breakpoints: this.props.breakpoints || [],
        subtitles: this.props.subtitles || []
    };

    private style: any = {
        width: '100%',
        height: '145px',
        overflow: 'scroll hidden',
        display: 'block'
    };

    private width: number;
    private extraWidth = 0;
    private height: number = 130;
    private allTime: number;
    private fullWidth: number;
    private secondInFullRange: number;
    private secondBarWidth: number;

    private renderBreakpoint(bp: Breakpoint, index: number) {
        const end = bp.end / 1000;
        const start = bp.start / 1000;
        const startWidth = (start * this.secondBarWidth) + (this.secondBarWidth / 2);
        const barWidth = ((end - start) * this.secondBarWidth);
        const r = this.actionRowG
            .append('g')
            .attr('class', 'breakpoint-item')
            .attr('transform', `translate(${startWidth}, 60)`);

        r.append('rect')
            .attr('class', 'action-click-target')
            .attr('height', 30)
            .attr('width', barWidth)
            .attr('fill', '#23a6c5')
            .attr('stroke', '#2098b4')
            .attr('stroke-width', 2)
            .attr('rx', 6)
            .attr('ry', 6);

        r.append('text')
            .text(this.truncate(`${index + 1}# ${bp.title}`, 12))
            .attr('fill', '#fff')
            .attr('font-family', '"Hind", sans-serif')
            .attr('transform', 'translate(10, 20)');
    }

    private truncate(text: string, length: number) {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        } else {
            return text;
        }
    }

    private renderSubtitle(bp: Subtitle, index: number) {
        const end = bp.end / 1000;
        const start = bp.start / 1000;
        const startWidth = (start * this.secondBarWidth) + (this.secondBarWidth / 2);
        const barWidth = ((end - start) * this.secondBarWidth);
        const r = this.actionRowG
            .append('g')
            .attr('class', 'subtitle-item')
            .attr('transform', `translate(${startWidth}, 10)`);

        r.append('rect')
            .attr('class', 'action-click-target')
            .attr('height', 30)
            .attr('width', barWidth)
            // .attr('fill', '#2281c5')
            // .attr('stroke', '#0f5ec5')
            .attr('fill', '#23c580')
            .attr('stroke', '#21ae5f')
            .attr('stroke-width', 2)
            .attr('rx', 6)
            .attr('ry', 6);

        r.append('text')
            .text(this.truncate(`${index + 1}# ${bp.text}`, 12))
            .attr('fill', '#fff')
            .attr('font-family', '"Hind", sans-serif')
            .attr('transform', 'translate(10, 20)');
    }

    private initializeD3() {

        if (this.svg) {
            this.svg.remove();
            this.svg = null;
            return this.initializeD3();
        }

        this.svg = d3.select(this.componentRef).append('svg')
            .attr('width', this.fullWidth + this.extraWidth)
            .attr('height', this.height);

        this.actionRowG = this.svg.append('g')
            .attr('class', 'actionRow');

        this.actionRowG.append('rect')
            .attr('width', this.fullWidth + this.extraWidth)
            .attr('height', (this.height - 30))
            .attr('fill', '#ededed');

        this.timeRowG = this.svg.append('g')
            .attr('class', 'timeRow')
            .attr('transform', `translate(0, ${this.height - 30})`);

        this.timeRowG.append('rect')
            .attr('width', this.fullWidth + this.extraWidth)
            .attr('height', 30)
            .attr('fill', '#e8e8e8');

        range(this.secondInFullRange).forEach((second: number) => {
            const a = this.timeRowG.append('g')
                .attr('transform', `translate(${(second * this.secondBarWidth) + (this.secondBarWidth / 2)}, 0)`);

            a.append('rect')
                .attr('width', .5)
                .attr('height', 10)
                .attr('class', 'secondBar')
                .attr('fill', '#868686');

            const text: any = a.append('text')
                .style('font-size', '12px')
                .text(this.getDialTimeBarText(second * 1000));

            const textWidth = text.node().getComputedTextLength();
            text.attr('transform', `translate(${-(textWidth / 2)}, 24)`);
        });

        range(this.secondInFullRange).forEach((second: number) => {
            const a = this.actionRowG.append('g')
                .attr('transform', `translate(${(second * this.secondBarWidth) + (this.secondBarWidth / 2)}, 0)`);

            a.append('rect')
                .attr('width', .5)
                .attr('height', (this.height - 30))
                .attr('class', 'secondBar')
                .attr('fill', '#c2c2c2');
        });

        this.svg.append('g')
            .attr('transform', `translate(${(this.secondBarWidth / 2)}, ${0})`)
            .append('rect')
            .attr('width', .5)
            .attr('height', this.height - 20)
            .attr('class', 'secondBar')
            .attr('fill', 'red');

        this.svg.append('text')
            .attr('transform', 'translate(5, 13)')
            .attr('fill', '#c2c2c2')
            .attr('font-size', 10)
            .text('Subtitles');
        // Add breakpoint separator
        if (!this.props.disableBreakpoints) {
            const breakpointSeparator = this.svg.append('g')
                .attr('transform', `translate(0, ${50})`);
            breakpointSeparator.append('rect')
                .attr('width', this.fullWidth + this.extraWidth)
                .attr('height', '1')
                .attr('fill', '#dadada');
            this.svg.append('text')
                .attr('transform', 'translate(5, 63)')
                .attr('fill', '#c2c2c2')
                .attr('font-size', 10)
                .text('Breakpoints');
        }

        this.actionRowG.on('click', (event: any) => this.handleActionClick(d3.event));
        this.updateBreakpointsAndSubtitles();
    }

    private updateBreakpointsAndSubtitles() {
        this.actionRowG.selectAll('.breakpoint-item').remove();
        this.actionRowG.selectAll('.subtitle-item').remove();
        this.state.breakpoints.forEach((bp: Breakpoint, index: number) => this.renderBreakpoint(bp, index));
        this.state.subtitles.forEach((subtitle: Subtitle, index: number) => this.renderSubtitle(subtitle, index));
    }

    getDialTimeBarText(time: number) {
        const hours = this.getHoursFromTime(time);
        const minutes = this.getMinutesFromTime(time);
        const seconds = this.getSecondsFromTime(time);

        const hourString = (hours < 10) ? '0' + hours.toString() : hours.toString();
        const minuteString = (minutes < 10) ? '0' + minutes.toString() : minutes.toString();
        const secondString = (seconds < 10) ? '0' + seconds.toString() : seconds.toString();
        return (`${hourString}:${minuteString}:${secondString}`);
    }

    handleActionClick(event: any) {
        const target = d3.select(event.target.parentNode);
        const x = parseFloat(((target.attr('transform') || '').match(/[\d\.-]+/g) || [])[0] || '0');
        const realClickX = ((event.offsetX - (this.secondBarWidth) - (this.secondBarWidth / 2)) <= 0) ? 0 : (event.offsetX - (this.secondBarWidth) - (this.secondBarWidth / 2));
        const realClickDistance = (-x) + (realClickX - (Math.floor((realClickX / 1000) * 0.5)));
        const realFullWidth = this.fullWidth - (this.secondBarWidth) + (this.secondBarWidth / 2);
        let timeAtClick = Math.ceil(this.allTime / 1000 * (realClickDistance / realFullWidth)) * 1000;
        if (timeAtClick + 1000 > this.allTime) {
            timeAtClick = timeAtClick - 1000;
        }
        const isUnderHalf = (50) <= event.offsetY;

        if (!isUnderHalf || this.props.disableBreakpoints) {
            this.validateSubtitleValue('start', this.createSubtitleForm.value('start', timeAtClick));
            this.validateSubtitleValue('end', this.createSubtitleForm.value('end', (timeAtClick + 1000)));
            this.createSubtitleModalRef.open(timeAtClick);
        } else {
            this.validateBreakpointValue('start', this.createBreakpointForm.value('start', timeAtClick));
            this.validateBreakpointValue('end', this.createBreakpointForm.value('end', (timeAtClick + 1000)));
            this.createBreakpointModalRef.open(timeAtClick);
        }
    }

    handleCreateSubtitleModalButtonSubmit() {
        this.state.subtitles.push(new Subtitle(this.createSubtitleForm.values.toJS()));
        this.forceUpdate();
        this.updateBreakpointsAndSubtitles();

        if (this.props.onSubtitles) {
            this.props.onSubtitles(this.state.subtitles.map((i: any) => new Subtitle(i)));
        }
    }

    handleCreateBreakpointModalButtonSubmit() {
        this.state.breakpoints.push(new Breakpoint(this.createBreakpointForm.values.toJS()));
        this.forceUpdate();
        this.updateBreakpointsAndSubtitles();

        if (this.props.onBreakpoints) {
            this.props.onBreakpoints(this.state.breakpoints.map((i: any) => new Breakpoint(i)));
        }
    }

    componentDidMount() {
        this.updateMeta();
        if (this.componentRef && this.props.allTime) {
            this.initializeD3();
            this.slideToTime(0);
        }
        this.createSubtitleForm.setContext(this);
        this.createBreakpointForm.setContext(this);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.allTime !== this.props.allTime) {
            if (this.componentRef && this.props.allTime) {
                this.updateMeta();
                this.initializeD3();
            }
        }

        if (prevProps.time !== this.props.time) {
            this.slideToTime(this.props.time);
        }
    }

    public slideToTime(time: number) {
        if (this.timeRowG && this.actionRowG) {
            const left = this.secondBarWidth * (time / 1000);

            this.actionRowG
                .attr('transform', `translate(${-left || 0}, 0)`);
            this.timeRowG
                .attr('transform', `translate(${-left || 0}, ${this.height - 30})`);
        }
    }

    public updateMeta() {
        this.width = this.componentRef.offsetWidth;
        this.allTime = Math.floor(this.props.allTime);
        this.fullWidth = this.width * (this.allTime / this.millisecondsInAView);
        this.secondInFullRange = Math.ceil(this.allTime / 1000);
        this.secondBarWidth = this.fullWidth / this.secondInFullRange;
    }

    removeSubtitle(id: number) {
        this.state.subtitles = this.state.subtitles.filter((i: any) => i.id !== id);
        if (this.props.onSubtitles) {
            this.props.onSubtitles(this.state.subtitles.map((i: any) => new Subtitle(i)));
        }

        this.setState({
            subtitles: this.state.subtitles
        }, () => this.updateBreakpointsAndSubtitles());
    }

    removeBreakpoint(id: number) {
        this.state.breakpoints = this.state.breakpoints.filter((i: any) => i.id !== id);
        if (this.props.onBreakpoints) {
            this.props.onBreakpoints(this.state.breakpoints.map((i: any) => new Breakpoint(i)));
        }

        this.setState({
            breakpoints: this.state.breakpoints
        }, () => this.updateBreakpointsAndSubtitles());
    }

    handleSubtitleChange(index: number, property: string, value: any) {
        if (property === 'start') {
            if (this.state.subtitles[index].end <= value) {
                value = this.state.subtitles[index].end - 1000;
            }
            if (value > this.allTime - 1) {
                value = this.allTime - 1000;
            }
        }
        if (property === 'end') {
            if (this.state.subtitles[index].start >= value) {
                value = this.state.subtitles[index].start + 1000;
            }
            if (value > this.allTime) {
                value = this.allTime;
            }
        }
        this.state.subtitles[index][property] = value;

        if (this.props.onSubtitles) {
            this.props.onSubtitles(this.state.subtitles.map((i: any) => new Subtitle(i)));
        }

        this.setState({
            subtitles: this.state.subtitles
        }, () => this.updateBreakpointsAndSubtitles());
    }

    validateSubtitleValue(property: string, value: number) {
        if (property === 'start') {
            if (this.createSubtitleForm.value('end') <= value) {
                value = this.createSubtitleForm.value('end') - 1000;
            }
            if (value > this.allTime - 1) {
                value = this.allTime - 1000;
            }
        }
        if (property === 'end') {
            if (this.createSubtitleForm.value('start') >= value) {
                value = this.createSubtitleForm.value('start') + 1000;
            }
            if (value > this.allTime) {
                value = this.allTime;
            }
        }
        return value;
    }

    validateBreakpointValue(property: string, value: number) {
        if (property === 'start') {
            if (this.createBreakpointForm.value('end') <= value) {
                value = this.createBreakpointForm.value('end') - 1000;
            }
            if (value > this.allTime - 1) {
                value = this.allTime - 1000;
            }
        }
        if (property === 'end') {
            if (this.createBreakpointForm.value('start') >= value) {
                value = this.createBreakpointForm.value('start') + 1000;
            }
            if (value > this.allTime) {
                value = this.allTime;
            }
        }
        return value;
    }

    handleBreakpointChange(index: number, property: string, value: any) {
        if (property === 'start') {
            if (this.state.breakpoints[index].end <= value) {
                value = this.state.breakpoints[index].end - 1000;
            }
            if (value > this.allTime - 1) {
                value = this.allTime - 1000;
            }
        }
        if (property === 'end') {
            if (this.state.breakpoints[index].start >= value) {
                value = this.state.breakpoints[index].start + 1000;
            }
            if (value > this.allTime) {
                value = this.allTime;
            }
        }
        this.state.breakpoints[index][property] = value;

        if (this.props.onBreakpoints) {
            this.props.onBreakpoints(this.state.breakpoints.map((i: any) => new Breakpoint(i)));
        }

        this.setState({
            breakpoints: this.state.breakpoints
        }, () => this.updateBreakpointsAndSubtitles());
    }

    handleBreakpointSelectTask(taskId: number) {
        const taskIri = `/task/${taskId}`;
        this.state.breakpoints[this.selectedBreakpointIndex].relatedTask = taskIri;

        this.setState({
            breakpoints: this.state.breakpoints
        }, () => {
            this.taskSelectorComponentModalRef.close();
            if (this.props.onBreakpoints) {
                this.props.onBreakpoints(this.state.breakpoints.map((i: any) => new Breakpoint(i)));
            }
        });
    }

    handleOpenBreakpointSelectTaskModal(bpIndex: number) {
        this.selectedBreakpointIndex = bpIndex;
        this.taskSelectorComponentModalRef.open();
    }

    removeTaskFromBreakpoint(bpIndex) {
        this.state.breakpoints[bpIndex].relatedTask = null;

        this.setState({
            breakpoints: this.state.breakpoints
        }, () => {
            if (this.props.onBreakpoints) {
                this.props.onBreakpoints(this.state.breakpoints.map((i: any) => new Breakpoint(i)));
            }
        });
    }

    getHoursFromTime(time: number) {
        const seconds = Math.ceil(time / 1000);
        const hours = (seconds / 60) / 60;
        return Math.floor(hours);
    }

    getMinutesFromTime(time: number) {
        const seconds = Math.ceil(time / 1000);
        const hours = this.getHoursFromTime(time) * 60 * 60;
        const minutes = (seconds - hours) / 60;
        return Math.floor(minutes);
    }

    getSecondsFromTime(time: number) {
        const seconds = Math.ceil(time / 1000);
        const hours = this.getHoursFromTime(time) * 60 * 60;
        const minutes = this.getMinutesFromTime(time) * 60;
        const resultSeconds = seconds - hours - minutes;
        return Math.floor(resultSeconds);
    }

    convertToMilisec(measure: string, amount: number, currentTime: number) {
        if (!amount) {
            amount = 0;
        }
        const seconds = this.getSecondsFromTime(currentTime) * 1000;
        const minutes = this.getMinutesFromTime(currentTime) * 60 * 1000;
        const hours = this.getHoursFromTime(currentTime) * 60 * 60 * 1000;
        if (measure === 'hour') {
            const currentHour = amount * 60 * 60 * 1000;
            const currentSum = currentHour + minutes + seconds;
            return currentSum;
        }
        if (measure === 'minute') {
            const currentMinute = amount * 60 * 1000;
            const currentSum = hours + currentMinute + seconds;
            return currentSum;
        }
        if (measure === 'second') {
            const currentSecond = amount * 1000;
            const currentSum = hours + minutes + currentSecond;
            return currentSum;
        }
        return 0;

    }

    render() {
        return <div className="col-xs-12">
            <div className="w-100" style={this.style} ref={(ref: any) => (this.componentRef = ref)}/>

            <div className="col-xs-12">
                <br/>
            </div>

            <div className="col-xs-12">
                <div className="card range-table" style={listStyle}>
                    <div className="card-header">
                        Subtitles
                    </div>
                    <div className="card-body">
                        <i style={{fontSize: '12px'}}>(You cannot specify greater start and end time value than the
                            media duration!)</i>
                        <div className="table-responsive">
                            <table className="table table-condensed">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Time</th>
                                    <th>Text</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>

                                {(this.state.subtitles || []).map((bp: Subtitle, index: number) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div>
                                            <strong style={{display: 'inline-block', width: '50px'}}>From: </strong>
                                            <input min={0} max={99} type="number"
                                                   value={this.getHoursFromTime(bp.start)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'start', this.convertToMilisec('hour', parseInt(event.target.value, 10), bp.start))}/>
                                            <span>h </span>
                                            <input min={0} max={60} type="number"
                                                   value={this.getMinutesFromTime(bp.start)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'start', this.convertToMilisec('minute', parseInt(event.target.value, 10), bp.start))}/>
                                            <span>m </span>
                                            <input min={0} max={60} type="number"
                                                   value={this.getSecondsFromTime(bp.start)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'start', this.convertToMilisec('second', parseInt(event.target.value, 10), bp.start))}/>
                                            <span>s </span>
                                        </div>

                                        <div>
                                            <strong style={{display: 'inline-block', width: '50px'}}>To: </strong>
                                            <input min={0} max={99} type="number"
                                                   value={this.getHoursFromTime(bp.start)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'end', this.convertToMilisec('hour', parseInt(event.target.value, 10), bp.end))}/>
                                            <span>h </span>
                                            <input min={0} max={60} type="number"
                                                   value={this.getMinutesFromTime(bp.end)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'end', this.convertToMilisec('minute', parseInt(event.target.value, 10), bp.end))}/>
                                            <span>m </span>
                                            <input min={0} max={60} type="number"
                                                   value={this.getSecondsFromTime(bp.end)}
                                                   onClick={(event: any) => event.target.select()}
                                                   onChange={(event: any) => this.handleSubtitleChange(index, 'end', this.convertToMilisec('second', parseInt(event.target.value, 10), bp.end))}/>
                                            <span>s </span>
                                        </div>
                                        {/*<input type="number" value={Math.ceil(bp.start / 1000)}
                                               onChange={(event: any) => this.handleSubtitleChange(index, 'start', parseInt(event.target.value, 10) * 1000)}/>
                                        <span>-</span>
                                        <input type="number" value={Math.ceil(bp.end / 1000)}
                                               onChange={(event: any) => this.handleSubtitleChange(index, 'end', parseInt(event.target.value, 10) * 1000)}/>
                                        <span> sec</span>*/}
                                    </td>
                                    <td>
                                        <input type="text" value={bp.text}
                                               onChange={(event: any) => this.handleSubtitleChange(index, 'text', event.target.value)}/>
                                    </td>
                                    <td>
                                        <i style={listItemStyle.icon} className="far fa-trash-alt"
                                           onClick={() => this.removeSubtitle(bp.id)}/>
                                    </td>
                                </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {!this.props.disableBreakpoints && <div className="col-xs-12">
                    <div className="card range-table" style={listStyle}>
                        <div className="card-header">
                            Breakpoints
                        </div>
                        <div className="card-body">
                            <i style={{fontSize: '12px'}}>(You cannot specify greater start and end time value than the
                                media duration!)</i>
                            <div className="table-responsive">
                                <table className="table table-condensed">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Time</th>
                                        <th>Title</th>
                                        <th>Task</th>
                                        <th>Position</th>
                                        <th>Blocker</th>
                                        <th>Required</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {(this.state.breakpoints || []).map((bp: Breakpoint, index: number) => <tr
                                        key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div>
                                                <strong style={{display: 'inline-block', width: '50px'}}>From: </strong>
                                                <input min={0} max={99} type="number"
                                                       value={this.getHoursFromTime(bp.start)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'start', this.convertToMilisec('hour', parseInt(event.target.value, 10), bp.start))}/>
                                                <span>h </span>
                                                <input min={0} max={60} type="number"
                                                       value={this.getMinutesFromTime(bp.start)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'start', this.convertToMilisec('minute', parseInt(event.target.value, 10), bp.start))}/>
                                                <span>m </span>
                                                <input min={0} max={60} type="number"
                                                       value={this.getSecondsFromTime(bp.start)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'start', this.convertToMilisec('second', parseInt(event.target.value, 10), bp.start))}/>
                                                <span>s </span>
                                            </div>
                                            <br/>

                                            <div>
                                                <strong style={{display: 'inline-block', width: '50px'}}>To: </strong>
                                                <input min={0} max={99} type="number"
                                                       value={this.getHoursFromTime(bp.start)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'end', this.convertToMilisec('hour', parseInt(event.target.value, 10), bp.end))}/>
                                                <span>h </span>
                                                <input min={0} max={60} type="number"
                                                       value={this.getMinutesFromTime(bp.end)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'end', this.convertToMilisec('minute', parseInt(event.target.value, 10), bp.end))}/>
                                                <span>m </span>
                                                <input min={0} max={60} type="number"
                                                       value={this.getSecondsFromTime(bp.end)}
                                                       onClick={(event: any) => event.target.select()}
                                                       onChange={(event: any) => this.handleBreakpointChange(index, 'end', this.convertToMilisec('second', parseInt(event.target.value, 10), bp.end))}/>
                                                <span>s </span>
                                            </div>
                                            <br/>
                                            {/*<input type="number" value={Math.ceil(bp.start / 1000)}
                                               onChange={(event: any) => this.handleSubtitleChange(index, 'start', parseInt(event.target.value, 10) * 1000)}/>
                                        <span>-</span>
                                        <input type="number" value={Math.ceil(bp.end / 1000)}
                                               onChange={(event: any) => this.handleSubtitleChange(index, 'end', parseInt(event.target.value, 10) * 1000)}/>
                                        <span> sec</span>*/}
                                        </td>
                                        <td>
                                            <input type="text" value={bp.title}
                                                   onChange={(event: any) => this.handleBreakpointChange(index, 'title', event.target.value)}/>
                                        </td>
                                        <td className="task-buttons">
                                            <button
                                                className="btn-atas"
                                                onClick={() => this.handleOpenBreakpointSelectTaskModal(index)}>
                                                <span>{bp.relatedTask ? `Change Task (${bp.relatedTask['@id'] || bp.relatedTask})` : 'Select Task'}</span>
                                            </button>

                                            <button className="trash-btn"
                                                    onClick={() => this.removeTaskFromBreakpoint(index)}>
                                                <i className="far fa-trash-alt"/>
                                            </button>
                                        </td>

                                        <td>
                                            <select
                                                value={bp.position}
                                                onChange={(event: any) => this.handleBreakpointChange(index, 'position', parseInt(event.target.value, 10))}>
                                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((pos: number, i: number) => <option
                                                    key={i} value={pos}>{pos}</option>)}
                                            </select>
                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={(event: any) => this.handleBreakpointChange(index, 'blocker', !bp.blocker)}
                                                checked={bp.blocker}/>
                                        </td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={(event: any) => this.handleBreakpointChange(index, 'required', !bp.required)}
                                                checked={bp.required}/>
                                        </td>

                                        <td>
                                            <i style={listItemStyle.icon} className="far fa-trash-alt"
                                               onClick={() => this.removeBreakpoint(bp.id)}/>
                                        </td>
                                    </tr>)}

                                    </tbody>
                                </table>
                            </div>

                            <ModalComponent
                                ref={(ref: any) => (this.taskSelectorComponentModalRef = ref)}
                                buttons={[
                                    <button
                                        key={0}
                                        className="btn-atas"
                                        onClick={() => this.taskSelectorComponentRef.handleSubmit()}>Select
                                        Tasks
                                    </button>
                                ]}>

                                <TaskSelectorComponent
                                    singleSelect={true}
                                    onSubmit={(taskId: number) => this.handleBreakpointSelectTask(taskId)}
                                    ref={(ref: any) => (this.taskSelectorComponentRef = ref)}/>
                            </ModalComponent>

                        </div>
                    </div>
                </div>}

            </div>

            <ModalComponent
                ref={(ref: ModalComponent) => (this.createSubtitleModalRef = ref)}
                onSuccess={(d: any) => this.handleCreateSubtitleModalButtonSubmit()}>
                <div>
                    <strong style={{display: 'inline-block', width: '50px'}}>Start: </strong>
                    <input min={0} max={99} type="number"
                           value={this.getHoursFromTime(this.createSubtitleForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('start', this.validateSubtitleValue('start', this.convertToMilisec('hour', parseInt(event.target.value, 10), this.createSubtitleForm.value('start'))))}/>
                    <span>h </span>
                    <input min={0} max={60} type="number"
                           value={this.getMinutesFromTime(this.createSubtitleForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('start', this.validateSubtitleValue('start', this.convertToMilisec('minute', parseInt(event.target.value, 10), this.createSubtitleForm.value('start'))))}/>
                    <span>m </span>
                    <input min={0} max={60} type="number"
                           value={this.getSecondsFromTime(this.createSubtitleForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('start', this.validateSubtitleValue('start', this.convertToMilisec('second', parseInt(event.target.value, 10), this.createSubtitleForm.value('start'))))}/>
                    <span>s </span>
                </div>
                <br/>
                {/*  <TextField
                    className="col atas-textfield"
                    label="Start Time"
                    type="number"
                    onChange={(event: any) => this.createSubtitleForm.value('start', parseInt(event.target.value, 10) * 1000)}
                    value={Math.ceil(this.createSubtitleForm.value('start') / 1000)}
                    error={this.createSubtitleForm.validator('start')}
                    helperText={this.createSubtitleForm.validatorMessage('start')}
                />*/}
                <div>
                    <strong style={{display: 'inline-block', width: '50px'}}>End: </strong>
                    <input min={0} max={99} type="number"
                           value={this.getHoursFromTime(this.createSubtitleForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('end', this.validateSubtitleValue('end', this.convertToMilisec('hour', parseInt(event.target.value, 10), this.createSubtitleForm.value('end'))))}/>
                    <span>h </span>
                    <input min={0} max={60} type="number"
                           value={this.getMinutesFromTime(this.createSubtitleForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('end', this.validateSubtitleValue('end', this.convertToMilisec('minute', parseInt(event.target.value, 10), this.createSubtitleForm.value('end'))))}/>
                    <span>m </span>
                    <input min={0} max={60} type="number"
                           value={this.getSecondsFromTime(this.createSubtitleForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createSubtitleForm.value('end', this.validateSubtitleValue('end', this.convertToMilisec('second', parseInt(event.target.value, 10), this.createSubtitleForm.value('end'))))}/>
                    <span>s </span>
                </div>
                <br/>
                {/* <TextField
                    className="col atas-textfield"
                    label="End Time"
                    type="number"
                    onChange={(event: any) => this.createSubtitleForm.value('end', parseInt(event.target.value, 10) * 1000)}
                    value={Math.ceil(this.createSubtitleForm.value('end') / 1000)}
                    error={this.createSubtitleForm.validator('end')}
                    helperText={this.createSubtitleForm.validatorMessage('end')}
                />*/}
                <TextField
                    className="col atas-textfield"
                    label="Subtitle Text"
                    type="text"
                    onChange={(event: any) => this.createSubtitleForm.value('text', event.target.value)}
                    value={this.createSubtitleForm.value('text')}
                    error={this.createSubtitleForm.validator('text')}
                    helperText={this.createSubtitleForm.validatorMessage('text')}
                />
            </ModalComponent>

            <ModalComponent
                ref={(ref: ModalComponent) => (this.createBreakpointModalRef = ref)}
                onSuccess={(d: any) => this.handleCreateBreakpointModalButtonSubmit()}>
                <div>
                    <strong style={{display: 'inline-block', width: '50px'}}>Start: </strong>
                    <input min={0} max={99} type="number"
                           value={this.getHoursFromTime(this.createBreakpointForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('start', this.validateBreakpointValue('start', this.convertToMilisec('hour', parseInt(event.target.value, 10), this.createBreakpointForm.value('start'))))}/>
                    <span>h </span>
                    <input min={0} max={60} type="number"
                           value={this.getMinutesFromTime(this.createBreakpointForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('start', this.validateBreakpointValue('start', this.convertToMilisec('minute', parseInt(event.target.value, 10), this.createBreakpointForm.value('start'))))}/>
                    <span>m </span>
                    <input min={0} max={60} type="number"
                           value={this.getSecondsFromTime(this.createBreakpointForm.value('start'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('start', this.validateBreakpointValue('start', this.convertToMilisec('second', parseInt(event.target.value, 10), this.createBreakpointForm.value('start'))))}/>
                    <span>s </span>
                </div>
                <br/>
                {/* <TextField
                    className="col atas-textfield"
                    label="Start Time"
                    type="number"
                    onChange={(event: any) => this.createBreakpointForm.value('start', parseInt(event.target.value, 10) * 1000)}
                    value={Math.ceil(this.createBreakpointForm.value('start') / 1000)}
                    error={this.createBreakpointForm.validator('start')}
                    helperText={this.createBreakpointForm.validatorMessage('start')}
                />*/}
                <div>
                    <strong style={{display: 'inline-block', width: '50px'}}>End: </strong>
                    <input min={0} max={99} type="number"
                           value={this.getHoursFromTime(this.createBreakpointForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('end', this.validateBreakpointValue('end', this.convertToMilisec('hour', parseInt(event.target.value, 10), this.createBreakpointForm.value('end'))))}/>
                    <span>h </span>
                    <input min={0} max={60} type="number"
                           value={this.getMinutesFromTime(this.createBreakpointForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('end', this.validateBreakpointValue('end', this.convertToMilisec('minute', parseInt(event.target.value, 10), this.createBreakpointForm.value('end'))))}/>
                    <span>m </span>
                    <input min={0} max={60} type="number"
                           value={this.getSecondsFromTime(this.createBreakpointForm.value('end'))}
                           onClick={(event: any) => event.target.select()}
                           onChange={(event: any) => this.createBreakpointForm.value('end', this.validateBreakpointValue('end', this.convertToMilisec('second', parseInt(event.target.value, 10), this.createBreakpointForm.value('end'))))}/>
                    <span>s </span>
                </div>
                <br/>
                {/* <TextField
                    className="col atas-textfield"
                    label="End Time"
                    type="number"
                    onChange={(event: any) => this.createBreakpointForm.value('end', parseInt(event.target.value, 10) * 1000)}
                    value={Math.ceil(this.createBreakpointForm.value('end') / 1000)}
                    error={this.createBreakpointForm.validator('end')}
                    helperText={this.createBreakpointForm.validatorMessage('end')}
                />*/}
                <TextField
                    className="col atas-textfield"
                    label="Breakpoint Text"
                    type="text"
                    onChange={(event: any) => this.createBreakpointForm.value('title', event.target.value)}
                    value={this.createBreakpointForm.value('title')}
                    error={this.createBreakpointForm.validator('title')}
                    helperText={this.createBreakpointForm.validatorMessage('title')}
                />
            </ModalComponent>
        </div>;
    }
}
