import { get } from 'lodash';

export abstract class Translation {

    public static PREFIX = 'com.jmg.e-learning.translation';

    public static get(key: string): string {
        let source: any = localStorage.getItem(`${Translation.PREFIX}.source`) || {};
        try {
            source = JSON.parse(source);
        } catch (err) {
            console.error('Failed to [translation].get(key)', key);
            console.error(err);
            source = {};
        }

        const keyValue: any = get(source, key, {});

        return get(keyValue, Translation.getLanguage(), null) as any || get(keyValue, 'en', key);
    }

    public static fetchTranslations(): Promise<any> {
        try {
            localStorage.setItem(`${Translation.PREFIX}.source`, JSON.stringify({
                'site.homepage.title': {
                    en: 'E-learning site',
                    de: 'E-learning DE site',
                    hu: 'Alma'
                },
                'site.homepage.keywords': {
                    de: 'keyword DE',
                    en: 'keywords EN'
                },
                'site.navigation.dashboard': {
                    en: 'Dashboard',
                    de: 'Armaturenbrett'
                }
            }));

            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [translation].fetchTranslations()');
            console.error(err);
            return Promise.reject(err);
        }

    }

    public static setLanguage(lang: string): void {
        localStorage.setItem(`${Translation.PREFIX}.lang`, lang);
    }

    public static getLanguage(): string {
        return localStorage.getItem(`${Translation.PREFIX}.lang`) as any;
    }
}
