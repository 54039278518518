import * as faker from 'faker';
import { environment } from '../../libs/environment';

export class Subtitle {
    public id: number;
    public '@id': string;
    public end: number;
    public start: number;
    public text: string;

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.id = faker.random.number();
            this.end = obj.end;
            this.start = obj.start;
            this.text = faker.lorem.sentences(4);
        } else {
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.end = obj.end;
            this.start = obj.start;
            this.text = obj.text;
        }
    }
}
