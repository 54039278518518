import * as React from 'react';
import {CookieStorage} from '../../libs/cookie-storage';
import {Repository} from '../../libs/repository/repository';
import {AllModulesPresenter} from './all-modules/all-modules.presenter';
import {DashboardHeroComponent} from './dashboard-hero/dashboard-hero.component';
import './dashboard.scss';
import {MyModulesPresenter} from './my-modules/my-modules.presenter';

export class DashboardComponent extends React.Component<any, any> {
    private cookieStorage: CookieStorage = new CookieStorage();

    public state: any = {
        points: 215,
        inProgressLessons: [],
        finishedLessons: [],
        userId: null,
        userLessonsAreLoading: false

    };

    public componentDidMount() {
        try {
            this.setState({userLessonsAreLoading: true});
            const userId = this.cookieStorage.getItem('UserId');
            this.setState({userId}, async () => {
                const userLessons = await Repository.get(`/user/${this.state.userId}/lesson`);
                const inProgressLessons = await Promise.all(userLessons.inProgress.map(async (lessonId: number) => {
                    const response = await Repository.get(`/lesson/${lessonId}`);
                    if (response.cover) {
                        response.cover = await Repository.get(response.cover);
                    }
                    response.status = 2;
                    return response;
                }));
                const finishedLessons = await Promise.all(userLessons.finished.map(async (lessonId: number) => {
                    const response = await Repository.get(`/lesson/${lessonId}`);
                    if (response.cover) {
                        response.cover = await Repository.get(response.cover);
                    }
                    response.status = 3;
                    return response;
                }));
                this.setState({
                    inProgressLessons,
                    finishedLessons,
                    userLessonsAreLoading: false
                });
            });

        } catch (err) {
            console.error('Failed to [dashboard].componentDidMount()');
            console.error(err);
        }
    }

    scrollTo(id: string) {
        $('html, body').animate({scrollTop: ($(`#${id}`) as any).offset().top - 80}, 400);
    }

    render() {
        return <div className="dashboard">
            <DashboardHeroComponent
                title="Welcome to the ATAS Training Dashboard"
                description="This training site will introduce you to some of the core topics in the touring and adventure sector, as well as training modules from our supplier members."
            />
            <div className="dashboard-navigation">
                <ul>
                    {/*
                    <li><span onClick={() => this.scrollTo('dashboard-overview')}>Overview</span></li>
*/}
                    <li><span onClick={() => this.scrollTo('dashboard-myModules')}>My Lessons</span></li>
                    <li><span onClick={() => this.scrollTo('dashboard-allModules')}>Browse all Lessons</span></li>
                </ul>
            </div>
            <div className="container dashboard-content">
                {/* <div id="dashboard-overview">
                    <OverviewComponent points={this.state.points}/>
                </div>*/}
                <div id="dashboard-myModules">
                    <MyModulesPresenter isLoading={this.state.userLessonsAreLoading}
                                        finishedLessons={this.state.finishedLessons}
                                        inProgressLessons={this.state.inProgressLessons}/>
                </div>
                <div id="dashboard-allModules">
                    <AllModulesPresenter finishedLessons={this.state.finishedLessons}
                                         inProgressLessons={this.state.inProgressLessons}/>
                </div>
            </div>
        </div>;
    }
}
