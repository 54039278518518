import * as React from 'react';
import './task-error.scss';

export class TaskError extends React.Component<any, any> {
    render() {
        return <div className="taskErrorComponent">
            <div className="alert-icon">
                <img className="img-fluid" src={require('../../../../assets/icons/alert.svg')} alt=""/>
            </div>
            <h3>{this.props.title || 'Something went wrong!'}</h3>
            <p>{this.props.description}</p>
        </div>;
    }
}
