import { get, isEqual } from 'lodash';
import * as React from 'react';
import { AtasTextfield } from '../../libs/elements/textfield/atas-textfield';

export class LessonBadge extends React.Component<any, any> {

    public state: any = {
        badge: this.props.badge
    };

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.badge, this.props.badge)) {
            this.setState({
                badge: this.props.badge
            });
        }
    }

    public handleInputChange(property: string, value: any) {
        this.setState({
            badge: {
                ...this.state.badge,
                [property]: (property === 'threshold') ? parseInt(value, 10) : value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.badge);
            }
        });
    }

    public render() {
        return <div>
            <AtasTextfield
                onChange={(event: any) => this.handleInputChange('title', event.target.value)}
                label="Title"
                value={get(this.state, 'badge.title', '') || ''}
            />
            <AtasTextfield
                onChange={(event: any) => this.handleInputChange('description', event.target.value)}
                label="Description"
                value={get(this.state, 'badge.description', '') || ''}
            />
            <AtasTextfield
                onChange={(event: any) => this.handleInputChange('threshold', event.target.value)}
                label="Threshold (%)"
                value={get(this.state, 'badge.threshold', 0) || 0}
                type="number"
                InputProps={{inputProps: {min: 0, max: 100, step: 1}}}
            />

        </div>;
    }
}
