import {get} from 'lodash';
import * as React from 'react';
import {TRUNCATE_TEXT} from '../../../libs/truncate';
import {TaskTypes} from '../../../task.types';
import './task-setting-card.scss';

const icons: any = TaskTypes.taskTypesConfig();

export class TaskSettingCardPresenter extends React.Component<any, any> {

    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any) {
        if (prevProps.task !== get(this.props, 'task')) {
            this.setState({task: this.props.task});
        }
    }

    public render() {
        return <div className="taskPreviewCardPresenter relative"
                    onClick={(event: any) => this.props.onClick ? this.props.onClick(event) : null}>
            <div className="previewContent flex">
                <div className="imageArea flex center-content">
                    <img src={get(icons, `[${get(this.state, 'task.type')}].icon`, '')}/>
                </div>

                <div className="textArea flex flex-column">
                    <p className="card-title">{get(this.state, 'task.title', '-')}</p>
                    <div
                        className="card-text">{(TRUNCATE_TEXT(get(this.state, 'task.lead', ''), 80, '...') || '')}</div>
                </div>
            </div>
            <div className={`used-flag ${get(this.state, 'task.lessonTaskCount') ? 'used' : 'unused'}`}>
                <span>{get(this.state, 'task.lessonTaskCount', 0) ? 'IN USE' : 'UNUSED'}</span>
            </div>
            <div className="task-action-buttons">
                {!this.state.task.hidden &&
                <button className="hide-btn" onClick={() => this.props.onHide(get(this.state, 'task'))}>
                    <i className="far fa-eye-slash"/>
                </button>}
                {/* {this.state.task.hidden &&
                <button className="trash-btn" onClick={() => this.props.onDelete(get(this.state, 'task'))}>
                    <i className="far fa-trash-alt"/>
                </button>}*/}
                {this.state.task.hidden &&
                <button className="unhide-btn" onClick={() => this.props.onUnHide(get(this.state, 'task'))}>
                    <i className="far fa-eye"/>
                </button>}
            </div>
        </div>;
    }
}
