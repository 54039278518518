import {find, findIndex, flatten, get, map, random} from 'lodash';
import * as React from 'react';
import {Placeholder} from '../../../libs/elements/placeholder/placeholder';
import {TaskError} from '../../../libs/elements/task-error/task-error';
import {Helper} from '../../../libs/helper';
import {Repository} from '../../../libs/repository/repository';
import {DragAndDropGroup} from '../../../mock/entities/drag-and-drop-group';
import {TaskPresenterPropsInterface} from '../task-presenter/task-presenter-props.interface';
import './drag-and-drop.presenter.scss';

export class DragAndDropPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    private draggedElementIndex: number | null;
    private draggedElementGroupIndex: number | null;
    // private draggedElementTargetGroupIndex: number | null;

    public state: any = {
        initialized: false,
        error: false,
        dragAndDropGroups: [],
        baseGroup: [],
        validated: false,
        validationValues: null,
        previousValidations: get(this.props, 'config.previousValidations')
    };
    private showValidations = get(this.props.config, 'lessonPreset') !== 'exam';

    componentDidMount() {
        const groups = get(this.props, 'config.config.dragAndDropGroups', []);
        if (groups.length) {
            this.setState({
                initialized: true,
                error: false,
                dragAndDropGroups: groups.map((group: any) => new DragAndDropGroup({
                    id: group.id,
                    title: group.title,
                    items: []
                })),
                baseGroup: new DragAndDropGroup({
                    items: (flatten(groups.map((g: DragAndDropGroup) => g.items) as any) as any[])
                        .sort((a: DragAndDropGroup, b: DragAndDropGroup) => random(0, 1) ? -1 : 1)
                })
            }, () => {
                this.emitSubmitable();
                this.showPreviousValidation();
            });
        } else {
            this.setState({
                error: true
            });
        }
    }

    public showPreviousValidation() {
        const answer = this.state.previousValidations;
        const validationValues = this.state.previousValidations;

        if (answer) {
            const rebased = map(answer, (item: any) => {
                const groupId = Object.keys(item)[0];

                return {
                    groupId,
                    items: (item[groupId] || []).map((i: any) => i.id)
                };
            });

            (rebased || []).forEach((group: any) => {
                const groupIndex = findIndex(this.state.dragAndDropGroups, (dI: any) => {
                    return parseInt(dI.id, 10) === parseInt(group.groupId, 10);
                });

                (group.items || []).map((item: any) => {
                    const itemIndex = findIndex(this.state.baseGroup.items, (i: any) => i.id === item);
                    this.onGroupItemDragStart(null, itemIndex, -1);
                    this.onGroupItemDrop(null, groupIndex);
                });
            });

            this.setState({
                isValidated: this.showValidations,
                validationValues
            });
        }
    }

    public async validateTask(): Promise<any> {
        try {
            const answer: any = [];
            this.state.dragAndDropGroups.forEach((group: any) => {
                const items: any = [];
                group.items.forEach((item: any) => {
                    items.push({id: item.id});
                });
                answer.push({[group.id]: items});
            });

            const validationResponse: any = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer
            });
            const validationValues: any = validationResponse;
            this.setState({isValidated: this.showValidations, validationValues});
            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public emitSubmitable() {
        this.props.config.taskState.set('submitable', !this.state.baseGroup.items.length);
        if (this.props.onState) {
            this.props.onState();
        }
    }

    public renderCard(element: any, index: number, groupIndex: number, groupId: number) {
        if (!element) {
            return false;
        }
        return <div
            className={`card ${this.state.isValidated ? (this.isImageValid(element.id, groupId) ? 'valid-card' : 'invalid-card') : ''}`}
            key={index}
            onDragStart={(event: any) => this.onGroupItemDragStart(event, index, groupIndex)}
            // onDragEnd={(event: any) => this.onGroupItemDragEnd(event, index, groupIndex)}
        >
            <img className="magic-image" src={get(element, 'images[300_300]', '')}/>
            <div className="valid">
                <i className="far fa-check"/>
            </div>
            <div className="invalid">
                <i className="far fa-times"/>
            </div>
        </div>;
    }

    onGroupItemDragStart(event: React.DragEvent<any> | any, elementIndex: number, groupIndex: number) {
        this.draggedElementGroupIndex = groupIndex;
        this.draggedElementIndex = elementIndex;
    }

    onGroupItemDrop(event: React.DragEvent<any> | any, targetGroupIndex: number) {
        if (event) {
            event.preventDefault();
        }
        if (this.draggedElementIndex !== null && this.draggedElementGroupIndex !== null) {
            if (targetGroupIndex !== this.draggedElementGroupIndex) {
                if (targetGroupIndex === -1) {
                    this.state.baseGroup.items.push(this.state.dragAndDropGroups[this.draggedElementGroupIndex].items[this.draggedElementIndex]);
                    this.state.dragAndDropGroups[this.draggedElementGroupIndex].items = Helper.removeFrom(this.state.dragAndDropGroups[this.draggedElementGroupIndex].items, this.draggedElementIndex);
                } else {
                    if (this.draggedElementGroupIndex === -1) {
                        this.state.dragAndDropGroups[targetGroupIndex].items.push(this.state.baseGroup.items[this.draggedElementIndex]);
                        this.state.baseGroup.items = Helper.removeFrom(this.state.baseGroup.items, this.draggedElementIndex);
                    } else {
                        this.state.dragAndDropGroups[targetGroupIndex].items.push(this.state.dragAndDropGroups[this.draggedElementGroupIndex].items[this.draggedElementIndex]);
                        this.state.dragAndDropGroups[this.draggedElementGroupIndex].items = Helper.removeFrom(this.state.dragAndDropGroups[this.draggedElementGroupIndex].items, this.draggedElementIndex);
                    }
                }
            }
            this.setState({
                baseGroup: this.state.baseGroup,
                dragAndDropGroups: this.state.dragAndDropGroups
            }, () => this.emitSubmitable());
            this.draggedElementGroupIndex = null;
            this.draggedElementIndex = null;
        }
    }

    public isImageValid(imageId: number, groupId: number): boolean {
        if (groupId === -1) {
            return false;
        }
        const group = find(this.state.validationValues, groupId);
        if (group) {
            if (group[groupId]) {
                const image = find(group[groupId], (i) => i.id === imageId);
                if (image) {
                    return image.result;
                }
            }
        }
        return false;

    }

    allowDrop(event: any) {
        event.preventDefault();
    }

    render() {
        return <div className="dragAndDropPresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}

            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}
            {this.state.initialized && <div className="w-100 groups">
                <div className="base-group" onDragOver={(event: any) => this.allowDrop(event)}
                     onDrop={(event: any) => this.onGroupItemDrop(event, -1)}>
                    {this.state.baseGroup.items.map((element: any, index: number) => this.renderCard(element, index, -1, -1))}
                </div>
            </div>}
            {this.state.initialized && <div className="w-100 groups">
                {this.state.dragAndDropGroups.map((group: DragAndDropGroup, groupIndex: number) => <div key={groupIndex}
                                                                                                        className="group"
                                                                                                        onDragOver={(event: any) => this.allowDrop(event)}
                                                                                                        onDrop={(event: any) => this.onGroupItemDrop(event, groupIndex)}>
                    <h3>{group.title}</h3>
                    {group.items.map((element: any, elementIndex: number) => this.renderCard(element, elementIndex, groupIndex, group.id))}
                </div>)}
            </div>}
        </div>;
    }
}
