import axios, {AxiosResponse} from 'axios';
import {compact, get} from 'lodash';
import * as React from 'react';
import {MainNavigationComponent} from '../components/main-navigation/main-navigation.component';
import {CookieStorage} from '../libs/cookie-storage';
import {Unauthorized} from '../libs/elements/unauthorized/unauthorized';
import {environment} from '../libs/environment';
import {Repository} from '../libs/repository/repository';
import {Storage} from '../libs/storage';

export class AuthorizedLayout extends React.Component<any, any> {
    private cookieStorage: CookieStorage = new CookieStorage();
    private localStorage: Storage = new Storage();
/*
    private storage: CookieStorage = new CookieStorage();
*/
    private tokenUrl: string = `${environment.apiUrl}/dev-token`;

    public state: any = {
        initialized: false,
        unauthorized: false
    };

    private async updateCookies() {
        try {
            // const siteSpecId = await this.cookieStorage.getItem('CompanySiteSpecId');
            /*const userId = await this.storage.getItem('UserId');*/
            const userId = get(this.state, 'userId');

            const userProfile = await Repository.get(`/user/${userId}`);
            const csIds = compact((userProfile.personCompany || []).map((item: any) => {
                return get(item, 'company.companySiteSpec');
            }));

            const preloadedCompanySiteSpecId = this.cookieStorage.getItem('CompanySiteSpecId');

            await this.localStorage.setItem('UserProfile', userProfile);

            if (csIds.indexOf(preloadedCompanySiteSpecId) > -1) {
                await this.cookieStorage.setItem('CompanySiteSpecId', preloadedCompanySiteSpecId.toString());
            } else {
                await this.cookieStorage.setItem('CompanySiteSpecId', this.cookieStorage.getItem('CompanySiteSpecId') || csIds[0].toString());
            }

            await this.localStorage.setItem('CompanySiteSpecIds', csIds);
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [authorized.layout].updateCookies()');
            console.error(err);
            // @ts-ignore
            return Promise.resolve();
        }
    }

    public updateState() {
        this.setState({
            code: this.cookieStorage.getItem('Code'),
            userId: this.cookieStorage.getItem('UserId')
        });
        // @ts-ignore
        return Promise.resolve();
    }

    public async updateDevCretdentials() {
        try {
            const response: AxiosResponse = await axios.get(this.tokenUrl);

            if (response.data && response.data.code) {
                this.cookieStorage.setItem('Code', response.data.code);
            } else {
                throw new Error('TokenRequestError[code]');
            }

            if (response.data && response.data.user) {
                this.cookieStorage.setItem('UserId', response.data.user);
            } else {
                throw new Error('TokenRequestError[user]');
            }

            // @ts-ignore
            return Promise.resolve(response);
        } catch (err) {
            console.error('Failed to [authorized.layout].updateDevCredentials()');
            console.error(err);
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    async updateUrlCredentials() {
        try {
            if (environment.devTokenLogin) {
                await this.updateDevCretdentials();
            } else {
                const url = new URL(window.location.href);
                const userId = url.searchParams.get('userId');
                const code = url.searchParams.get('token');

                if (code) {
                    this.cookieStorage.setItem('Code', code);
                }

                if (userId) {
                    this.cookieStorage.setItem('UserId', userId);
                }
            }
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [authorized.layout].updateUrlCredentials()');
            console.error(err);
            // @ts-ignore
            return Promise.reject(err);
        }
    }

    isCompanyAdmin() {
        try {
            const userProfile = this.localStorage.getItem('UserProfile');
            const companies = compact(get(userProfile, 'personCompany').map((item: any) => {
                const company = get(item, 'company');
                if (company && company.admin === true) {
                    return company;
                } else {
                    return;
                }
            }));
            return !!companies.length;
        } catch (err) {
            console.error('Failed to [authorized.layout].isCompanyAdmin()');
            console.error(err);
            return false;
        }
    }

    async componentDidMount() {
        try {
            await this.updateUrlCredentials();
            await this.updateState();
            await this.updateCookies();

            this.setState({
                initialized: true,
                unauthorized: false
            });
        } catch (err) {
            console.error('Failed to [authorized.layout].componentDidMount()');
            console.error(err);
            this.setState({
                initialized: false,
                unauthorized: true
            });
        }
    }

    async componentDidUpdate(prevProps: any) {
        try {
            // await this.updateState();
            // await this.updateCookies();
        } catch (err) {
            console.error('Failed to [authorized.layout].componentDidUpdate(prevProps)', prevProps);
            console.error(err);
        }
    }

    render() {
        return <div>
            {!this.props.disableNav && <MainNavigationComponent isCompanyAdmin={this.isCompanyAdmin()}/>}
            {this.state.initialized && this.props.children}
            {this.state.unauthorized && <Unauthorized/>}
        </div>;
    }
}
