import { get } from 'lodash';

export class HydraListResponse {
    public member: any[] = [];
    public totalItems: number = 0;

    constructor(response: any) {
        this.member = get(response, 'hydra:member', []);
        this.totalItems = get(response, 'hydra:totalItems', 0);
    }

    addMapping(typeClass: any) {
        this.member = this.member.map((item: any) => new typeClass(item));
    }
}
