import { get } from 'lodash';
import * as React from 'react';
import { Repository } from '../../libs/repository/repository';
import { Lesson } from '../../mock/entities/lesson';
import { HydraListResponse } from '../../mock/libs/hydra-list-response';
import { LessonCardPresenter } from '../e-modules/lesson/lesson-card.presenter';
import { HeroComponent } from '../hero/hero.component';
import './company-details.component.scss';

export class CompanyDetailsComponent extends React.Component<any, any> {
    public state: any = {
        initialized: false,
        company: {},
        companyIri: this.getCompanyIri(),
        companyId: this.getCompanyId(),
        lessons: new HydraListResponse({})
    };

    componentDidMount() {
        this.updateLessons();
        this.updateCompany();
    }

    componentDidUpdate(prevProps: any) {
        if (get(this.props, 'match.params.companySiteSpecId') !== get(prevProps, 'match.params.companySiteSpecId')) {
            this.setState({
                companyIri: this.getCompanyIri()
            }, () => {
                this.updateLessons();
                this.updateCompany();
            });
        }
    }

    public async updateCompany() {
        try {
            const response: any = await Repository.get(this.state.companyIri);
            this.setState({
                company: response
            });
        } catch (err) {
            console.error('Failed to [company-details].updateCompany()');
            console.error(err);
        }
    }

    public async updateLessons() {
        try {
            const response: HydraListResponse = await Repository.get('/lesson');
            response.addMapping(Lesson);

            this.setState({
                lessons: response
            });

        } catch (err) {
            console.error('Failed to [company-details].updateLessons()');
            console.error(err);
        }
    }

    public render() {
        return <div>
            <HeroComponent cover={get(this.state, 'lesson.cover.images.original', '')} logo={get(this.state, 'company.logo.original')}/>
            <div className="container lessonCardContainer">
                <div className="row flex">
                    {(get(this.state, 'lessons.member', [])).map((lesson: Lesson, index: number) => {
                        return <div className="col-lg-4 mb-3" key={index}>
                            <LessonCardPresenter linkTo={`/cs/${this.state.companySiteSpecId}/${lesson.id}`} lesson={lesson}/>
                        </div>;
                    })}
                </div>
            </div>
        </div>;
    }

    public getCompanyIri(): string {
        return `/company/${get(this.props, 'match.params.companySiteSpecId', null)}`;
    }

    public getCompanyId(): string {
        return get(this.props, 'match.params.companySiteSpecId', null);
    }
}
