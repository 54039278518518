export class Choice {
    public id: number;
    public '@id': string;
    public choice: string = '';
    public correct: boolean;
    public isChecked: boolean = false;

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.choice = obj.choice || '';
        this.correct = obj.correct || false;
    }
}
