import {get, isEqual, random} from 'lodash';
import * as Plyr from 'plyr';
import * as React from 'react';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Repository} from '../../../libs/repository/repository';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/*
import * as ReactQuill from 'react-quill';
*/

export class InformativeSectionTypeSetup extends React.Component<any, any> {
    private readonly canvasDomId: string = `video-Canvas-${random(1000, 9999)}`;
    private plyrInstance: any;

    public state: any = {
        task: this.props.task,
        fakeBool: 1
    };

    public options: any = {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
        fullscreen: {
            enabled: false,
            fallback: false,
            iosNative: false
        }
    };

    componentDidMount() {
        this.initializeVideo();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    initializeVideo() {
        const src = get(this.state, 'task.config.videoUrl', null);

        if (this.plyrInstance) {
            this.plyrInstance.destroy();
            this.plyrInstance = null;
        }
        setTimeout((() => {
            if (src) {
                this.plyrInstance = new Plyr(`#${this.canvasDomId}`, this.options);

                this.plyrInstance.source = {
                    type: 'video',
                    sources: [
                        {
                            src,
                            provider: 'vimeo'
                        }
                    ]
                };
            }
        }), 100);
    }

    handlePropertyChange(prop: string, value: any) {
        if (prop === 'description') {
            value = value.replace('<a href="', '<a href="//');
            value = value.replace('<a href="////', '<a href="//');
            console.log(value);
        }
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public async mediaTypeChange(mt: any) {
        try {
            if (this.state.task.config['@id']) {
                this.state.task.config = this.state.task.config || {};
                if (mt.type === 'image') {
                    this.state.task.config.videoUrl = null;
                    this.state.task.config.type = mt.type;
                    this.state.task.config.media = await Repository.get(get(mt, 'media.@id', mt.media));
                } else if (mt.type === 'video') {
                    this.state.task.config.media = null;
                    this.state.task.config.type = mt.type;
                    this.state.task.config.videoUrl = mt.videoUrl;
                }
            } else {
                this.state.task.config = await Repository.get(get(mt, '@id'));

                if (mt.type === 'image') {
                    this.state.task.config.media = await Repository.get(get(this.state.task, 'config.media.@id', this.state.task.config.media));
                }
            }

            this.setState({
                task: this.state.task
            }, () => {
                if (mt.type === 'video') {
                    this.initializeVideo();
                }
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>
                <div className="col-xs-12">
                    {get(this.state, 'task.config.type') === 'image' &&
                    <div className="card">
                        <div className="card-header">
                            Preview
                        </div>
                        <div className="card-body">
                            <img className="basic-image" src={get(this.state, 'task.config.media.images[original]', '')}
                                 alt=""/>

                        </div>
                    </div>}
                    {get(this.state, 'task.config.type') === 'video' && <div>
                        <div className="card">
                            <div className="card-header">Preview</div>
                            <div className="card-body show-breakpoint-edges">
                                <video id={this.canvasDomId}/>
                            </div>
                        </div>
                    </div>}
                </div>

                <MultiUploadComponent
                    multi={false}
                    enableImage={true}
                    enableVideo={true}
                    title="Upload Image OR Video"
                    onSuccess={(mts: any[]) => this.mediaTypeChange(mts[0])}
                    createMediaType={!this.state.task.config['@id']}/>

            </div>
        </div>;
    }
}
