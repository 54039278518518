import { LessonTask } from './lessonTask';

export class Lesson {
    public '@id': string;
    public id: number;
    public title: string;
    public description: string;
    public status: string;
    public lessonTasks: LessonTask[];
    public createdAt: any;
    public cover: any;
    public endTitle: string;
    public endLead: string;
    public endBody: string;
    public badges: any[] = [];
    public preset: string;

    constructor(obj: any = {}) {
        this['@id'] = obj['@id'];
        this.id = obj.id;
        this.title = obj.title;
        this.status = obj.status;
        this.description = obj.description;
        this.cover = obj.cover || {};
        this.endTitle = obj.endTitle;
        this.endLead = obj.endLead;
        this.endBody = obj.endBody;
        this.badges = obj.badges;
        this.preset = obj.preset;

        this.lessonTasks = (obj.lessonTasks || []).map((lt: any) => {
            if (typeof lt === 'string') {
                return new LessonTask({
                    ['@id']: lt
                });
            } else {
                lt.lessonPreset = this.preset;
                return new LessonTask(lt);
            }

        });
        this.createdAt = obj.createdAt;
    }
}
