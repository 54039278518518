import { get } from 'lodash';
import * as React from 'react';
import './hero.component.scss';

const placeholder = require('../../../assets/images/placeholder.png');

export class HeroComponent extends React.Component<any, any> {
    public state: any = {
        logo: null,
        cover: null
    };

    componentDidMount() {
        this.setState({
            logo: get(this.props, 'logo', placeholder),
            cover: get(this.props, 'cover', '')
        });
    }

    componentDidUpdate(prevProps: any) {
        if (get(this.props, 'logo') !== get(prevProps, 'logo') || get(this.props, 'cover') !== get(prevProps, 'cover')) {
            this.setState({
                logo: get(this.props, 'logo'),
                cover: get(this.props, 'cover')
            });
        }
    }

    render() {
        return <div className="HeroComponent w-100">
            <div className="overlay"/>
            <img className="magic-image" src={get(this.state, 'cover')} alt=""/>
            <div className="container">
                <div className="hero-content">
                    <img className="logo" src={get(this.state, 'logo', placeholder)} alt=""/>
                </div>
            </div>
        </div>;
    }
}
