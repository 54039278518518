import * as React from 'react';
import './circle.scss';

export class Circle extends React.Component<any, any> {
    render() {
        return <div className={`placeholder-component-circle relative ${this.props.size || 'sm'} ${this.props.className || ''}`}>
            <div className="animated-background circle-body"/>
        </div>;
    }
}
