import {get, uniq, find, cloneDeep} from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import {Placeholder} from '../../../libs/elements/placeholder/placeholder';
import {TaskError} from '../../../libs/elements/task-error/task-error';
import {Repository} from '../../../libs/repository/repository';
import {TaskPresenterPropsInterface} from '../task-presenter/task-presenter-props.interface';
import './interactive-slideshow.presenter.scss';
import {SubtitlePresenter} from '../../../libs/elements/subtitle/subtitle.presenter';
import {Breakpoint} from '../../../mock/entities/breakpoint';
import {BreakpointPresenter} from '../video/breakpoint/breakpoint.presenter';
import {Task} from '../../../mock/entities/task';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {TaskPresenter} from '../task-presenter/task.presenter';

export class InteractiveSlideshowPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    private visitedSlides: any = [0];
    private taskModalComponent: ModalComponent;
    private playBlocked: boolean = false;
    private blockerBreakpoints: Breakpoint[] = [];
    private requiredBreakpoints: Breakpoint[] = [];
    private requiredTasks: Breakpoint[] = [];
    private currentTask: Task;
    public state: any = {
        initialized: false,
        error: false,
        play: false,
        pause: true,
        config: {},
        activeIndex: 0,
        canSubmitable: false,
        referencePoint: moment().unix(),
        time: 0
    };

    componentDidMount() {
        this.emitSubmitable(true);

        this.state.initialized = true;
        this.state.activeIndex = 0;
        this.requiredBreakpoints = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => (bp.required && !bp.relatedTask));
        this.requiredTasks = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => (bp.relatedTask));
        this.blockerBreakpoints = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => bp.blocker);
        this.setState({
            config: this.props.config.config
        });

        setInterval(() => {
            if (this.state.play) {
                this.setState({time: this.state.time + 1});
                const dTime = moment().unix() - this.state.referencePoint;
                if ((this.props.config.maxDuration || 1) === dTime) {
                    this.nextSlide();
                }
            }
        }, 1000);
    }

    emitSubmitable(val: boolean) {
        this.props.config.taskState.set('submitable', val);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    onPlayButtonClick() {
        if (!this.state.play) {
            this.setState({
                play: true,
                pause: false,
                referencePoint: moment().unix()
            });
        }
    }

    onTogglePlay() {
        this.setState({
            play: !this.state.play,
            pause: !this.state.pause,
            referencePoint: moment().unix()
        });
    }

    setTime(slideIndex: number) {
        const currentTime = slideIndex * (this.props.config.maxDuration || 1) + 1;
        this.setState({time: currentTime});
    }

    onPauseButtonClick() {
        if (!this.state.pause) {
            this.setState({
                play: false,
                pause: true,
                referencePoint: moment().unix()
            });
        }

    }

    prevSlide() {
        if (this.playBlocked) {
            return;
        }
        const maxIndex = get(this.state, 'config.slides', []).length - 1;

        if (this.state.activeIndex === 0) {
            this.setState({
                referencePoint: moment().unix(),
                activeIndex: maxIndex
            }, () => this.afterChange());
        } else {
            this.setState({
                referencePoint: moment().unix(),
                activeIndex: this.state.activeIndex - 1
            }, () => this.afterChange());
        }
    }

    nextSlide() {
        if (this.playBlocked) {
            return;
        }
        const maxIndex = get(this.state, 'config.slides', []).length - 1;

        if (this.state.activeIndex === maxIndex) {
            this.setState({
                referencePoint: moment().unix(),
                activeIndex: 0
            }, () => this.afterChange());
        } else {
            this.setState({
                referencePoint: moment().unix(),
                activeIndex: this.state.activeIndex + 1
            }, () => this.afterChange());
        }
    }

    afterChange() {
        this.visitedSlides.push(this.state.activeIndex);
        this.visitedSlides = uniq(this.visitedSlides);
        this.setTime(this.state.activeIndex);
        this.emitSubmitable(this.visitedSlides.length === get(this.state, 'config.slides', []).length);
    }

    public async validateTask(): Promise<any> {
        try {
            const validationResponse = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: []
            });

            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    disablePlayButton() {
        this.playBlocked = true;
    }

    enablePlayButton() {
        this.playBlocked = false;
    }

    /*BREAKPOINT HANDLERS*/

    onBreakpointBlock(bp: Breakpoint) {
        const existentBp = find(this.blockerBreakpoints, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.blockerBreakpoints = this.blockerBreakpoints.filter((breakpoint: Breakpoint) => breakpoint.id !== bp.id);
            this.disablePlayButton();
        }
    }

    onBreakpointRequired(bp: Breakpoint) {
        const existentBp = find(this.requiredBreakpoints, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.onPauseButtonClick();
            this.disablePlayButton();
        }
    }

    onBreakpointRequiredTask(bp: Breakpoint) {
        this.currentTask = new Task(cloneDeep(bp.relatedTask));

        const existentBp = find(this.requiredTasks, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.onPauseButtonClick();
            this.taskModalComponent.open(bp);
            this.disablePlayButton();
        }
    }

    onBreakpointSuccess(bp: Breakpoint) {
        this.onPlayButtonClick();
        this.requiredBreakpoints = this.requiredBreakpoints.filter((breakpoint: Breakpoint) => (breakpoint.id !== bp.id));
        this.enablePlayButton();
    }

    onTaskCompleted(bp: Breakpoint) {
        this.onPlayButtonClick();
        this.requiredTasks = this.requiredTasks.filter((breakpoint: Breakpoint) => (breakpoint.id !== bp.id));
        this.enablePlayButton();
    }

    openBreakpointOpenTask(bp: Breakpoint) {
        this.taskModalComponent.open(bp);
    }

    public render() {
        return <div className="relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="hd"/>}
            {!this.state.initialized && <Placeholder.Bar size="sm"/>}

            {this.state.initialized && !this.state.FS &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized && !this.state.FS &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}
            {this.state.initialized && <div className="slideshowPresenter">
                <div className="slideshow-images-wrap">
                    <div className="bumperBee"/>

                    <img className="magic-image"
                         src={get(this.state, `config.slides[${this.state.activeIndex}].mediaItem.images.original`)}/>
                    <div className="breakpoints-wrapper">
                        <BreakpointPresenter
                            onBlock={(bp: Breakpoint) => this.onBreakpointBlock(bp)}
                            onRequired={(bp: Breakpoint) => this.onBreakpointRequired(bp)}
                            onTask={(bp: Breakpoint) => this.onBreakpointRequiredTask(bp)}
                            openTask={(bp: Breakpoint) => this.openBreakpointOpenTask(bp)}
                            onBreakpointSuccess={(bp: Breakpoint) => this.onBreakpointSuccess(bp)}
                            time={this.state.time * 1000}
                            breakpoints={get(this.props, 'config.breakpoints', [])}
                        />
                        <ModalComponent
                            ref={(ref: ModalComponent) => (this.taskModalComponent = ref)}
                            onSuccess={(bp: Breakpoint) => this.onTaskCompleted(bp)}>

                            <TaskPresenter task={this.currentTask}/>
                        </ModalComponent>
                    </div>
                    <button className={`btn-atas center-start-button ${this.state.play ? 'd-none' : ''}`}
                            onClick={() => this.onPlayButtonClick()}>Start the slideshow
                    </button>
                </div>
                <div>
                    <div className="slideshow-progress">
                        {get(this.state, 'config.slides', []).map((item: any, index: any) => <div key={index}
                                                                                                  className={`progress-indicator ${index === this.state.activeIndex ? 'active' : ''}`}/>)}
                    </div>
                    <div className="slideshow-controls">
                        <button onClick={() => this.onTogglePlay()}>
                            <i className={this.state.play ? 'far fa-pause-circle' : 'far fa-play-circle'}/>
                        </button>
                        {/* {!this.state.pause && <button onClick={() => this.onPauseButtonClick()}>
                            <i className="far fa-pause-circle"/>
                        </button>}*/}
                        <div className="slideshow-scroller">
                            <button onClick={() => this.prevSlide()}><i className="fas fa-chevron-left"/></button>
                            <button onClick={() => this.nextSlide()}><i className="fas fa-chevron-right"/></button>
                        </div>
                    </div>
                </div>
            </div>}
            <SubtitlePresenter time={this.state.time * 1000} fileType="slideshow"
                               subtitles={get(this.props, 'config.subtitles', [])}/>

        </div>;
    }
}
