/**
 * example.mp3 duration -> 634 seconds
 */

export class VideoConfig {
    public id: number;
    public '@id': string;
    public videoUrl = '';
    public provider = 'vimeo';
    public duration: number =0;
    public startAt: number = 0;
    public type: string = 'video';

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.videoUrl = obj.videoUrl;
        this.duration = obj.duration;
    }
}
