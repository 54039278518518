import * as faker from 'faker';
import { range } from 'lodash';
import { environment } from '../../libs/environment';
import { MediaItem } from './media-item';

export class DragAndDropGroup {
    public id: number;
    public '@id': string;
    public title: string = '';
    public items: MediaItem[] = [];

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.id = faker.random.number();
            this.title = faker.lorem.sentence(1);
            this.items = range(4).map((item: any, index: number) => new MediaItem({id: item + 1, source: `https://picsum.photos/200/300/?${index + 1}`}));
        } else {
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.title = obj.title || '';
            this.items = (obj.items || []).map((item: any) => new MediaItem(item));
        }
    }
}
