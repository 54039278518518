import { Task } from './task';

export class QuizSubtask {
    public id: number;
    public '@id': string;
    public task: Task;
    public position: number = 0;

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.task = new Task(obj.task) || null;
        this.position = obj.position;
    }
}
