export const TRUNCATE_TEXT = (str: string, length: number, ending: string) => {
    if (length === null) {
        length = 100;
    }
    if (ending === null) {
        ending = '...';
    }

    str = str || '';

    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
