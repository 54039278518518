import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { compact, range } from 'lodash';
import { HydraListResponse } from '../../mock/libs/hydra-list-response';
import { MockSource } from '../../mock/libs/mock-source';
import { environment } from '../environment';

// TODO options mindenhova!

export abstract class Repository {

    public static init() {
        //
    }

    public static async get(entity: string, query: any = {}): Promise<HydraListResponse | any> {
        if (environment.mock) {
            return Repository._getMock(entity, query);
        } else {
            return Repository._get(entity, query);
        }
    }

    public static async pdf(entity: string, query: any = {}): Promise<any> {
        try {
            const response: AxiosResponse = await axios.get(`${environment.apiUrl}${entity}`, {
                params: query,
                withCredentials: true
            });

            return Promise.resolve(response.data);
        } catch (err) {
            console.error('Failed to [repository].pdf(entity, query)', entity, query);
            console.error(err);
            return Promise.reject(err);
        }
    }

    public static async post(entity: string, data: any = {}, options?: AxiosRequestConfig): Promise<any> {
        if (environment.mock) {
            return Repository._postMock(entity, data);
        } else {
            return Repository._post(entity, data, options);
        }
    }

    public static async put(entity: string, data: any = {}): Promise<any> {
        if (environment.mock) {
            return Repository._putMock(entity, data);
        } else {
            return Repository._put(entity, data);
        }
    }

    public static async delete(entity: string): Promise<any> {
        try {
            const response = await axios.delete(`${environment.apiUrl}${entity}`, {withCredentials: true});
            return Promise.resolve(response);
        } catch (err) {
            console.error('Failed to [repository].delete(entity)', entity);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _get(entity: string, query: any = {}): Promise<any> {
        try {
            const response: AxiosResponse = await axios.get(`${environment.apiUrl}${entity}`, {
                params: query,
                withCredentials: true
            });

            if (Repository.isList(entity)) {
                return Promise.resolve(new HydraListResponse(response.data));
            } else {
                return Promise.resolve(response.data);
            }
        } catch (err) {
            console.error('Failed to [repository]._get(entity, query)', entity, query);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _put(entity: string, data: any = {}): Promise<any> {
        try {
            const response: AxiosResponse = await axios.put(`${environment.apiUrl}${entity}`, data, {
                withCredentials: true
            });

            return Promise.resolve(response.data);
        } catch (err) {
            console.error('Failed to [repository]._put(entity, data)', entity, data);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _post(entity: string, data: any = {}, options?: AxiosRequestConfig | any): Promise<any> {
        try {
            const response: AxiosResponse = await axios.post(`${environment.apiUrl}${entity}`, data, {
                // ...options,
                cancelToken: options ? options.cancelToken : null,
                onUploadProgress: options ? options.onUploadProgress : null,
                withCredentials: true
            });
            return Promise.resolve(response.data);
        } catch (err) {
            console.error('Failed to [repository]._post(entity, data, options)', entity, data, options);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _getMock(entity: string, query: any = {}): Promise<any> {
        try {
            if (Repository.isList(entity)) {
                return Promise.resolve(Repository.generateList(entity, query));
            } else {
                return Promise.resolve(Repository.generateEntity(entity));
            }
        } catch (err) {
            console.error('Failed to [repository]._getMock(entity, query)', entity, query);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _postMock(entity: string, data: any = {}): Promise<any> {
        try {
            if (Repository.isList(entity)) {
                return Promise.resolve(Repository.generateEntity(entity, data));
            } else {
                throw new Error('POST NOT ALLOWED');
            }
        } catch (err) {
            console.error('Failed to [repository]._postMock(entity, data)', entity, data);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static async _putMock(entity: string, data: any = {}): Promise<any> {
        try {
            if (Repository.isList(entity)) {
                throw new Error('PUT NOT ALLOWED');
            } else {
                return Promise.resolve(Repository.generateEntity(entity, data));
            }
        } catch (err) {
            console.error('Failed to [repository]._putMock(entity, data)', entity, data);
            console.error(err);
            return Promise.reject(err);
        }
    }

    private static generateEntity(entity: string, data: any = {}) {
        try {
            const parts = compact((entity || '').split('/'));
            const ent: string = parts[0];
            const id: string = parts[1];
            data.id = id;
            return MockSource[ent](data);
        } catch (err) {
            console.error('Failed to [repository].generateEntity(entity, data)', entity, data);
            console.error(err);
            throw new Error('MOCK NOT FOUND');
        }
    }

    private static generateList(entity: string, query: any) {
        try {
            const cleanEntity = (entity || '').replace('/', '');
            return new HydraListResponse({
                'hydra:member': range(query.limit || 10).map(() => MockSource[cleanEntity]()),
                'hydra:totalItems': (query.limit || 10) * 12
            });
        } catch (err) {
            console.error('Failed to [repository].generateList(entity, query)', entity, query);
            console.error(err);
            throw new Error('MOCK NOT FOUND');
        }
    }

    private static isList(entity: string) {
        return compact((entity || '').split('/')).length < 2;
    }
}
