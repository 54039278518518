import { get } from 'lodash';
import * as React from 'react';
import { TRUNCATE_TEXT } from '../../../libs/truncate';
import { TaskTypes } from '../../../task.types';
import './task-thumbnail.presenter.scss';

const icons: any = TaskTypes.taskTypesConfig();

export class TaskThumbnailPresenter extends React.Component<any, any> {

    public render() {
        return <div className={`taskThumbnailPresenter ${(this.props.isCurrentTask) ? 'highlighted' : ''} ${(!this.props.isRepeatable) ? 'non-repeatable' : ''}`}>
            <div className="previewContent flex">
                <div className="imageArea flex center-content">
                    <img src={get(icons, `[${this.props.type}].icon`, '')}/>
                </div>

                <div className="textArea flex flex-column">
                    <p className="card-title">{this.props.title || ''}</p>
                    <div className="card-text">{(TRUNCATE_TEXT(get(this.props, 'lead', ''), 80, '...') || '')}</div>
                </div>
            </div>
            <div className="previewContentFooter flex align-items-center more" onClick={() => this.props.onClick ? this.props.onClick() : () => null}>
                <span className={(this.props.isCurrentTask ? 'current-task-text' : '')}>{((this.props.isCurrentTask) ? 'You are currently viewing this task' : (!this.props.isRepeatable) ? 'THIS TASK CANNOT BE REVISITED' : 'GO TO TASK')}</span>&nbsp;
                {!this.props.isCurrentTask && <i className="fa fa-chevron-right"/>}
            </div>
        </div>;
    }
}
