import { findIndex, get } from 'lodash';
import * as React from 'react';
import { FeedbackBubble } from '../../../libs/elements/feedback-bubble/feedback-bubble';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import { Repository } from '../../../libs/repository/repository';
import { TaskPresenterPropsInterface } from '../task-presenter/task-presenter-props.interface';
import './complete-sentence.presenter.scss';

export class CompleteSentencePresenter extends React.Component<TaskPresenterPropsInterface, any> {
    public state: any = {
        initialized: false,
        sentence: [],
        synonyms: [],
        isValidated: false,
        previousValidations: get(this.props, 'config.previousValidations')
    };

    private showValidations = get(this.props.config, 'lessonPreset') !== 'exam';

    public getVariableIndexByWordIndex(wordIndex: number): number {
        let counter = 0;

        for (let i = 0; i < wordIndex; i++) {
            if (this.state.sentence[i] === '<==>') {
                counter++;
            }
        }

        return counter;
    }

    componentDidMount() {

        this.setState({
            sentence: (get(this.props, 'config.config.sentence', '')).split(' ')
        }, () => {
            this.setState({
                initialized: true,
                synonyms: this.state.sentence
                    .filter((i: any) => this.isVariable(i))
                    .map((i: any, index: number) => ({groupId: this.getInterpolatorId(i), answer: ''}))
            }, () => {
                this.emitSubmitable();
                this.showPreviousValidation();
            });
        });

    }

    emitSubmitable() {
        const truthy = this.state.synonyms.map((i: any) => i.answer).filter(i => i);
        this.props.config.taskState.set('submitable', truthy.length === this.state.synonyms.length);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    handleSynonymChange(wordIndex: number, value: string) {
        this.state.synonyms[wordIndex].answer = value;

        this.setState({
            synonyms: this.state.synonyms
        }, () => {
            this.emitSubmitable();
        });
    }

    public showPreviousValidation() {
        const answers = this.state.previousValidations;

        if (answers && answers.length) {
            this.setState({
                isValidated: this.showValidations,
                synonyms: (this.state.synonyms || []).map((syn: any) => {
                    const valueForThis = answers.find((a: any) => syn.groupId === a.groupId);
                    // const resultForThis = result.find((a: any) => syn.groupId === a.groupId);

                    if (valueForThis) {
                        syn.result = get(valueForThis, 'result', false);
                        syn.correct = valueForThis.correct;
                        syn.answer = valueForThis.answer;
                    }

                    return syn;
                })
            }, () => this.emitSubmitable());
        }
    }

    public async validateTask(): Promise<any> {
        try {

            const validationResponse: any = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: this.state.synonyms
            });

            this.setState({
                isValidated: this.showValidations
            });

            (validationResponse || []).forEach((item: any) => {
                this.state.synonyms = (this.state.synonyms || []).map((syn: any) => {
                    if (item.groupId === syn.groupId) {
                        syn.result = item.result;
                        syn.correct = item.correct;
                    }

                    return syn;
                });
            });

            this.setState({
                synonyms: this.state.synonyms
            });

            return Promise.resolve(validationResponse);
        } catch (err) {
            console.log(err);
            return Promise.reject(err);
        }
    }

    public isVariable(input: string) {
        return !!(/^<=([0-9]{1,6})=>$/.test(input));
    }

    public getInterpolatorId(input: string) {
        return parseInt(input.replace('<=', '').replace('=>', ''), 10);
    }

    public getSynIndexByInterpolator(intpltr: string) {
        const synGId = this.getInterpolatorId(intpltr);
        const index = findIndex(this.state.synonyms, (syn: any) => {
            return syn.groupId === synGId;
        });

        return index;
    }

    public render() {
        return <div className="completeSentencePresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}

            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}

            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}

            {this.state.initialized && <div className="completeSentencePresenter bg-light p-4">
                <div className="sentence">
                    {this.state.sentence.map((element: string, index: number) => {

                        if (this.isVariable(element)) {
                            return <div key={index} className="relative feedbackBubbleParent">
                                <input
                                    className={`sentence-input ${this.state.isValidated ? (this.state.synonyms[this.getSynIndexByInterpolator(element)].result ? 'correct' : 'wrong') : ''}`}
                                    type="text"
                                    value={this.state.synonyms[this.getSynIndexByInterpolator(element)].answer}
                                    onChange={(event: any) => this.handleSynonymChange(this.getSynIndexByInterpolator(element), event.target.value)}/>&nbsp;
                                <div className="bubble-container">
                                    {this.state.isValidated && <FeedbackBubble
                                        textWrong={`Sorry, that's incorrect. The answer is "${get(this.state, `synonyms[${this.getSynIndexByInterpolator(element)}].correct[0].word`)}" `}
                                        success={this.state.synonyms[this.getSynIndexByInterpolator(element)].result}/>}
                                </div>
                            </div>;
                        } else {
                            return <strong key={index}>{element}&nbsp;</strong>;
                        }

                    })}
                </div>
            </div>}

        </div>;
    }
}
