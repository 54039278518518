import {get, indexOf, isEqual} from 'lodash';
import * as React from 'react';
import {CookieStorage} from '../../../../libs/cookie-storage';
import {EmptyList} from '../../../../libs/elements/empty-list/empty-list';
import {Loader} from '../../../../libs/elements/loader/loader';
import {Pagination} from '../../../../libs/elements/pagination/pagination';
import {Repository} from '../../../../libs/repository/repository';
import {Task} from '../../../../mock/entities/task';
import {TaskTypes} from '../../../../task.types';
import {TaskPreviewCardPresenter} from '../../../e-modules/task-preview-card/task-preview-card.presenter';
import './task-selector.component.scss';

export class TaskSelectorComponent extends React.Component<any, any> {
    private toastRef: any;
    private cookieStorage: CookieStorage = new CookieStorage();
    public state: any = {
        fetched: false,
        isLoading: false,
        tasks: [],
        typeFilter: '',
        currentPage: 1,
        totalItems: 0,
        selectedTasks: this.props.selectedTasks || [],
        singleSelect: get(this.props, 'singleSelect', false)
    };

    private async fetchTasks(page?: number, limit?: number): Promise<any> {
        try {
            this.setState({isLoading: true});
            const query: any = {};
            (page) ? query.page = page : query.page = 1;
            (limit) ? query.limit = limit : query.limit = 99;
            query.hidden = false;
            query.companyId = this.cookieStorage.getItem('CompanySiteSpecId');
            if (this.state.typeFilter !== '') {
                query.type = this.state.typeFilter;
            }
            const response = await Repository.get('/task', query);
            this.state.tasks = [];
            this.state.totalItems = get(response, 'totalItems', 0);
            this.forceUpdate();

            await Promise.all(get(response, 'member').map((item: any) => {
                this.state.tasks.push({
                    task: new Task(item),
                    isSelected: (indexOf(this.state.selectedTasks, item.id) >= 0)
                });
            }));

            this.setState(this.state);
            this.setState({fetched: true, isLoading: false});

            return Promise.resolve(true);

        } catch (err) {
            console.error('Failed to [task-selector].fetchTasks(page, limit)', page, limit);
            console.error(err);
            this.setState({isLoading: false});
            if (this.props.onError) {
                this.props.onError();
            }
            return Promise.reject(err);
        }
    }

    private selectTask(index: number) {
        if (this.state.singleSelect) {

            this.state.tasks.map((item: any) => {
                item.isSelected = false;
            });

            this.state.tasks[index].isSelected = true;
            this.state.selectedTasks = [get(this.state, `tasks[${index}].task.id`)];
        } else {
            this.state.tasks[index].isSelected = !this.state.tasks[index].isSelected;

            if (this.state.tasks[index].isSelected) {
                this.state.selectedTasks.push(get(this.state, `tasks[${index}].task.id`));
            } else {
                const ind = indexOf(this.state.selectedTasks, get(this.state, `tasks[${index}].task.id`));
                const tempLeftArr = this.state.selectedTasks.splice(0, ind);
                const tempRightArr = this.state.selectedTasks.splice(ind + 1);
                this.state.selectedTasks = tempLeftArr.concat(tempRightArr);
            }
        }
        this.setState(this.state);
    }

    private async handleFilterChange(type: string) {
        this.state.typeFilter = type;
        this.setState(this.state);
        try {
            await this.fetchTasks();
            this.forceUpdate();
        } catch (err) {
            console.error('Failed to [task-selector].handleFilterChange(type)', type);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    private async handlePageChange(page: number, limit: number) {
        try {
            this.setState({currentPage: page});
            await this.fetchTasks(page, limit);
            this.forceUpdate();
        } catch (err) {
            console.error('Failed to [task-selector].handlePageChange(page, limit)', page, limit);
            console.error(err);
        }
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.selectedTasks, this.props.selectedTasks)) {
            this.setState({
                selectedTasks: this.props.selectedTasks
            });
        }
    }

    isAllFilterEnabled() {
        return !(this.props.enabledTypes && this.props.enabledTypes.length);
    }

    async componentDidMount() {
        await this.fetchTasks();
        this.forceUpdate();

        if (this.props.enabledTypes && this.props.enabledTypes.length) {
            this.handleFilterChange(this.getAvailableTypes()[0]);
        }
    }

    public handleSubmit() {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.selectedTasks);
        }
    }

    public getAvailableTypes() {
        if (this.props.enabledTypes && this.props.enabledTypes.length) {
            const all = TaskTypes.toArray();
            return all.filter((i: any) => (this.props.enabledTypes.indexOf(i) > -1));
        } else {
            return TaskTypes.toArray();
        }
    }

    public render() {
        return <div className="taskSelectorComponent">
            <Loader isLoading={this.state.isLoading}/>
            {this.state.fetched && <div>
                <h2>Select Task</h2>
                <div className="form-group atas-select">
                    <label htmlFor="selectTask">Filter type:</label>
                    <i className="fas fa-caret-down"/>
                    <select className="form-control" id="selectTask" value={this.state.typeFilter}
                            onChange={(event) => this.handleFilterChange(event.target.value)}>

                        {this.isAllFilterEnabled() && <option value="">All</option>}

                        {this.getAvailableTypes().map((item: any, index: number) => {
                            return <option key={index} value={item}>{(TaskTypes.taskTypesConfig()[item].name)}</option>;
                        })}
                    </select>
                </div>

                <div className="taskPreviewRoll">
                    {!!this.state.tasks.length && this.state.tasks.map((item: any, index: number) => {
                        return <div key={index}
                                    className={`taskPreviewCardContainer ${item.isSelected ? 'selected' : ''}`}
                                    onClick={(event: any) => this.selectTask(index)}>
                            <TaskPreviewCardPresenter
                                task={get(item, 'task')}
                            />
                        </div>;
                    })}
                    {!this.state.tasks.length && <EmptyList elText="No available task found!"/>}

                </div>
            </div>}
            <div>
                <Pagination page={this.state.currentPage} limit={12} totalItems={get(this.state, 'totalItems', 0)}
                            onPageChange={(page: number, limit: number) => this.handlePageChange(page, limit)}/>
            </div>
        </div>;
    }

}
