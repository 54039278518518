import { filter, get } from 'lodash';
import * as React from 'react';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import { Repository } from '../../../libs/repository/repository';
import { QuizSubtask } from '../../../mock/entities/quiz-subtask';
import { TaskPresenterPropsInterface } from '../task-presenter/task-presenter-props.interface';
import { TaskPresenter } from '../task-presenter/task.presenter';
import './multiple-task.presenter.scss';

export class MultipleTaskPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    private taskRefs: TaskPresenter[] = [];
    public state: any = {
        initialized: false,
        subTasks: [],
        subtaskTaskStates: {},
        previousValidations: get(this.props, 'config.previousValidations', null)
    };

    async componentDidMount() {
        if (get(this.props, 'config.config.subTasks.length')) {
            this.setState({
                initialized: true,
                error: false,
                subTasks: await this.updateSubtaskWithPreviousValidations(get(this.props, 'config.config.subTasks', []))
            }, () => this.emitSubmitable());
        } else {
            this.setState({
                error: true
            });
        }

    }

    public async updateSubtaskWithPreviousValidations(subTasks: QuizSubtask[]) {
        const answer = get(this.state.previousValidations, 'answer');
        const result = get(this.state.previousValidations, 'result');

        /*
           const result = get(this.state.previousValidations, 'result');
        result: get(result, '[0][subtask.task.id]')
         */

        if (answer) {
            subTasks = subTasks.map((subtask: QuizSubtask) => {
                subtask.task.previousValidations = {
                    answer: get(result, `[0][${subtask.task.id}].answer`),
                    result: get(result, `[0][${subtask.task.id}]`)
                };
                return subtask;
            });
        }

        return subTasks;
    }

    public emitSubmitable() {
        const hasUnsubmitableTask = filter(this.state.subtaskTaskStates || {}, (i: any) => !i.submitable).length === 0;

        this.props.config.taskState.set('submitable', hasUnsubmitableTask);
        if (this.props.onState) {
            this.props.onState();
        }
    }

    public handleSubtaskTaskStateChange(subtask: QuizSubtask) {
        this.state.subtaskTaskStates[subtask['@id']] = subtask.task.taskState.toJS();
        this.setState({
            subtaskTaskStates: this.state.subtaskTaskStates
        }, () => this.emitSubmitable());
    }

    public async validateTask(): Promise<any> {

        try {
            const validations: any = await Promise.all((this.taskRefs || []).map(async (taskRef: TaskPresenter) => {
                return {
                    taskId: taskRef.props.task.id,
                    answer: ((await taskRef.validate(true)) as any || {}).answer
                };
            }));

            const refValidation: any = {};

            validations.forEach((validation: any) => {
                refValidation[validation.taskId] = validation;
            });

            const validateResponse: any = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: refValidation
            });

            return Promise.resolve(validateResponse);
        } catch (err) {
            console.log(err.message);
            return Promise.reject(err);
        }
    }

    public render() {
        return <div className="multipleTaskPresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}

            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}

            {this.state.initialized && <div>
                <div>
                    {(get(this.state, 'subTasks', []) || []).map((subTask: QuizSubtask, index: number) => {
                        return <div key={index}>
                            <TaskPresenter
                                ref={(ref: any) => (this.taskRefs[index] = ref)}
                                task={subTask.task}
                                onState={() => this.handleSubtaskTaskStateChange(subTask)}/>
                            <hr/>
                        </div>;
                    })}
                </div>
            </div>}
        </div>;
    }
}
