export abstract class Helper {
    public static insertAt(prevIndex: number, newIndex: number, arr: any[]): any[] {
        const newArr: any[] = [];
        arr.forEach((item: any, index: number) => {
            if (index === prevIndex) {
                if (prevIndex === newIndex) {
                    newArr.push(item);
                }
            } else if (index === newIndex) {
                if (newIndex < prevIndex) {
                    newArr.push(arr[prevIndex]);
                    newArr.push(item);
                } else if (newIndex > prevIndex) {
                    newArr.push(item);
                    newArr.push(arr[prevIndex]);
                } else {
                    newArr.push(item);
                }
            } else {
                newArr.push(item);
            }
        });
        return newArr;
    }

    public static removeFrom(arr: any[], ind: number): any[] {
        const newArr: any[] = [];
        arr.forEach((item: any, index: number) => {
            if (index !== ind) {
                newArr.push(item);
            }
        });
        return newArr;
    }
}
