import { ButtonProps } from '@material-ui/core/Button/Button';
import * as React from 'react';

export class AtasBtn extends React.Component<ButtonProps, any> {

    render() {
        return React.createElement('button', {
            ...this.props,
            className: `btn-atas ${this.props.disabled ? 'disabled' : ''} ${(this.props.className || '')}`
        });
    }
}
