import * as React from 'react';
import './settings-hero.scss';

export class SettingsHeroComponent extends React.Component<any, any> {
    render() {
        return <div className="settings-hero container-fluid">
            <div className="settings-hero-content">
                <h1>{this.props.title}</h1>
                <p>{this.props.description}</p>
            </div>
        </div>;
    }
}
