import { get } from 'lodash';
import * as React from 'react';
import { Subtitle } from '../../../mock/entities/subtitle';
import './subtitle.presenter.scss';

export class SubtitlePresenter extends React.Component<any, any> {
    public state: any = {
        isExpanded: false,
        time: 0,
        currentSubtitle: {},
        fileType: 'video'
    };

    componentDidUpdate(prevProps: any) {
        if (this.props.time !== prevProps.time) {
            this.updateSubtitle(this.props.time);
        }
    }

    public toggleSubtitle() {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    public updateSubtitle(time: number) {
        const possibleSubtitle = (this.props.subtitles || []).filter((s: Subtitle) => {
            return (s.start <= time) && (s.end >= time);
        });
        this.setState({currentSubtitle: get(possibleSubtitle, '[0]', {})});
    }

    render() {
        return this.props.subtitles.length ? <div className="subtitleContainer">
            {!this.state.isExpanded && <span onClick={() => this.toggleSubtitle()}>
                <i className="fal fa-file-alt"/>
                {`Click to read ${get(this, 'props.fileType', 'video')} transcript`}
            </span>}
            {this.state.isExpanded && <span onClick={() => this.toggleSubtitle()}>
                <i className="fal fa-file-alt"/>
                {`Close ${get(this, 'props.fileType', 'video')} transcript`}
            </span>}
            {this.state.isExpanded && <div>
                <p>{get(this.state, 'currentSubtitle.text', '')}</p>
            </div>}
        </div> : '';
    }
}
