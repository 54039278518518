import * as faker from 'faker';
import { random } from 'lodash';
import { environment } from '../../libs/environment';
import { Task } from './task';

export class Breakpoint {
    public id: number;
    public end: number;
    public start: number;
    public title: string;
    public description: string;
    public position: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    public blocker: boolean;
    public required: boolean;
    public checkResult: boolean;
    public task: Task;
    public relatedTask: any;

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.id = faker.random.number();
            this.end = obj.end;
            this.start = obj.start;
            this.title = faker.lorem.sentence(3);
            this.description = faker.lorem.sentence(5);
            this.position = obj.position;
            this.blocker = !!random(0, 1);
            this.checkResult = false; // Refactor
            this.relatedTask = null;
            this.required = !!random(0, 1);

            if (!this.required) {
                this.task = new Task();
            }
        } else {
            this.id = obj.id;
            this.end = obj.end;
            this.start = obj.start;
            this.title = obj.title;
            this.description = obj.description;
            this.position = obj.position || 0;
            this.blocker = obj.blocker;
            this.checkResult = obj.checkResult;
            this.task = obj.task;
            this.relatedTask = obj.relatedTask;
            this.required = obj.required;
        }
    }
}
