import { Map } from 'immutable';
import { each, find, get } from 'lodash';
import * as React from 'react';
import { Subject } from 'rxjs/index';

export class FormLib {
    contexts: Array<React.Component<any, any>> = [];
    values: Map<string, any> = Map({}).asMutable();
    validators: Map<string, any> = Map({}).asMutable();
    validatorResponses: Map<string, any> = Map({}).asMutable();
    validatorMessages: Map<string, any> = Map({}).asMutable();
    onChanges$: Subject<Map<string, any>> = new Subject();

    constructor(obj: any) {
        each(obj, (value: any, key: string) => {
            this.value(key, get(value, 'value'));
            this.validators.set(key, get(value, 'validators'));
            this.validatorMessages.set(key, '');
            this.validatorResponses.set(key, false);
        });
    }

    private updateConext() {
        if (!this.contexts.length) {
            return;
        }

        this.contexts.forEach((context: any) => {
            context.forceUpdate();
        });
    }

    private runValidators(property: string, value: any) {
        const validators = this.validators.get(property) || [];

        for (const validator of validators) {
            const vResponse = validator(value);

            if (vResponse.isValid) {
                this.validatorResponses.set(property, false);
                this.validatorMessages.set(property, '');
            } else {
                this.validatorResponses.set(property, true);
                this.validatorMessages.set(property, vResponse.message);
                break;
            }
        }
    }

    public update(obj: any) {
        each(obj || {}, (value: any, key: string) => this.value(key, value));
    }

    public value(property: string, value?: any): any {
        if (value !== undefined) {
            let uC = false;

            if (this.values.get(property) !== value) {
                uC = true;
            }

            this.values.set(property, value);
            this.runValidators(property, value);
            this.onChanges$.next(this.values);

            if (uC) {
                this.updateConext();
            }
        }

        return this.values.get(property);
    }

    /**
     * Return true if invalid
     */
    public validator(property: string): boolean {
        return this.validatorResponses.get(property);
    }

    public validateForm() {
        this.values.forEach((value: any, key: string) => {
            this.runValidators(key, value);
        });

        this.updateConext();
    }

    public isValid(): boolean {
        return !find(this.validatorResponses.toObject(), (value: any) => value);
    }

    public validatorMessage(property: string): string {
        return this.validatorMessages.get(property);
    }

    public setContext(context: React.Component<any, any> | Array<React.Component<any, any>>) {
        if (Array.isArray(context)) {
            this.contexts = context;
        } else {
            this.contexts = [context];
        }
    }
}
