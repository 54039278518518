import FormControlLabel from '@material-ui/core/FormControlLabel';
import {get, remove, sortBy} from 'lodash';
import * as React from 'react';
import {AtasCheckBox} from '../../../libs/elements/checkbox/atas-checkbox';
import {EmptyList} from '../../../libs/elements/empty-list/empty-list';
import {FeedbackToast} from '../../../libs/elements/feedback-toast/feedback-toast';
import {Loader} from '../../../libs/elements/loader/loader';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {TabNavigationComponent, TabNavigationItemComponent} from '../../../libs/elements/tab-navigation.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Helper} from '../../../libs/helper';
import {Repository} from '../../../libs/repository/repository';
import {Lesson} from '../../../mock/entities/lesson';
import {Task} from '../../../mock/entities/task';
import {TaskPreviewCardPresenter} from '../../e-modules/task-preview-card/task-preview-card.presenter';
import {HeroComponent} from '../../hero/hero.component';
import {LessonBadge} from '../lesson-badge';
import {TaskCreatorComponent} from '../task.creator.component';
import './lesson-creator.scss';
import {TaskSelectorComponent} from './task-selector/task-selector.component';

const SortableLessonList = require('./../../../../node_modules/sortablejs/Sortable.js');

export class LessonCreatorComponent extends React.Component<any, any> {
    public lessonId: string = `/lesson/${get(this.props, 'match.params.lessonId')}`;
    public state: any = {
        initialized: false,
        lesson: null,
        lessonTasks: [],
        curentLessonTask: null,
        company: null,
        creatorTask: null,
        isLoading: false,
        tab: 0
    };
    private updateTaskModalRef: ModalComponent;
    private updateTaskComponentRef: TaskCreatorComponent;
    private createTaskModalRef: ModalComponent;
    private createTaskComponentRef: TaskCreatorComponent;
    private taskSelectorModalRef: ModalComponent;
    private taskSelectorModalSelectorRef: TaskSelectorComponent;
    private taskListRef: any = null;
    private toastRef: any;
    private endOfLessonModalRef: any;

    // @ts-ignore
    private async handleSelectTaskSubmitted(tasks: any) {
        try {
            let currentGreatest = this.state.lessonTasks.length;
            // @ts-ignore
            await Promise.all(tasks.map(async (item) => {
                currentGreatest = currentGreatest + 1;
                return Repository.post('/lesson-task', {
                    lesson: get(this.state, 'lesson.@id', ''),
                    task: `task/${item}`,
                    position: currentGreatest
                });
            }));
            this.taskSelectorModalRef.close();
            await this.updateLessonTasksData(this.lessonId);
        } catch (err) {
            console.error('Failed to [lesson-creator].handleSelectTaskSubmitted(tasks)', tasks);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    // @ts-ignore
    private async updateLessonTask(prevId: number, newId: number): Promise<any> {
        const lessonTasks = Helper.insertAt(prevId, newId, this.state.lesson.lessonTasks);
        this.state.lesson.lessonTasks = lessonTasks;
        this.setState({
            lesson: this.state.lesson
        });
        try {
            await Repository.put(get(this.state, `lesson.lessonTasks[${newId}].@id`), {position: newId + 1});
        } catch (err) {
            console.error('Failed to [lesson-creator].updateLessonTask(prevId, newId)', prevId, newId);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    // @ts-ignore
    private async updateBadge(b: any): Promise<any> {
        return Repository.put(get(b, '@id'), b);
    }

    // @ts-ignore
    private async forceSaveLessonTaskPositions() {
        // @ts-ignore
        await Promise.all(this.state.lessonTasks.map(async (item: any, index: number) => {
            return Repository.put(get(item, '@id'), {position: index + 1});
        }));
    }

    // @ts-ignore
    private async saveLesson() {
        try {
            this.setState({isLoading: true});
            await this.forceSaveLessonTaskPositions();
            const lesson = this.state.lesson;

            // @ts-ignore
            await Promise.all((this.state.lesson.badges || []).map(async (badge: any) => this.updateBadge(badge)));

            await Repository.put(get(this.state, 'lesson.@id'), {
                title: lesson.title,
                description: lesson.description,
                endTitle: lesson.endTitle,
                endLead: lesson.endLead,
                endBody: lesson.endBody,
                status: lesson.status,
                preset: lesson.preset
            });
            this.toastRef.showToast('Successfully saved!', 'success');
        } catch (err) {
            console.error('Failed to [lesson-creator].saveLesson()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
        } finally {
            this.setState({isLoading: false});
        }
    }

    public resetCreatorTask() {
        this.setState({
            creatorTask: new Task()
        });
    }

    // @ts-ignore
    public async updateLessonData(iri: string) {
        try {
            this.setState({isLoading: true});
            const lesson: any = new Lesson(await Repository.get(iri));
            if (!get(lesson, 'cover.images')) {
                if (typeof lesson.cover === 'string') {
                    const cover = await Repository.get(get(lesson, 'cover'));
                    lesson.cover = cover;
                }
            }

            if (get(lesson, 'badges').length) {

                // @ts-ignore
                const badges: any = await Promise.all(get(lesson, 'badges').map(async (badge: any) => {
                    return Repository.get(badge);
                }));
                lesson.badges = badges;
            }
            // @ts-ignore
            let lessonTasks: any = await Promise.all((lesson.lessonTasks).map(async (lessonTaskIri: any) => {
                const lessonTask: any = await Repository.get(lessonTaskIri['@id']);
                lessonTask.task = new Task(await Repository.get(lessonTask.task));
                return lessonTask;
            }));

            lessonTasks = sortBy(lessonTasks, (lessonTask: any) => {
                return lessonTask.position;
            });

            this.setState({
                lesson,
                lessonTasks,
                isLoading: false
            });

        } catch (err) {
            console.error('Failed to [lesson-creator].updateLessonData(iri)', iri);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    // @ts-ignore
    public async updateLessonTasksData(iri: string) {
        try {
            this.setState({isLoading: true});
            const lesson: any = new Lesson(await Repository.get(iri));
            // @ts-ignore
            let lessonTasks: any = await Promise.all((lesson.lessonTasks).map(async (lessonTaskIri: any) => {
                const lessonTask: any = await Repository.get(lessonTaskIri['@id']);
                lessonTask.task = new Task(await Repository.get(lessonTask.task));
                return lessonTask;
            }));

            lessonTasks = sortBy(lessonTasks, (lessonTask: any) => {
                return lessonTask.position;
            });

            this.setState({
                lessonTasks,
                isLoading: false
            });

        } catch (err) {
            console.error('Failed to [lesson-creator].updateLessonTasksData(iri)', iri);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    public async removeTask(lessonTaskIri: string) {
        try {
            await Repository.delete(lessonTaskIri);
            this.state.lesson.lessonTasks = remove(this.state.lesson.lessonTasks, (item: any) => {
                return lessonTaskIri !== get(item, '@id');
            });
            this.updateLessonTasksData(this.lessonId);
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [lesson-creator].removeTask(lessonTaskIri)', lessonTaskIri);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    public handleInputChange(property: string, value: any) {
        this.setState({
            lesson: {
                ...this.state.lesson,
                [property]: value
            }
        });
    }

    async componentDidMount() {
        try {
            this.setState({initialized: false});
            await this.updateLessonData(this.lessonId);
            await this.initializeSortable();
            await this.updateCompany();
            this.setState({initialized: true});
        } catch (err) {
            console.error('Failed to [lesson-creator].componentDidMount()');
            console.error(err);
        }
    }

    initializeSortable() {
        if (this.taskListRef) {
            new SortableLessonList(this.taskListRef, {
                onEnd: async (event: any) => {
                    return this.updateLessonTask(event.oldIndex, event.newIndex);
                }
            });
        }
    }

    handleLessonTaskClick(lessonTask: any) {
        this.setState({
            currentLessonTask: lessonTask
        });

        this.updateTaskModalRef.open();
    }

    async handleTaskUpdateSubmit() {
        try {
            await this.updateTaskComponentRef.submitTask();
            this.updateTaskModalRef.close();
            this.updateLessonData(this.lessonId);
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [lesson-creator].handleTaskUpdateSubmit()');
            console.error(err);
        }
    }

    public async updateCoverImage(media: any) {
        try {
            const response = await Repository.put(get(this.state, 'lesson.@id'), {cover: media.media['@id']});
            this.setState({
                lesson: {
                    ...this.state.lesson,
                    cover: media.media
                }
            });
            // @ts-ignore
            return Promise.resolve(response);
        } catch (err) {
            console.error('Failed to [lesson-creator].updateCoverImage(media)', media);
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
            // @ts-ignore
            return Promise.reject();
        }
    }

    async handleTaskCreateSubmit() {
        try {
            const newTask = await this.createTaskComponentRef.submitTask();
            this.createTaskModalRef.close();

            Repository.post('/lesson-task', {lesson: get(this.state, 'lesson.@id', ''), task: get(newTask, '@id')});
            await this.updateLessonTasksData(this.lessonId);
            this.toastRef.showToast('New task successfully created!', 'success');
            // @ts-ignore
            return Promise.resolve();
        } catch (err) {
            console.error('Failed to [lesson-creator].handleTaskCreateSubmit()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
            // @ts-ignore
            return Promise.reject();
        }
    }

    // @ts-ignore
    public async addBadge() {
        try {
            const levels = ['gold', 'silver', 'bronze'];
            const response = await Repository.post('/badge', {
                title: 'BADGE TITLE',
                description: 'BADGE LEVEL DESCRIPTION',
                lesson: get(this.state, 'lesson.@id'),
                code: levels[get(this.state, 'lesson.badges', []).length],
                threshold: (100 - (20 * get(this.state, 'lesson.badges', []).length))
            });
            this.state.lesson.badges.push(response);
            this.setState({
                lesson: {
                    ...this.state.lesson,
                    badges: this.state.lesson.badges
                }
            });
        } catch (err) {
            console.error('Failed to [lesson-creator].addBadge()');
            console.error(err);
        }
    }

    // @ts-ignore
    public async deleteBadge(iri: string) {
        try {
            const response = await Repository.delete(iri);
            if (response) {
                const badges = remove(this.state.lesson.badges, (item: any) => {
                    return iri !== get(item, '@id');
                });
                this.setState({
                    lesson: {
                        ...this.state.lesson,
                        badges
                    }
                });
            }
        } catch (err) {
            console.error('Failed to [lesson-creator].deleteBadge()');
            console.error(err);
        }
    }

    handleCreateNewTaskClick() {
        this.resetCreatorTask();
        this.createTaskModalRef.open();
    }

    public handlePresetChange(preset: string) {
        this.setState({
            lesson: {
                ...this.state.lesson,
                preset
            }
        });
    }

    public render() {

        return <div>
            <HeroComponent logo={get(this.state, 'company.logo.original')}
                           cover={get(this.state, 'lesson.cover.images.original', '')}/>
            <div className="relative lessonCreator">
                <div className="container ">

                    <div className="col-xs-12">
                        <div className="buttons-wrap">
                            <MultiUploadComponent
                                multi={false}
                                enableImage={true}
                                title={<span>
                                        <i className="fa fa-file-image"/>
                                        <span>&nbsp;Change Cover</span>
                                    </span>}
                                onSuccess={(mts: any[]) => this.updateCoverImage(mts[0])}
                                createMediaType={false}/>
                            <button className={'btn-atas'} onClick={() => this.saveLesson()}>Save</button>
                        </div>
                    </div>

                    <TabNavigationComponent
                        active={this.state.tab}
                        onActiveChange={(t: number) => this.setState({tab: t}, () => this.initializeSortable())}
                        items={[
                            <TabNavigationItemComponent key={0} title="Tasks"/>,
                            <TabNavigationItemComponent key={1} title="Settings"/>
                        ]}/>

                    {this.state.tab === 1 && <div className="col-xs-12">
                        <AtasTextfield
                            onChange={(event: any) => this.handleInputChange('title', event.target.value)}
                            label="Title"
                            value={get(this.state, 'lesson.title', '')}
                        />
                        <AtasTextfield
                            onChange={(event: any) => this.handleInputChange('description', event.target.value)}
                            label="Description"
                            value={get(this.state, 'lesson.description', '')}
                            multiline={true}
                        />

                        <FormControlLabel
                            control={<AtasCheckBox
                                onChange={(event: any) => this.handleInputChange('status', (event.target.checked) ? 1 : 0)}
                                checked={get(this.state, 'lesson.status') === 1}
                            />}
                            label="Published"
                        />
                        <div className="row">
                            <div className="col-md-4 col-xs-12">
                                <div className="form-group atas-select">
                                    <label htmlFor="selectTask">Lesson preset:</label>
                                    <i className="fas fa-caret-down"/>
                                    <select className="form-control"
                                            onChange={(event: any) => this.handlePresetChange(event.target.value)}
                                            value={this.state.lesson.preset}>
                                        <option value="practice">Practice</option>
                                        <option value="exam">Exam</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <h3 className="lesson-creator-title">End of Module settings:</h3>
                        <AtasTextfield
                            onChange={(event: any) => this.handleInputChange('endTitle', event.target.value)}
                            label="Title"
                            value={get(this.state, 'lesson.endTitle', '')}
                        />
                        <AtasTextfield
                            onChange={(event: any) => this.handleInputChange('endLead', event.target.value)}
                            label="Lead"
                            value={get(this.state, 'lesson.endLead', '')}
                            multiline={true}
                        />
                        <AtasTextfield
                            onChange={(event: any) => this.handleInputChange('endBody', event.target.value)}
                            label="Description"
                            value={get(this.state, 'lesson.endBody', '')}
                            multiline={true}
                        />
                        <h3 className="lesson-creator-title">Badge setting</h3>
                        {(get(this.state, 'lesson.badges', []).length < 3) &&
                        <button className="btn-atas add-badge" onClick={() => this.addBadge()}>
                            <i className="far fa-plus"/>
                            <span>Add badge</span>
                        </button>}
                        <div className="badge-creator">
                            {this.state.lesson.badges.map((badge: any, badgeIndex: number) => {
                                return <div key={badgeIndex} className="card">
                                    <div className="card-header flex justify-content-between">
                                        <h3 className="card-title">{badge.code.toUpperCase()}</h3>
                                        <button className="trash-btn"
                                                onClick={() => this.deleteBadge(get(badge, '@id'))}>
                                            <i className="far fa-trash-alt"/>
                                        </button>
                                    </div>
                                    <div className="card-body">
                                        <LessonBadge
                                            onChange={(changes: any) => this.handleBadgeChange(badgeIndex, changes)}
                                            badge={badge}/>
                                    </div>
                                </div>;

                            })}
                        </div>

                        <ModalComponent ref={(ref: any) => (this.endOfLessonModalRef = ref)}>
                            <h1>{get(this.state, 'lesson.endTitle', '')}</h1>
                            <div className="col-lg-12">
                                <p className="lead-text">{get(this.state, 'lesson.endLead', '')}</p>
                                <p className="intro-text">{get(this.state, 'lesson.endBody', '')}</p>
                            </div>
                        </ModalComponent>
                    </div>}

                    {this.state.tab === 0 && <div className="col-xs-12">
                        <div className="buttons-wrap">
                            <button className="btn-atas" onClick={() => this.handleCreateNewTaskClick()}>
                                <i className="far fa-plus"/>
                                <span>Create new task</span>
                            </button>
                            <button className="btn-atas" onClick={() => this.taskSelectorModalRef.open()}>
                                <i className="far fa-plus-circle"/>
                                Add Task
                            </button>
                        </div>
                    </div>
                    }

                    {this.state.tab === 0 && <div>
                        <div className="row" ref={(ref: any) => this.taskListRef = ref}>

                            {!!this.state.lessonTasks.length && (this.state.lessonTasks).map((lessonTask: any, index: number) =>
                                <div
                                    className="col-md-6 col-lg-4" key={index}>
                            <span className="remove"
                                  onClick={() => this.removeTask(get(lessonTask, '@id'))}><i
                                className="far fa-minus-circle basic-tooltip"><span className="tooltiptext">Remove from lesson</span></i></span>
                                    <TaskPreviewCardPresenter task={lessonTask.task}
                                                              onClick={() => this.handleLessonTaskClick(lessonTask)}/>
                                </div>)}
                            {!this.state.lessonTasks.length && <EmptyList elText="No selected task yet!"/>}

                        </div>
                        <ModalComponent ref={(ref: any) => (this.updateTaskModalRef = ref)} buttons={[
                            <button key={0} className="btn-atas"
                                    onClick={() => this.handleTaskUpdateSubmit()}>Update</button>,
                            <button key={1} className="btn-atas"
                                    onClick={() => this.updateTaskModalRef.close()}>Close</button>
                        ]}>
                            <TaskCreatorComponent ref={(ref: any) => (this.updateTaskComponentRef = ref)}
                                                  isCreate={false}
                                                  task={get(this.state, 'currentLessonTask.task')}/>
                        </ModalComponent>
                        <ModalComponent ref={(ref: any) => (this.createTaskModalRef = ref)} buttons={[
                            <button key={0} className="btn-atas"
                                    onClick={() => this.handleTaskCreateSubmit()}>Done</button>,
                            <button key={1} className="btn-atas"
                                    onClick={() => this.createTaskModalRef.close()}>Close</button>
                        ]}>
                            <TaskCreatorComponent ref={(ref: any) => (this.createTaskComponentRef = ref)}
                                                  isCreate={true}
                                                  task={this.state.creatorTask}/>
                        </ModalComponent>
                        <ModalComponent
                            ref={(ref: any) => (this.taskSelectorModalRef = ref)}
                            buttons={[
                                <button key={0} className="btn-atas"
                                        onClick={() => this.taskSelectorModalSelectorRef.handleSubmit()}>Add Selected
                                    Tasks</button>,
                                <button key={1} className="btn-atas"
                                        onClick={() => this.taskSelectorModalRef.close()}>Close</button>
                            ]}>
                            <TaskSelectorComponent
                                ref={(ref: any) => (this.taskSelectorModalSelectorRef = ref)}
                                onSubmit={(tasks: any) => this.handleSelectTaskSubmitted(tasks)}
                                onError={() => this.toastRef.showToast('Something went wrong!', 'error')}
                            />
                        </ModalComponent>
                    </div>}

                    <Loader isLoading={this.state.isLoading}/>
                    <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
                </div>
            </div>

        </div>;
    }

    handleBadgeChange(index: number, changes: any) {
        this.state.lesson.badges[index] = changes;

        this.setState({
            lesson: this.state.lesson
        });
    }

    public async updateCompany() {
        try {
            const companyIri = `/company/${get(this.props, 'match.params.companySiteSpecId', null)}`;
            const response: any = await Repository.get(companyIri);
            this.setState({
                company: response
            });
        } catch (err) {
            console.error('Failed to [lesson-creator].updateCompany()');
            console.error(err);
        }
    }
}
