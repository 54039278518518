import {get, isEqual} from 'lodash';
import * as React from 'react';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {RangeSliderComponent} from '../../../libs/elements/range-slider.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Repository} from '../../../libs/repository/repository';
import {VideoConfig} from '../../../mock/entities/task-configs/video.config';
import {VideoPresenter} from '../../e-modules/video/video.presenter';
import './task-setup.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class VideoTypeSetup extends React.Component<any, any> {
    public state: any = {
        task: this.props.task,
        fakeBool: 1,
        time: 0,
        duration: 0
    };

    updateFakeBool() {
        this.setState({
            fakeBool: 0
        }, () => {
            this.setState({
                fakeBool: 1
            });
        });
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public async mediaTypeChange(mt: any) {
        try {

            if (this.state.task.config['@id']) {
                this.state.task.config.videoUrl = mt.videoUrl;
            } else {
                this.state.task.config = new VideoConfig(await Repository.get(get(mt, '@id')));
            }

            this.state.task.config.provider = 'vimeo';
            this.state.task.config.type = 'video';

            this.setState({
                task: this.state.task
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }

                this.updateFakeBool();
            });
        } catch (err) {
            alert(err.message);
        }
    }

    handleSubtitleChange(s: any[]) {
        this.setState({
            task: {
                ...this.state.task,
                subtitles: s
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handleBreakpointChange(s: any[]) {
        this.setState({
            task: {
                ...this.state.task,
                breakpoints: s
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Preview</span>
                        <MultiUploadComponent
                            multi={false}
                            enableVideo={true}
                            title="Upload Video"
                            videoUrl={get(this.state, 'task.config.videoUrl', '')}
                            onSuccess={(mts: any[]) => this.mediaTypeChange(mts[0])}
                            createMediaType={false}/>

                    </div>
                    <div className="card-body">
                        {this.state.fakeBool && <VideoPresenter
                            editMode={true}
                            config={{
                                ...this.state.task,
                                subtitles: [],
                                breakpoints: []
                            }}
                            onDurationChanged={(duration) => {
                                this.setState({duration});
                            }}
                            onState={() => this.setState({
                                time: this.state.task.taskState.get('time') || 0,
                                duration: this.state.task.taskState.get('duration') || 0
                            })}/>}
                    </div>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Settings</span>

                    </div>
                    <div className="card-body">
                        <RangeSliderComponent
                            onSubtitles={(s: any[]) => this.handleSubtitleChange(s)}
                            onBreakpoints={(b: any[]) => this.handleBreakpointChange(b)}
                            subtitles={this.state.task.subtitles}
                            breakpoints={this.state.task.breakpoints}
                            time={this.state.time}
                            allTime={(this.state.duration * 1000) || 100000}/>

                    </div>
                </div>

            </div>
        </div>
            ;
    }
}
