import Chip from '@material-ui/core/Chip';
import * as jQuery from 'jquery';
import {cloneDeep, find, get, isEqual} from 'lodash';
import * as React from 'react';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Repository} from '../../../libs/repository/repository';
import {Synonym} from '../../../mock/entities/synonym';
import './complete-the-sentence.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class CompleteSentenceTypeSetup extends React.Component<any, any> {
    public state: any = {
        task: this.props.task,
        words: [''],
        selectedGroupId: 0,
        newSynonym: ''
    };
    private cardRef: any;
    private synonymsModalRef: any;

    parseInputSentence() {
        this.state.words = this.state.task.config.sentence.split(' ');
        this.setState({
            task: this.props.task
        });
    }

    componentDidMount() {
        this.parseInputSentence();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }

        try {
            const prevWordNumber = prevState.words.length;
            const nextWordNumber = this.state.words.length;

            if (prevWordNumber < nextWordNumber) {
                jQuery(this.cardRef).find(`[data-index="${nextWordNumber - 1}"]`).focus();
            }

            if (prevWordNumber > nextWordNumber) {
                jQuery(this.cardRef).find(`[data-index="${nextWordNumber - 1}"]`).focus();
            }

        } catch (err) {
            console.error('Failed to [complete-sentence-type-setup].componentDidUpdate(prevProps, prevState)', prevProps, prevState);
            console.error(err);
        }
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    updateWord(word: any, index: number) {
        this.state.words[index] = word;
        const joined = this.state.words
            .join(' ')
            .replace('  ', ' ');

        this.state.task.config.sentence = joined;

        this.setState({
            words: joined.split(' '),
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handleSynonymClick(groupId: number) {
        this.setState({
            selectedGroupId: groupId,
            newSynonym: ''
        }, () => {
            this.synonymsModalRef.open();
        });
    }

    handleAddNewSynonymInputChange(event: any) {
        this.setState({
            newSynonym: event.target.value
        });
    }

    getSynGroupId() {
        return Math.ceil(Math.random() * 100000);
    }

    async handleAddNewSyn() {
        try {
            const response = new Synonym(await Repository.post('/synonyms', {
                word: this.state.newSynonym,
                groupID: this.state.selectedGroupId
            }));

            this.state.task.config.synonyms.push(response);

            this.setState({
                task: this.state.task,
                newSynonym: ''
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    async handleDeleteSyn(syn: Synonym) {
        try {
            this.state.task.config.synonyms = this.state.task.config.synonyms.filter((item: Synonym) => {
                return item['@id'] !== syn['@id'];
            });

            await Repository.delete(syn['@id']);

            this.setState({
                task: this.state.task,
                newSynonym: ''
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Sentence</span>
                    </div>
                    <div className="card-body" ref={(ref: any) => (this.cardRef = ref)}>

                        <div className="cts-create-input-wrapper">
                            {this.state.words.map((word: string, index: number) => {
                                return <div key={index} className="relative-papa">
                                    <input
                                        type="text"
                                        value={word}
                                        key={index}
                                        className={`cts-create-input ${this.isVariable(word) ? 'hidden' : ''}`}
                                        data-index={index}
                                        onChange={(event: any) => this.updateWord(event.target.value, index)}/>

                                    {(!this.isVariable(word)) ? this.renderSimpleText(word, index) : this.renderVariableText(word, index)}
                                </div>;
                            })}
                        </div>

                    </div>
                </div>

                <ModalComponent ref={(ref: any) => (this.synonymsModalRef = ref)} mini={true}>
                    <div className="flex add-new-syn-wrapper">

                        <AtasTextfield
                            value={this.state.newSynonym}
                            placeholder="Add new Synonym"
                            onChange={(event: any) => this.handleAddNewSynonymInputChange(event)}/>

                        <i className="fa fa-plus" onClick={() => this.handleAddNewSyn()}/>

                    </div>

                    <div className="syn-chip-container">
                        {this.state.task.config.synonyms
                            .filter((syn: Synonym) => (syn.groupID === this.state.selectedGroupId))
                            .map((syn: Synonym, index: number) => <div key={index} className="syn-chips">
                                <Chip label={syn.word} onDelete={() => this.handleDeleteSyn(syn)}/>
                            </div>)}
                    </div>
                </ModalComponent>

            </div>
        </div>;
    }

    public async handleToSynClick(index: number) {
        try {
            const newSynId = this.getSynGroupId();

            this.setState({
                selectedGroupId: newSynId,
                newSynonym: this.state.words[index]
            }, async () => {
                await this.handleAddNewSyn();
                this.updateWord(`<=${newSynId}=>`, index);
            });
        } catch (err) {
            console.error('Failed to [complete-sentence-type-setup].handleToSynClick(index)', index);
            console.error(err);
        }
    }

    public isVariable(input: string) {
        return !!(/^<=([0-9]{1,6})=>$/.test(input));
    }

    public getInterpolatorId(input: string) {
        return parseInt(input.replace('<=', '').replace('=>', ''), 10);
    }

    public handleToSimpleTextClick(index: number) {
        const word = this.state.words[index];
        const synInterpolId = this.getInterpolatorId(word);
        const getFirstSyn = cloneDeep(find(this.state.task.config.synonyms, (syn: Synonym) => (synInterpolId === syn.groupID)));
        this.state.task.config.synonyms = (this.state.task.config.synonyms || []).filter((syn: Synonym) => (synInterpolId !== syn.groupID));
        this.updateWord(get(getFirstSyn, 'word', 'TEXT_HERE'), index);
    }

    public getVariableIndexByWordIndex(wordIndex: number): number {
        let counter = 0;

        for (let i = 0; i < wordIndex; i++) {
            if (this.isVariable(this.state.words[i])) {
                counter++;
            }
        }

        return counter;
    }

    public renderSimpleText(word: string, index: number) {
        return <div className="relative synPres">
            <div className="to-synonym" onClick={() => this.handleToSynClick(index)}>
                <i className="fas fa-exchange-alt"/>
            </div>
            <span className="cts-create-input hidden">{word}</span>
        </div>;
    }

    public renderVariableText(word: string, index: number) {
        return <span className="cts-create-variable relative">
            <div className="to-smiple-text" onClick={() => this.handleToSimpleTextClick(index)}>
                <i className="fal fa-trash-alt"/>
            </div>
            <button className="btn-atas flex justify-content-center align-items-center"
                    onClick={() => this.handleSynonymClick(this.getInterpolatorId(word))}>
                {this.getVariableIndexByWordIndex(index) + 1}
            </button>
        </span>;
    }
}
