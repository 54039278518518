import {find, get, isEqual} from 'lodash';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {EmptyList} from '../../../libs/elements/empty-list/empty-list';
import {FeedbackToast} from '../../../libs/elements/feedback-toast/feedback-toast';
import {Loader} from '../../../libs/elements/loader/loader';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {Pagination} from '../../../libs/elements/pagination/pagination';
import {Repository} from '../../../libs/repository/repository';
import {LessonCardPresenter} from '../../e-modules/lesson/lesson-card.presenter';
import {LessonPreview} from '../lesson-preview/lesson-preview';
import './all-modules.scss';

export class AllModulesPresenter extends React.Component<any, any> {
    private lessonPreviewModalRef: any;
    private toastRef: any;
    public state: any = {
        isLoading: false,
        items: [],
        page: 0,
        limit: 3,
        totalItems: 0,
        open: true,
        previewLesson: null,
        inProgressLessons: this.props.inProgressLessons || [],
        finishedLessons: this.props.finishedLessons || []
    };

    private async fetchLesson(page: number, limit: number) {
        try {
            this.setState({isLoading: true});
            const response = await Repository.get('/lesson', {
                page,
                limit,
                status: 1,
                // 'order[position]': 'ASC'
                'order[createdAt]': 'DESC'
            });

            response.member = await Promise.all(response.member.map(async (lesson: any) => {
                if (lesson.cover) {
                    lesson.cover = await Repository.get(lesson.cover);
                }
                return lesson;
            }));

            this.setState({
                items: response.member,
                totalItems: response.totalItems,
                page,
                limit,
                isLoading: false
            }, () => {
                this.setInProgress();
                this.setFinished();
            });
        } catch (err) {
            console.error('Failed to [all-modules].fetchLesson(page, limit)', page, limit);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.inProgressLessons, this.props.inProgressLessons)) {
            this.setState({
                inProgressLessons: this.props.inProgressLessons
            }, () => {
                this.setInProgress();
                this.setFinished();
            });
        }

        if (!isEqual(prevProps.finishedLessons, this.props.finishedLessons)) {
            this.setState({
                finishedLessons: this.props.finishedLessons
            }, () => {
                this.setInProgress();
                this.setFinished();
            });
        }
    }

    componentDidMount() {
        this.fetchLesson(1, this.state.limit);
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    public setInProgress() {
        this.state.items = (this.state.items || []).map((lesson: any) => {
            if (find(this.state.inProgressLessons, (i) => i.id === lesson.id)) {
                lesson.status = 2;
            }
            return lesson;
        });
        this.setState({
            items: this.state.items
        });
    }

    public setFinished() {
        this.state.items = (this.state.items || []).map((lesson: any) => {
            if (find(this.state.finishedLessons, (i) => i.id === lesson.id)) {
                lesson.status = 3;
            }
            return lesson;
        });
        this.setState({
            items: this.state.items
        });
    }

    public openPreview(lesson: any) {
        this.setState({previewLesson: lesson}, () => this.lessonPreviewModalRef.open());
    }

    actualItems() {
        const actualFirstItem = (this.state.page * this.state.limit) - (this.state.limit - 1);
        const actualLastItem = this.state.page * this.state.limit;
        return `Showing ${actualFirstItem} - ${actualLastItem} of ${this.state.totalItems}`;
    }

    render() {
        return (<div className="allModulesPresenter relative">
            <ModalComponent
                ref={(ref: any) => (this.lessonPreviewModalRef = ref)}
                buttons={[
                    (get(this.state, 'previewLesson.status', 0) === 1) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic atas">Start Lesson</Link> : false,
                    (get(this.state, 'previewLesson.status', 0) === 2) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic inProgress atas">Resume Lesson</Link> : false,
                    (get(this.state, 'previewLesson.status', 0) === 3) ?
                        <Link key={1}
                              to={`/cs/${get(this.state, 'previewLesson.companyId')}/${get(this.state, 'previewLesson.id', '')}`}
                              className="btn-basic complete atas">Restart Lesson</Link> : false
                ]}>
                <LessonPreview lesson={this.state.previewLesson} onClose={() => this.lessonPreviewModalRef.close()}/>
            </ModalComponent>
            <div className="modules-open">
                <h2>Browse all Lessons</h2>
                <button className={this.state.open ? 'far fa-chevron-circle-up' : 'far fa-chevron-circle-down'}
                        onClick={() => this.toggle()}/>
            </div>
            <div className={`collapsible ${this.state.open ? '' : 'hide'}`}>
                <div className="row">
                    {!!this.state.items.length && this.state.items.map((lesson: any, index: number) => {
                        return <div className="col-sm-6 col-lg-4 cardcol" key={index}>
                            <LessonCardPresenter learnMore={() => this.openPreview(lesson)}
                                                 linkTo={`/cs/${lesson.companyId}/${lesson.id}`} lesson={lesson}/>
                        </div>;
                    })}
                    {!this.state.items.length && <EmptyList elText="No lesson found!"/>}

                </div>
                <Pagination
                    totalItems={this.state.totalItems}
                    limit={this.state.limit}
                    currentPage={this.state.page}
                    onPageChange={(page: number) => this.fetchLesson(page, this.state.limit)}
                />
            </div>
            <Loader isLoading={this.state.isLoading}/>
            <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
        </div>);
    }
}
