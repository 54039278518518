import * as jQuery from 'jquery';
import * as React from 'react';
import './multi-upload.scss';

export class ProgressTileComponent extends React.Component<any, any> {
    private barRef: HTMLDivElement;

    public state: any = {
        percentage: this.props.percentage,
        error: this.props.error,
        success: this.props.success
    };

    private setProgress(percentage: number) {
        jQuery(this.barRef).css('width', `${percentage}%`);
    }

    componentDidMount() {
        this.setProgress(this.props.percentage);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.percentage !== this.props.percentage) {
            this.setProgress(this.props.percentage);
            this.setState({percentage: this.props.percentage});
        }

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setProgress(100);
            }
            this.setState({error: this.props.error});
        }

        if (prevProps.success !== this.props.success) {
            if (this.props.success) {
                this.setProgress(100);
            }
            this.setState({success: this.props.success});
        }
    }

    render() {
        return <div className="progress w-100">
            <div
                ref={(ref: any) => (this.barRef = ref)}
                className={`progress-bar progress-bar-animated progress-bar-striped ${this.state.error ? 'bg-danger' : ''} ${this.state.success ? 'bg-success' : ''}`}
                role="progressbar"
                aria-valuenow={this.state.percentage}
                aria-valuemin={0} aria-valuemax={100}/>
        </div>;
    }
}
