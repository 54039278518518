import { get } from 'lodash';
import * as React from 'react';
import { ModalComponent } from '../../../../libs/elements/modal/modal.component';
import { Breakpoint } from '../../../../mock/entities/breakpoint';
import './breakpoint.presenter.scss';

export class BreakpointPresenter extends React.Component<any, any> {
    private tapHereModal: ModalComponent;

    public state: any = {
        time: 0,
        currentBreakpoint: null,
        currentBlocker: null,
        currentRequired: null,
        currentTask: null,
        hideProvisory: false
    };

    componentDidUpdate(prevProps: any) {
        if (this.props.time !== prevProps.time) {
            this.hasBreakpoint(this.props.time);
        }
    }

    hasBreakpoint(time: number) {
        const posibleBreakpoints = (this.props.breakpoints || []).filter((bp: Breakpoint) => {
            return (bp.start <= time) && (bp.end >= time);
        });

        const possibleBlockers = posibleBreakpoints.filter((bp: Breakpoint) => {
            return bp.blocker;
        });

        const possibleRequired = posibleBreakpoints.filter((bp: Breakpoint) => {
            return bp.required;
        });

        const possibleTasks = posibleBreakpoints.filter((bp: Breakpoint) => {
            return bp.relatedTask;
        });

        this.setState({
            currentBreakpoint: get(posibleBreakpoints, '[0]', null),
            currentBlocker: get(possibleBlockers, '[0]', null),
            currentRequired: get(possibleRequired, '[0]', null),
            currentTask: get(possibleTasks, '[0]', null)
        });
    }

    public void(): void {
        //
    }

    handleOnTapHere(bp: Breakpoint, modalArgs: any) {
        if (this.tapHereModal) {
            this.tapHereModal.close();
        }

        if (this.props.onBreakpointSuccess) {
            this.props.onBreakpointSuccess(bp);
        }
    }

    handleOpenTask(bp: Breakpoint, modalArgs: any) {
        if (this.props.openTask) {
            this.props.openTask(bp);
        }
    }

    render() {

        if (this.state.currentBlocker && this.props.onBlock) {
            this.props.onBlock(this.state.currentBlocker);
        }

        if (this.state.currentRequired && this.props.onRequired) {
            this.props.onRequired(this.state.currentRequired);
        }

        if (this.state.currentTask && this.props.onTask) {
            this.props.onTask(this.state.currentTask);
        }

        return <div className="breakpointPresenter">
            {this.state.currentBreakpoint && <div className={`positioned-breakpoint position-${this.state.currentBreakpoint.position || 0}`}>
                <div className="circle-box"/>
                <div className="text-box">

                    {!this.state.currentBreakpoint.relatedTask && <div>
                        <p className="title">{this.state.currentBreakpoint.title}</p>
                        <p className="description">{this.state.currentBreakpoint.description}</p>
                        <p className="tap-here" onClick={() => this.tapHereModal ? this.tapHereModal.open() : () => this.void()}>Please tap here</p>
                    </div>}

                    <ModalComponent
                        ref={(ref: ModalComponent) => (this.tapHereModal = ref)}
                        onSuccess={(args: any) => this.handleOnTapHere(this.state.currentBreakpoint, args)}>
                        <h3>{this.state.currentBreakpoint.title}</h3>
                        <h4>{this.state.currentBreakpoint.description}</h4>
                    </ModalComponent>

                    {this.state.currentBreakpoint.required && !this.state.currentBreakpoint.relatedTask && <button
                        className="d-none d-lg-block d-xl-block btn-atas"
                        onClick={() => this.handleOnTapHere(this.state.currentBreakpoint, null)}>
                        <span>Next</span>
                    </button>}

                    {this.state.currentBreakpoint.relatedTask && <button
                        className="d-none d-lg-block d-xl-block btn-atas"
                        onClick={() => this.handleOpenTask(this.state.currentBreakpoint, null)}>
                        <span>Open Task</span>
                    </button>}

                    {this.state.currentBreakpoint.relatedTask && <p className="tap-here" onClick={() => this.handleOpenTask(this.state.currentBreakpoint, null)}>Open Task</p>}

                </div>
            </div>}
        </div>;
    }
}
