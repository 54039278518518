import { Action, Location } from 'history';
import createHistory from 'history/createHashHistory';
import { Subject } from 'rxjs/index';

export class RouterLib {
    public onChange$ = new Subject<Location>();
    private history = createHistory();

    constructor() {

        this.history.listen((location: Location, action: Action) => {
            // location is an object like window.location
            this.onChange$.next(location);
        });
    }

    public getLocation(): Location {
        return this.history.location;
    }
}
