import { get, isEqual } from 'lodash';
import * as React from 'react';
import { EmptyList } from '../../../libs/elements/empty-list/empty-list';
import { ModalComponent } from '../../../libs/elements/modal/modal.component';
import { AtasTextfield } from '../../../libs/elements/textfield/atas-textfield';
import { Repository } from '../../../libs/repository/repository';
import { QuizSubtask } from '../../../mock/entities/quiz-subtask';
import { Task } from '../../../mock/entities/task';
import { TaskTypes } from '../../../task.types';
import { TaskPreviewCardPresenter } from '../../e-modules/task-preview-card/task-preview-card.presenter';
import { TaskSelectorComponent } from '../lesson-creator/task-selector/task-selector.component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class QuizTypeSetup extends React.Component<any, any> {
    private taskSelectorModalSelectorRef: any;
    private taskSelectorModalRef: any;
    private taskListRef: any;
    private toastRef: any;
    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public async handleSelectTaskSubmitted(tasks: any) {
        try {

            const newSubtask = await Promise.all(tasks.map(async (task: any, index: number) => {
                const response = new Task(await Repository.get(`/task/${task}`));
                return new QuizSubtask({
                    position: (this.state.task.config.subTasks.length) + index,
                    task: response
                });
            }));

            this.state.task.config.subTasks.push(...newSubtask);

            this.setState({
                task: this.state.task
            });

            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
            this.taskSelectorModalRef.close();

        } catch (err) {
            this.setState({
                isLoading: false
            });
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    public removeSubTask(subTaskIndex: number) {
        this.state.task.config.subTasks = (this.state.task.config.subTasks || []).filter((subTask: any, index: number) => (index !== subTaskIndex));

        this.setState({
            task: this.state.task
        });

        if (this.props.onChange) {
            this.props.onChange(this.state.task);
        }
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div>
                    <ModalComponent
                        ref={(ref: any) => (this.taskSelectorModalRef = ref)}
                        buttons={[
                            <button key={0} className="btn-atas"
                                    onClick={() => this.taskSelectorModalSelectorRef.handleSubmit()}>Add Selected
                                Tasks</button>,
                            <button key={1} className="btn-atas"
                                    onClick={() => this.taskSelectorModalRef.close()}>Close</button>
                        ]}>
                        <TaskSelectorComponent
                            enabledTypes={[TaskTypes.CHOICE]}
                            ref={(ref: any) => (this.taskSelectorModalSelectorRef = ref)}
                            onSubmit={(tasks: any) => this.handleSelectTaskSubmitted(tasks)}
                            onError={() => this.toastRef.showToast('Something went wrong!', 'error')}
                        />
                    </ModalComponent>

                    <button className="btn-atas" onClick={() => this.taskSelectorModalRef.open()}>
                        <i className="far fa-plus-circle"/>
                        <span>Add Task</span>
                    </button>

                    <br/>

                    <div className="row" ref={(ref: any) => this.taskListRef = ref}>

                        {get(this.state, 'task.config.subTasks', []).map((subtask: any, index: number) =>
                            <div className="col-md-6 col-lg-4" key={index}>

                                <span className="remove far fa-minus-circle"
                                      onClick={() => this.removeSubTask(index)}/>
                                <TaskPreviewCardPresenter task={subtask.task}/>

                            </div>)}

                        {!get(this.state, 'task.config.subTasks', []).length &&
                        <EmptyList elText="No selected task yet!"/>}

                    </div>
                </div>
            </div>
        </div>;
    }
}
