import {get} from 'lodash';
import * as React from 'react';
import {Placeholder} from '../../../libs/elements/placeholder/placeholder';
import {TaskError} from '../../../libs/elements/task-error/task-error';
import {Repository} from '../../../libs/repository/repository';
import {Task} from '../../../mock/entities/task';
import {TaskPresenterPropsInterface} from '../task-presenter/task-presenter-props.interface';
import {TaskPresenter} from '../task-presenter/task.presenter';
import './informative-section.presenter.scss';

export class InformativeSectionPresenter extends React.Component<TaskPresenterPropsInterface, any> {

    public state: any = {
        initialized: false
    };

    componentDidMount() {
        if (get(this.props, 'config.title') && get(this.props, 'config.title')) {
            this.setState({
                initialized: true,
                error: false
            }, () => this.emitSubmitable());
        } else {
            this.setState({
                error: true
            });
        }
    }

    public async validateTask(): Promise<any> {
        try {

            const validationResponse: any = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: null
            });
            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public emitSubmitable() {
        this.props.config.taskState.set('submitable', true);
        if (this.props.onState) {
            this.props.onState();
        }
    }

    public render() {
        if ((get(this.props, 'config.config.@type', '') === 'MediaType')) {
            if (get(this.props, 'config.config.type', '') === 'video') {
                return <TaskPresenter
                    {...this.props.config}
                    task={new Task({
                        type: 'video',
                        title: get(this.props, 'config.title', 'TASK_TITLE'),
                        description: get(this.props, 'config.description', 'TASK_DESCRIPTION'),
                        config: get(this.props, 'config.config')
                    })}/>;
            } else if (get(this.props, 'config.config.type', '') === 'image') {
                return <div className="informativeSectionPresenter relative task-presenter">
                    {this.state.error && <TaskError description="Task configuration is incorrect"/>}
                    {!this.state.initialized && <Placeholder.Bar size="xl"/>}
                    {!this.state.initialized && <Placeholder.Bar size="md"/>}
                    {!this.state.initialized && <Placeholder.Bar size="huge"/>}

                    {this.state.initialized &&
                    <div>
                        <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>
                        <div className="description wysiwyg-presenter"
                             dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>
                        <div>
                            <img className="informative-section-image"
                                 src={get(this.props, 'config.config.media.images[original]', '')}/>
                        </div>
                    </div>
                    }
                </div>;
            }

        } else {
            return <div className="informativeSectionPresenter relative task-presenter">
                {this.state.error && <TaskError description="Task configuration is incorrect"/>}
                {!this.state.initialized && <Placeholder.Bar size="xl"/>}
                {!this.state.initialized && <Placeholder.Bar size="md"/>}
                {!this.state.initialized && <Placeholder.Bar size="huge"/>}

                {this.state.initialized &&
                <div>
                    <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>
                    <div className="description wysiwyg-presenter"
                         dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>
                </div>
                }
            </div>;
        }
    }
}
