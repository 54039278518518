import { QuizSubtask } from '../quiz-subtask';

export class QuizConfig {
    public id: number;
    public '@id': string;
    public subTasks: QuizSubtask[];

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.subTasks = (obj.subTasks || []).map((i: any) => new QuizSubtask(i));
    }
}
