import * as faker from 'faker';
import { environment } from '../../../libs/environment';
import { Synonym } from '../synonym';

export class CompleteSentenceConfig {
    public id: number;
    public '@id': string;
    public sentence: string = '';
    public task: string = '';
    public synonyms: Synonym[] = [];

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.sentence = obj.sentence || `${faker.lorem.sentence(3)}<%=%>(${faker.lorem.sentence(2)}<%=%>${faker.lorem.sentence(4)}<%=%>`;
        } else {
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.sentence = obj.sentence || '';
            this.task = obj.task || '';
            this.synonyms = (obj.synonyms || []).map((s: any) => new Synonym(s));
        }
    }
}
