import { environment } from '../../../libs/environment';
import { DragAndDropGroup } from '../drag-and-drop-group';

export class DragAndDropConfig {
    public id: number;
    public '@id': string;
    public dragAndDropGroups: any = [];

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.dragAndDropGroups = [
                new DragAndDropGroup(),
                new DragAndDropGroup(),
                new DragAndDropGroup()
            ];
        } else {
            this.id = obj.id;
            this['@id'] = obj['@id'];
            this.dragAndDropGroups = (obj.dragAndDropGroups || []).map((group: any) => new DragAndDropGroup(group));
        }
    }
}
