import {get} from 'lodash';
import * as React from 'react';
import {TRUNCATE_TEXT} from '../../../libs/truncate';
import {TaskTypes} from '../../../task.types';
import './task-preview-card.presenter.scss';

const icons: any = TaskTypes.taskTypesConfig();

export class TaskPreviewCardPresenter extends React.Component<any, any> {

    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any) {
        if (prevProps.task !== get(this.props, 'task')) {
            this.setState({task: this.props.task});
        }
    }

    public render() {
        return <div className="taskPreviewCardPresenter"
                    onClick={(event: any) => this.props.onClick ? this.props.onClick(event) : null}>
            <div className="previewContent flex">
                <div className="imageArea flex center-content">
                    <img src={get(icons, `[${get(this.state, 'task.type')}].icon`, '')}/>
                </div>

                <div className="textArea flex flex-column">
                    <p className="card-title">{get(this.state, 'task.title', '-')}</p>
                    <div className="card-text">{(TRUNCATE_TEXT(get(this.state, 'task.lead', ''), 80, '...') || '')}</div>
                </div>
            </div>
        </div>;
    }
}
