import {get, isEqual} from 'lodash';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {Repository} from '../../../libs/repository/repository';
import {TRUNCATE_TEXT} from '../../../libs/truncate';
import './lesson-card.presenter.scss';

export class LessonCardPresenter extends React.Component<any, any> {

    public state: any = {
        initialized: false,
        lesson: {}
    };

    componentDidMount() {
        this.setState({
            initialized: true,
            lesson: get(this.props, 'lesson')
        }, () => {
            // console.log(this.state.lesson);
        });
    }

    componentDidUpdate(prevState: any) {
        if (!isEqual(prevState.lesson, this.props.lesson)) {
            this.setState({
                lesson: get(this.props, 'lesson')
            });
        }
    }

    public getLessonStatus() {
        switch (get(this.state, 'lesson.status')) {
            case 1:
                return 'readyToStart';
            case 2:
                return 'inProgress';
            case 3:
                return 'complete';
            default:
                return '';
        }
    }

    public async restartModule() {
        try {
            await Repository.post('/lesson/start', {
                lessonId: this.state.lesson.id
            });

            (window as any)._history.push(this.props.linkTo || '');
        } catch (err) {
            // handle error
            alert(err.message);
        }
    }

    handleRestartModuleClick(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.restartModule();
    }

    public render() {
        return <div className="lessonCardPresenter">
            <div className={`lesson-card atas-card ${this.getLessonStatus()}`}>

                <div className="lesson-card-img">
                    <img src={get(this.state, 'lesson.cover.images.original')} alt=""/>
                    {(get(this.state, 'lesson.status') === 1) && <span className="flag">Ready to start</span>}
                    {(get(this.state, 'lesson.status') === 3) && <span className="flag">complete</span>}
                    {(get(this.state, 'lesson.status') === 2) && <span className="flag">in progress</span>}
                </div>
                <div className="lesson-card-text">
                    <h4 className="lesson-card-title">{get(this.state, 'lesson.title')}</h4>
                    <p>{TRUNCATE_TEXT(get(this.state, 'lesson.description'), 164, '...') || ''}</p>
                    {this.props.learnMore && <span onClick={() => this.props.learnMore()} className="more">Learn more<i
                        className="fa fa-chevron-right"/></span>}
                </div>
                <div className="lesson-card-footer">
                    {this.props.isExternalLink &&
                    <div>
                        {(get(this.state, 'lesson.status') === 1) &&
                        <a target={this.props.blank ? '_blank' : '_self'} href={this.props.linkTo || ''}
                           className="btn-basic">Start Lesson</a>}
                        {(get(this.state, 'lesson.status') === 2) &&
                        <a target={this.props.blank ? '_blank' : '_self'} href={this.props.linkTo || ''}
                           className="btn-basic">Resume Lesson</a>}
                        {(get(this.state, 'lesson.status') === 3) &&
                        <a target={this.props.blank ? '_blank' : '_self'}
                           onClick={(event: any) => this.handleRestartModuleClick(event)}
                           className="btn-basic complete text-white">Restart Lesson</a>}
                    </div>

                    }
                    {!this.props.isExternalLink &&
                    <div>
                        {(get(this.state, 'lesson.status') === 1) &&
                        <Link target={this.props.blank ? '_blank' : '_self'} to={this.props.linkTo || ''}
                              className="btn-basic">Start Lesson</Link>}
                        {(get(this.state, 'lesson.status') === 2) &&
                        <Link target={this.props.blank ? '_blank' : '_self'} to={this.props.linkTo || ''}
                              className="btn-basic">Resume Lesson</Link>}
                        {(get(this.state, 'lesson.status') === 3) &&
                        <a target={this.props.blank ? '_blank' : '_self'}
                           onClick={(event: any) => this.handleRestartModuleClick(event)}
                           className="btn-basic complete text-white">Restart Lesson</a>}
                    </div>
                    }
                </div>
            </div>
        </div>;
    }
}
