import { get, isEqual } from 'lodash';
import * as React from 'react';
import { TRUNCATE_TEXT } from '../../../libs/truncate';
import './lesson-setting-card.presenter.scss';

export class LessonSettingCardPresenter extends React.Component<any, any> {

    public state: any = {
        initialized: false,
        companyId: this.props.companyId,
        lesson: {}
    };

    componentDidMount() {
        this.setState({
            initialized: true,
            lesson: get(this.props, 'lesson')
        });
    }

    componentDidUpdate(prevState: any) {
        if (!isEqual(prevState.lesson, this.props.lesson)) {
            this.setState({
                lesson: get(this.props, 'lesson')
            });
        }
    }

    public render() {
        return <div className="lessonCardPresenter">
            <div className={`lesson-card atas-card ${get(this.state, 'lesson.status')}`}>
                <div className="lesson-card-img">
                    <img src={get(this.state, 'lesson.cover.images.original', '')} alt=""/>
                </div>
                <div className="lesson-card-text">
                    <h4 className="lesson-card-title">{get(this.state, 'lesson.title')}</h4>
                    <p>{TRUNCATE_TEXT(get(this.state, 'lesson.description'), 164, '...') || ''}</p>
                </div>
                <div className="lesson-card-footer">
                    <a href={`${window.location}/${this.state.lesson.companyId}/lesson/${this.state.lesson.id}`} className="btn-atas"><i className="far fa-edit"/><span>Edit lesson</span></a>
                </div>
                <button className="trash-btn" onClick={() => this.props.onDelete(this.state.lesson['@id'])}>
                    <i className="far fa-trash-alt"/>
                </button>
            </div>
        </div>;
    }
}
