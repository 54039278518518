import * as jQuery from 'jquery';
import { compact, isEqual, sum } from 'lodash';
import * as React from 'react';
import './tab-navigation.scss';

export class TabNavigationComponent extends React.Component<any, any> {
    private itemRefs: HTMLDivElement[] = [];
    private indicatorRef: HTMLDivElement;
    private widths: number[] = [];

    public state: any = {
        activeTab: this.props.active || 0,
        items: compact(this.props.items)
    };

    componentDidMount() {
        this.setIndicatorPosition();
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(compact(prevProps.items), compact(this.props.items))) {
            this.itemRefs = [];
            this.widths = [];

            this.setState({
                items: compact(this.props.items)
            });
        }

        if (prevProps.active !== this.props.active) {
            this.setState({
                activeTab: this.props.active
            });
        }

        this.setIndicatorPosition();
    }

    async handleRefArrived(ref: any, index: number) {
        this.itemRefs[index] = ref;
        this.calcFullWidth(index);
    }

    calcFullWidth(index: number) {
        this.widths = (this.itemRefs || []).map((ref: HTMLDivElement) => {
            return (ref || {}).offsetWidth || 0;
        });
    }

    setIndicatorPosition() {
        const halfOfIndicator = this.indicatorRef.offsetWidth / 2;
        const currentTabLeftHalf = (this.widths[this.state.activeTab] / 2);
        let otherTabsWidths = 0;

        if (this.state.activeTab > 0) {
            otherTabsWidths = sum(this.widths.splice(0, this.state.activeTab));
        }

        const left = otherTabsWidths + currentTabLeftHalf - halfOfIndicator;

        if (left >= 0) {
            jQuery(this.indicatorRef).css('left', left);
        }

    }

    render() {
        return <div className="tabs-wrap">
            <ul className="tabs">
                {(this.state.items || []).map((item: any, index: number) => {
                    return <div
                        key={index}
                        ref={(ref: any) => this.handleRefArrived(ref, index)}
                        onClick={() => (this.props.onActiveChange ? this.props.onActiveChange(index) : (() => null))}
                        className={`${(index === this.state.activeTab) ? 'active' : ''}`}>{item}</div>;
                })}
                <span className="indicator-bar" ref={(domElement: any) => (this.indicatorRef = domElement)}/>
            </ul>
        </div>;
    }

}

export class TabNavigationItemComponent extends React.Component<any, any> {
    public state: any = {
        title: this.props.title || ''
    };

    componentDidUpdate(prevProps: any) {
        if (prevProps.title !== this.props.title) {
            this.setState({
                title: this.props.title
            });
        }
    }

    render() {
        return <li onClick={(event: any) => this.props.onClick ? this.props.onClick(event) : null}>{this.state.title}</li>;
    }
}
