import {get} from 'lodash';
import * as React from 'react';
import {TaskTypes} from '../../../task.types';
import './task-type-card.scss';

const config: any = TaskTypes.taskTypesConfig();

export class TaskTypeCard extends React.Component<any, any> {

    public state: any = {
        hidden: true
    };

    handleTooltip(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.hidden === true) {
            this.setState({
                hidden: false
            });
        }
        if (this.state.hidden === false) {
            this.setState({
                hidden: true
            });
        }
    }

    closeTooltip(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({hidden: true});
    }

    public render() {
        return <div className={`taskTypeCard atas-card ${(this.props.selected) ? 'selected' : ''}`}>
            <div className="ttc-img">
                <img src={get(config, `[${get(this.props, 'type')}].icon`, '')} alt=""/>
            </div>
            <span className="card-title">{get(config, `[${get(this.props, 'type')}].name`, '')}</span>
            <div className="ttc-card-info">
                <button onClick={(e) => this.handleTooltip(e)}><i className="fas fa-question"></i></button>
                <div className={`custom-tooltip ${this.state.hidden ? 'd-none' : ''}`}>
                    <i onClick={(e) => this.closeTooltip(e)} className="closeTooltip fas fa-times"></i>
                    <div className="tooltip-content">
                        <span>{get(config, `[${get(this.props, 'type')}].info`, '')}</span>
                    </div>
                </div>
            </div>
        </div>;
    }

}
