import { get } from 'lodash';
import * as React from 'react';
import { FeedbackToast } from '../../libs/elements/feedback-toast/feedback-toast';
import { Loader } from '../../libs/elements/loader/loader';
import { Repository } from '../../libs/repository/repository';
import { LessonPresenter } from '../e-modules/lesson/lesson.presenter';

export class LessonDetailsComponent extends React.Component<any, any> {
    private toastRef: any;
    public state: any = {
        initialized: false,
        isLoading: false,
        company: {},
        companyIri: this.getCompanyIri(),
        companyId: this.getCompanyId()
    };

    async componentDidMount() {
        try {
            this.setState({initialized: false, isLoading: true});
            await this.updateCompany();
            this.setState({initialized: true, isLoading: false});
        } catch (err) {
            this.setState({initialized: true, isLoading: false});

            console.error('Failed to lesson-details.componentDidMount()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
        }

    }

    componentDidUpdate(prevProps: any) {
        if (get(this.props, 'match.params.companySiteSpecId') !== get(prevProps, 'match.params.companySiteSpecId')) {
            this.setState({
                companyIri: this.getCompanyIri()
            }, () => {
                this.updateCompany();
            });
        }
    }

    public async updateCompany() {
        try {
            const response: any = await Repository.get(this.state.companyIri);
            this.setState({
                company: response
            });
        } catch (err) {
            console.error('Failed to lesson-details.updateCompany()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    public getLessonSlug(): string {
        return get(this.props, 'match.params.lessonId', '');
    }

    public render() {
        return <div>
            {this.state.initialized && <LessonPresenter company={get(this.state, 'company')} iri={`/lesson/${this.getLessonSlug()}`}/>}
            <Loader isLoading={this.state.isLoading}/>
            <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
        </div>;
    }

    public getCompanyIri(): string {
        return `/company/${get(this.props, 'match.params.companySiteSpecId', null)}`;
    }

    public getCompanyId(): string {
        return get(this.props, 'match.params.companySiteSpecId', null);
    }
}
