import { environment } from './environment';

export class Storage {
    private provider: any = window.localStorage;
    private prefix: string = `JMG[EL-FRONTEND][${environment.appRandomId}]`;

    constructor() {
        if (!this.provider) {
            alert('Your browser does not support LocalStorage API!');
        }
    }

    public getItem(key: string): any {
        try {
            return JSON.parse(this.provider.getItem(`${this.prefix}-${key}`));
        } catch (e) {
            console.error('Failed to storage.getItem(key)', key);
            console.error(e);
            return null;
        }
    }

    public setItem(key: string, value: any): void {
        try {
            this.provider.setItem(`${this.prefix}-${key}`, JSON.stringify(value));
        } catch (e) {
            console.error('Failed to [storage].setItem(key, value)', key, value);
            console.error(e);
        }
    }

    public removeItem(key: string): void {
        try {
            return this.provider.removeItem(`${this.prefix}-${key}`);
        } catch (e) {
            console.error('Failed to [storage].removeItem(key)', key);
            console.error(e);
        }
    }
}
