import { environment } from '../../../libs/environment';
import { MediaItem } from '../media-item';
import { Subtitle } from '../subtitle';

/**
 * example.mp3 duration -> 634 seconds
 */

export class AudioConfig {
    public id: string;
    public type: string;
    public '@id': string;
    public media: MediaItem;
    public isEditorMode: boolean = false;
    public subtitles: Subtitle[] = [];

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.type = 'audio';

        if (environment.mock) {
            this.isEditorMode = !!obj.isEditorMode;
            this.media = new MediaItem({
                source: '/example.mp3'
            });
            this.subtitles = obj.subtitles || [
                new Subtitle({start: 10000, end: 18000}),
                new Subtitle({start: 20000, end: 28000}),
                new Subtitle({start: 30000, end: 38000})
            ];
        } else {
            this.isEditorMode = !!obj.isEditorMode;
            this.media = new MediaItem(obj.media || {});
            this.subtitles = (obj.subtitles || []).map((subtitle: any) => new Subtitle(subtitle));
        }
    }
}
