import * as faker from 'faker';
import { environment } from '../../../libs/environment';

export class JournalConfig {
    public id: number;
    public '@id': string;
    public question: string;

    constructor(obj: any = {}) {
        if (environment.mock) {
            this.question = obj.question || faker.lorem.sentence(6);
        } else {
            this.question = obj.question || '';
            this.id = obj.id;
            this['@id'] = obj['@id'];
        }
    }
}
