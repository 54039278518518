import {get} from 'lodash';
import * as React from 'react';
import {EmptyList} from '../../../libs/elements/empty-list/empty-list';
import {FeedbackToast} from '../../../libs/elements/feedback-toast/feedback-toast';
import {Loader} from '../../../libs/elements/loader/loader';
import {Repository} from '../../../libs/repository/repository';
import {LessonSettingCardPresenter} from '../lesson-setting-card/lesson-setting-card.presenter';
import './company-lessons.scss';
import {Helper} from '../../../libs/helper';
import {Pagination} from '../../../libs/elements/pagination/pagination';

const Sortable = require('./../../../../node_modules/sortablejs/Sortable.js');

export class CompanyLessonsPresenter extends React.Component<any, any> {

    private toastRef: any;
    private lessonListRef: any = null;
    private lessonOrderList: any;

    public state: any = {
        lessons: [],
        page: 1,
        limit: 99,
        totalItems: 0,
        open: true,
        isLoading: false,
        isError: false,
        reordered: false
    };

    // @ts-ignore
    private async fetchLesson(page: number, limit: number) {
        try {
            this.setState({isLoading: true});
            const response = await Repository.get('/lesson', {
                companyId: this.props.companyId,
                limit: 99
            });
            if (!response.member.length && page > 1) {
                this.fetchLesson(page - 1, this.state.limit);
            }
            // @ts-ignore
            response.member = await Promise.all(response.member.map(async (lesson: any) => {
                if (lesson.cover) {
                    lesson.cover = await Repository.get(lesson.cover);
                }
                return lesson;
            }));
            this.lessonOrderList = response.member;
            this.setState({
                lessons: response.member,
                totalItems: response.totalItems,
                isLoading: false
            });
        } catch (err) {
            this.setState({
                isLoading: false
            });
            if (this.props.onError) {
                this.props.onError(err);
            }
            console.error('Failed to company-lessons.fetchLesson(page, limit)', page, limit);
            console.error(err);
            this.toastRef.showToast(err.toString(), 'error');

        }
    }

    // @ts-ignore
    private async updateLessonOrder(prevId: number, newId: number) {
        this.setState({reordered: true});
        const lessons = Helper.insertAt(prevId, newId, this.lessonOrderList);
        this.lessonOrderList = lessons;
        console.log(this.lessonOrderList);
        /*this.state.lessons = lessons;
        this.setState({
            lessons: this.state.lessons
        });*/
        try {
            await Repository.put(get(lessons, `[${newId}].@id`), {position: newId + 1});
        } catch (err) {
            console.error('Failed to [company-lessons].updateLessonOrder(prevId, newId)', prevId, newId);
            console.error(err);
            this.setState({isLoading: false});
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    async componentDidMount() {
        await this.fetchLesson(this.state.page, this.state.limit);
        await this.initializeSortable();

    }

    public async createLesson() {
        try {
            const newLesson = await Repository.post('/lesson', {
                title: 'New lesson',
                description: 'Lesson Description',
                status: 0,
                companyId: this.props.companyId,
                preset: 'practice'
            });
            this.toastRef.showToast('Lesson successfully created!', 'success');
            return newLesson;

        } catch (err) {
            console.error('Failed to [company-lessons].createLesson()');
            console.error(err);
            this.toastRef.showToast(err.toString(), 'error');
        }
    }

    // @ts-ignore
    public async handleOnLessonDelete(lessonIri: string) {
        try {
            await Repository.delete(lessonIri);
            this.fetchLesson(this.state.page, this.state.limit);
        } catch (err) {
            console.error('Failed to [company-lessons].handleOnLessonDelete(lessonIri)', lessonIri);
            console.error(err);
            this.toastRef.showToast(err.toString(), 'error');
        }
    }

    /* SORTABLE */

    initializeSortable() {
        if (this.lessonListRef) {
            new Sortable(this.lessonListRef, {
                onEnd: (event: any) => {
                    return this.updateLessonOrder(event.oldIndex, event.newIndex);
                }
            });
        }
    }

    // @ts-ignore
    async forceSaveLessonPositions(lessons?: any) {
        console.log(this.lessonOrderList);
        this.setState({isLoading: true});
        if (lessons) {
            // @ts-ignore
            await Promise.all(lessons.map(async (item: any, index: number) => {
                return Repository.put(get(item, '@id'), {position: index + 1});
            }));
        } else {
            // @ts-ignore
            await Promise.all(this.state.lessons.map(async (item: any, index: number) => {
                return Repository.put(get(item, '@id'), {position: index + 1});
            }));
        }
        this.setState({isLoading: false});
    }

    /* EDN SORTABLE */

    // @ts-ignore
    public async handleNewLessonCreate() {
        this.setState({isLoading: true});
        const createdLesson = await this.createLesson();
        (window as any)._history.push(`/mng/${this.props.companyId}/lesson/${get(createdLesson, 'id')}`);
    }

    render() {
        return (<div className="relative companyLessonsPresenter">
            <div className="modules-open">
                <h2>Lessons</h2>
            </div>
            <button className="btn-atas create-lesson-btn" onClick={() => this.handleNewLessonCreate()}><i
                className="far fa-plus"/>Create new lesson
            </button>
            {this.state.reordered &&
            <button className="btn-atas create-lesson-btn"
                    onClick={() => this.forceSaveLessonPositions(this.lessonOrderList)}>Update Lesson
                order
            </button>}
            <div>
                <div className="row" ref={(ref: any) => this.lessonListRef = ref}>
                    {!!this.state.lessons.length && this.state.lessons.map((lesson: any, index: number) => {
                        return <div className="col-sm-6 col-lg-4 cardcol" key={index}>
                            <LessonSettingCardPresenter key={lesson.position}
                                                        onDelete={(lessonIri: any) => this.handleOnLessonDelete(lessonIri)}
                                                        lesson={lesson}/>
                        </div>;
                    })}

                    {!this.state.lessons.length && <EmptyList elText="No lesson found!"/>}

                </div>
                <Pagination
                    totalItems={this.state.totalItems}
                    limit={this.state.limit}
                    currentPage={this.state.page}
                    onPageChange={(page: number) => this.fetchLesson(page, this.state.limit)}
                />
            </div>
            <Loader isLoading={this.state.isLoading}/>
            <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
        </div>);
    }
}
