import * as React from 'react';
import { Repository } from '../../../../libs/repository/repository';
import './rating.scss';

export class RatingComponent extends React.Component<any, any> {
    private ratingPoints = [0, 1, 2, 3, 4];
    public state: any = {
        rating: -1
    };

    private handleStarClick(point: number) {
        this.setState({
            rating: point
        });
    }

    public async submitRating(rating: number) {
        try {
            await Repository.post('/lesson-rating', {lesson: this.props.lessonIri, userID: (this.props.userId * 1), rating});
        } catch (err) {
            console.log(err);
        }
    }

    public render() {
        return <div className="rating">
            <h3>Rate this module</h3>
            <p>How useful did you find this lesson? Rate below.</p>
            <div className="stars">
                {this.ratingPoints.map((point: number, index: number) => {
                    return <div key={index} className={`fas fa-star star ${(point <= this.state.rating) ? 'marked' : ''}`} onClick={() => this.handleStarClick(point)}/>;
                })}
            </div>
            <button onClick={() => this.submitRating(this.state.rating + 1)} className="btn-atas">Submit Rating</button>
        </div>;
    }
}
