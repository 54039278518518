import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { find, get } from 'lodash';
import * as React from 'react';
import { AtasCheckBox } from '../../../libs/elements/checkbox/atas-checkbox';
import { FeedbackBubble } from '../../../libs/elements/feedback-bubble/feedback-bubble';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import { Repository } from '../../../libs/repository/repository';
import { Choice } from '../../../mock/entities/choice';
import { TaskPresenterPropsInterface } from '../task-presenter/task-presenter-props.interface';
import './multiple-choice-question.presenter.scss';

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

export class MultipleChoiceQuestionPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    public state: any = {
        initialized: false,
        error: false,
        choices: get(this.props, 'config.config.choices', []),
        validated: false,
        validationResponse: null,
        previousValidations: get(this.props, 'config.previousValidations', null)
    };
    private showValidations = get(this.props.config, 'lessonPreset') !== 'exam';

    componentDidMount() {

        if (get(this.props, 'config.config.question')) {
            this.setState({
                initialized: true,
                error: false
            }, () => {
                this.emitHasChoice();
                this.showPreviousValidation();
            });
        } else {
            this.setState({
                error: true
            });
        }
    }

    handleCheckboxChange(index: number, event: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.props.config.config.multi) {
                this.state.choices = (this.state.choices || []).map((choice: Choice, i: number) => {
                    if (i === index) {
                        choice.isChecked = event;
                    } else {
                        choice.isChecked = false;
                    }

                    return choice;
                });
            } else {
                this.state.choices[index].isChecked = event;
                this.state.validated = false;
            }

            setTimeout(() => {
                this.state.validated = false;

                this.setState(this.state);

                if (this.props.onState) {
                    this.props.onState();
                }

                this.emitHasChoice();
                resolve();
            });
        });
    }

    emitHasChoice() {
        const hasChoice = (this.state.choices || []).filter((i: any) => i.isChecked);

        this.props.config.taskState.set('submitable', !!hasChoice.length);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    public async validateTask(useTaskId = false): Promise<any> {
        try {
            const correctAnswers = this.state.choices.filter((i: any) => i.isChecked).map((i: any) => i.id);
            let validationResponse;

            if (useTaskId) {
                validationResponse = await Repository.post('/log/validate', {
                    taskId: this.props.config.id,
                    answer: this.props.config.config.multi ? correctAnswers : correctAnswers[0]
                });
            } else {
                validationResponse = await Repository.post('/log/validate', {
                    lessonTaskId: this.props.lessonTaskId,
                    answer: this.props.config.config.multi ? correctAnswers : correctAnswers[0]
                });
            }

            this.state.validated = this.showValidations;
            this.setState(this.state);

            if (this.props.onState) {
                this.props.onState();
            }

            this.setState({validationResponse});
            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public async showPreviousValidation() {
        const answer = get(this.state.previousValidations, 'answer');

        const finishCB = () => {
            this.setState({
                validated: this.showValidations,
                validationResponse: this.state.previousValidations
            });
        };

        if (answer) {
            if (!this.props.config.config.multi) {
                await Promise.all((this.state.choices).map(async (item: Choice, index: number) => {
                    if (item.id === answer[index]) {
                        return this.handleCheckboxChange(index, true);
                    }
                }));

                finishCB();
            } else {
                await Promise.all((this.state.choices).map(async (item: Choice, index: number) => {
                    if (answer.indexOf(item.id) > -1) {
                        return this.handleCheckboxChange(index, true);
                    }
                }));
                finishCB();
            }
        }
    }

    public showValidation(choice: any): boolean {
        if (this.state.validationResponse) {
            const correctResult = find(this.state.validationResponse.correct, (c) => c.id === choice.id);
            return ((!!correctResult && this.props.config.config.multi) || choice.isChecked);
        }
        return false;
    }

    public isCorrect(choice: any): boolean {
        if (this.state.validationResponse) {
            const correctResult = find(this.state.validationResponse.correct, (c) => c.id === choice.id);
            return (correctResult);
        }
        return false;
    }

    public textCorrect(choice): string {
        if (this.isCorrect(choice)) {
            if (choice.isChecked) {
                return 'That\'s correct!';
            } else {
                return 'This was the correct answer.';
            }
        } else {
            return '';
        }

    }

    public render() {
        return <div className="multipleChoiceQuestionPresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}
            {this.state.initialized && <div className="w-100">
                <h3 className="title lead-text">{get(this.props, 'config.config.question', '')}?</h3>
                <FormGroup className="w-100">
                    {this.state.choices.map((choice: Choice, index: number) => {
                        return <div key={index}>
                            <FormControlLabel
                                key={index}
                                control={<AtasCheckBox
                                    onChange={(event: any) => this.handleCheckboxChange(index, event.target.checked)}
                                    checked={choice.isChecked}
                                />}
                                label={`${alphabet[index]}) ${choice.choice}`}
                            />
                            {(this.state.validated && this.showValidation(choice)) &&
                            <FeedbackBubble textCorrect={this.textCorrect(choice)} success={this.isCorrect(choice)}/>}
                        </div>;
                    })}
                </FormGroup>
            </div>}
        </div>;
    }
}
