import { get, isEqual } from 'lodash';
import * as React from 'react';
import { TaskTypes } from '../../../task.types';
import { AudioPresenter } from '../audio/audio.presenter';
import { CompleteSentencePresenter } from '../complete-sentence/complete-sentence.presenter';
import { DragAndDropPresenter } from '../drag-and-drop/drag-and-drop.presenter';
import { InformativeSectionPresenter } from '../informative/informative-section.presenter';
import { InteractiveSlideshowPresenter } from '../interactive-slideshow/interactive-slideshow.presenter';
import { JournalPresenter } from '../journal/journal.presenter';
import { MultipleChoiceQuestionPresenter } from '../multiple-choice-question/multiple-choice-question.presenter';
import { MultipleTaskPresenter } from '../multiple-task/multiple-task.presenter';
import { QuestionAndAnswerPresenter } from '../question-and-answer/question-and-answer.presenter';
import { UnknownTasktype } from '../unknown-tasktype/unknown-tasktype';
import { VideoPresenter } from '../video/video.presenter';
import { TaskPresenterPropsInterface } from './task-presenter-props.interface';

export interface CustomTaskComponentInterface extends React.Component<TaskPresenterPropsInterface, any> {
    validateTask(useTaskId?): Promise<any>;
}

export class TaskPresenter extends React.Component<any, any> {
    private customTaskConfigRef: CustomTaskComponentInterface;
    public state: any = {
        lessonTask: get(this.props, 'lessonTask'),
        type: get(this.props, 'task.type'),
        config: get(this.props, 'task'),
        updateTrigger: true
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.lessonTask !== prevProps.lessonTask) {
            this.setState({
                lessonTask: this.props.lessonTask
            });
        }

        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                updateTrigger: false,
                type: get(this.props, 'task.type'),
                config: get(this.props, 'task')
            }, () => {
                this.setState({
                    updateTrigger: true
                });
            });
        }
    }

    handleTaskState() {
        if (this.props.onState) {
            this.props.onState(this.state.config);
        }
    }

    async validate(useTaskId = false): Promise<boolean> {
        if (!this.customTaskConfigRef) {
            return Promise.resolve(false);
        }
        return this.customTaskConfigRef.validateTask(useTaskId);
    }

    renderTask() {
        if (!this.state.updateTrigger) {
            return <div/>;
        }

        if (this.state.type === TaskTypes.AUDIO) {
            return <AudioPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.INFORMATIVE_SECTION) {
            return <InformativeSectionPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.VIDEO) {
            return <VideoPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.DRAG_AND_DROP) {
            return <DragAndDropPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.JOURNAL) {
            return <JournalPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.QUESTION_AND_ANSWER) {
            return <QuestionAndAnswerPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.CHOICE) {
            return <MultipleChoiceQuestionPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.COMPLETE_SENTENCE) {
            return <CompleteSentencePresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.QUIZ) {
            return <MultipleTaskPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else if (this.state.type === TaskTypes.SLIDE) {
            return <InteractiveSlideshowPresenter
                ref={(ref: any) => (this.customTaskConfigRef = ref)}
                config={this.state.config}
                lessonTaskId={get(this.state, 'lessonTask.id')}
                onState={() => this.handleTaskState()}/>;
        } else {
            return <h3><UnknownTasktype/></h3>;
        }
    }

    public render() {
        return <div className="w-100 relative">
            {/*this.props.disabled && <div className="task-disabler"></div>*/}
            {this.renderTask()}
        </div>;
    }
}
