import {Map} from 'immutable';
import {TaskTypes} from '../../task.types';
import {Breakpoint} from './breakpoint';
import {Subtitle} from './subtitle';
import {AudioConfig} from './task-configs/audio.config';
import {CompleteSentenceConfig} from './task-configs/complete-sentence.config';
import {DragAndDropConfig} from './task-configs/drag-and-drop.config';
import {JournalConfig} from './task-configs/journal.config';
import {MultipleChoiceQuestionConfig} from './task-configs/multiple-choice-question.config';
import {QuizConfig} from './task-configs/quiz.config';
import {SlideshowConfig} from './task-configs/slideshow.config';
import {VideoConfig} from './task-configs/video.config';

export class Task {
    public id: number;
    public lessonPreset: string;
    public '@id': string;
    public type: string = '';
    public title: string = '';
    public description: string = '';
    public lead: string = '';
    public maxDuration: number = 0;
    public choiceType: any;
    public subtitles: Subtitle[];
    public breakpoints: Breakpoint[];
    public mediaType: any;
    public lessonTaskCount: number;
    public hidden: boolean;
    public config: any = null;
    public taskState = Map({}).asMutable();
    public previousValidations: any = null;

    constructor(obj: any = {}) {
        this.id = obj.id;
        this['@id'] = obj['@id'];
        this.type = obj.type;
        this.maxDuration = obj.maxDuration;
        this.lessonPreset = obj.lessonPreset;
        this.title = obj.title || '';
        this.subtitles = obj.subtitles || [];
        this.breakpoints = obj.breakpoints || [];
        this.description = obj.description || '';
        this.lessonTaskCount = obj.lessonTaskCount || 0;
        this.hidden = obj.hidden || false;
        this.lead = obj.lead || '';
        this.previousValidations = obj.previousValidations;

        if (this.type === TaskTypes.AUDIO) {
            this.config = new AudioConfig(obj.config || {});
        }
        if (this.type === TaskTypes.INFORMATIVE_SECTION) {
            this.config = obj.config;
        }
        if (this.type === TaskTypes.VIDEO) {
            this.config = new VideoConfig(obj.config || {});
        }
        if (this.type === TaskTypes.DRAG_AND_DROP) {
            this.config = new DragAndDropConfig(obj.config || {});
        }
        if (this.type === TaskTypes.JOURNAL) {
            this.config = new JournalConfig(obj.config || {});
        }
        if (this.type === TaskTypes.QUESTION_AND_ANSWER) {
            this.config = new JournalConfig(obj.config || {});
        }
        if (this.type === TaskTypes.CHOICE) {
            this.config = new MultipleChoiceQuestionConfig(obj.config || {});
        }
        if (this.type === TaskTypes.COMPLETE_SENTENCE) {
            this.config = new CompleteSentenceConfig(obj.config || {});
        }
        if (this.type === TaskTypes.QUIZ) {
            this.config = new QuizConfig(obj.config || {});
        }
        if (this.type === TaskTypes.SLIDE) {
            this.config = new SlideshowConfig(obj.config || {});
        }
    }
}
