import * as React from 'react';
// import * as magicShit from 'react-append-to-body';
import './modal.component.scss';

export class ModalComponent extends React.Component<any, any> {
    private openInfo: any;
    public container: any;
    public content: any;

    public state: any = {
        isOpened: false,
        isChildOpened: false
    };

    open(info?: any) {
        this.openInfo = info;

        this.setState({
            isOpened: true,
            isChildOpened: true
        });

        document.body.classList.add('modal-opened');
    }

    close() {
        document.body.classList.remove('modal-opened');

        this.setState({
            isOpened: false
        }, () => {
            setTimeout(() => {
                this.setState({isChildOpened: false});
            }, 500);
        });
    }

    componentWillUnmount() {
        this.setState({
            isOpened: false,
            isChildOpened: false
        });
        this.close();

    }

    onSuccess() {
        if (this.props.onSuccess) {
            this.props.onSuccess(this.openInfo);
        }

        this.close();
    }

    onClose() {
        if (this.props.onClose) {
            this.props.onClose(this.openInfo);
        }
        this.close();
    }

    render() {
        return <div className={`customModalComponent ${this.state.isOpened ? 'opened' : ''}`}>
            <div className="custom-modal-wrapper container">

                <div className={`custom-modal-body ${this.props.mini ? 'mini' : ''}`}>
                    {this.state.isChildOpened ? this.props.children : ''}

                    {!this.props.buttons && <div className="custom-modal-footer">
                        {this.props.onSuccess && <button className="btn-atas" onClick={() => this.onSuccess()}>done</button>}
                        <button className="btn btn-atas-inverse" onClick={() => this.close()}>close</button>
                    </div>}
                    {!!this.props.buttons && <div className="custom-modal-footer">
                        {this.props.buttons}
                    </div>}
                </div>

            </div>
            <button className="close-modal-button" onClick={() => this.close()}>
                <i className="far fa-times"/>
            </button>
        </div>;
    }
}
