import {Map} from 'immutable';
import {get, isEqual} from 'lodash';
import * as React from 'react';
import {MultiUploadComponent} from '../../../libs/elements/multi-upload/multi-upload.component';
import {RangeSliderComponent} from '../../../libs/elements/range-slider.component';
import {AtasTextfield} from '../../../libs/elements/textfield/atas-textfield';
import {Repository} from '../../../libs/repository/repository';
import {Subtitle} from '../../../mock/entities/subtitle';
import {TaskPresenter} from '../../e-modules/task-presenter/task.presenter';
import './task-setup.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class AudioTypeSetup extends React.Component<any, any> {
    public state: any = {
        time: 0,
        duration: 0,
        task: this.props.task
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    public async mediaTypeChange(mt: any) {
        try {
            this.state.task.config.media = await Repository.get(get(mt, 'media.@id'));
            this.setState({
                task: this.state.task
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    handleTaskStateChahge(state: Map<string, any>) {
        this.setState({
            time: state.get('time'),
            duration: state.get('duration')
        });
    }

    handleSubtitleChanges(s: Subtitle[]) {
        this.state.task.subtitles = s;
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Preview</span>
                        <MultiUploadComponent
                            multi={false}
                            enableAudio={true}
                            title="Add Audio"
                            onSuccess={(mts: any[]) => this.mediaTypeChange(mts[0])}
                            createMediaType={false}/>
                    </div>
                    <div className="card-body">

                        <TaskPresenter
                            task={this.state.task}
                            onState={() => this.handleTaskStateChahge(this.state.task.taskState)}/>

                    </div>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Settings</span>

                    </div>
                    <div className="card-body">
                        {this.state.duration && <RangeSliderComponent
                            disableBreakpoints={true}
                            onSubtitles={(s: any) => this.handleSubtitleChanges(s)}
                            subtitles={this.state.task.subtitles}
                            time={this.state.time * 1000}
                            allTime={this.state.duration * 1000}/>}
                    </div>
                </div>

            </div>
        </div>;
    }
}
