import {find, get, isEqual, random, cloneDeep} from 'lodash';
import * as Plyr from 'plyr';
import 'plyr/src/sass/plyr.scss';
import * as React from 'react';
import {ModalComponent} from '../../../libs/elements/modal/modal.component';
import {Placeholder} from '../../../libs/elements/placeholder/placeholder';
import {SubtitlePresenter} from '../../../libs/elements/subtitle/subtitle.presenter';
import {TaskError} from '../../../libs/elements/task-error/task-error';
import {Repository} from '../../../libs/repository/repository';
import {Breakpoint} from '../../../mock/entities/breakpoint';
import {Task} from '../../../mock/entities/task';
import {TaskPresenter} from '../task-presenter/task.presenter';
import {BreakpointPresenter} from './breakpoint/breakpoint.presenter';
import './video.presenter.scss';

export class VideoPresenter extends React.Component<any, any> {
    private readonly canvasDomId: string = `videotask-Canvas-${random(1000, 9999)}`;
    private taskModalComponent: ModalComponent;
    private plyrInstance: any;
    private playBlocked: boolean = false;
    private blockerBreakpoints: Breakpoint[] = [];
    private requiredBreakpoints: Breakpoint[] = [];
    private requiredTasks: Breakpoint[] = [];
    private currentTask: Task;
    private videoDuration: 0;

    public state: any = {
        initialized: false,
        error: false,
        noVideo: false,
        time: 0
    };

    public options: any = {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
        fullscreen: {
            enabled: false,
            fallback: false,
            iosNative: false
        }
    };

    emitSubmitable(val: boolean) {
        this.props.config.taskState.set('submitable', val);

        if (this.props.onState) {
            this.props.onState();
        }
    }

    componentDidMount() {
        this.emitSubmitable(true);
        this.initializeVideo();
    }

    componentDidUpdate(prevProps: any) {
        if (!isEqual(prevProps.config, this.props.config)) {
            this.initializeVideo();
        }
    }

    public initializeVideo() {

        const src = get(this.props, 'config.config.videoUrl', null);
        if (src) {
            this.plyrInstance = new Plyr(`#${this.canvasDomId}`, this.options);

            this.plyrInstance.source = {
                type: 'video',
                sources: [
                    {
                        src,
                        provider: get(this.props, 'config.config.provider')
                    }
                ]
            };
            this.plyrInstance.on('ready', (event: any) => {
                this.props.config.taskState.set('duration', this.plyrInstance.duration);
                const updateInterval = setInterval(() => {
                    if (this.videoDuration !== this.plyrInstance.duration) {
                        this.videoDuration = this.plyrInstance.duration;
                        this.props.onDurationChanged(this.videoDuration);
                        clearInterval(updateInterval);
                    }
                }, 100);

                this.setState({
                    initialized: true,
                    error: false
                });

                if (this.props.onState) {
                    this.props.onState();
                }
            });

            this.plyrInstance.on('seeked', (event: any) => {
                this.handleSeeking(this.getPlyrTime());
                this.props.config.taskState.set('time', this.getPlyrTime());
                this.props.config.taskState.set('duration', this.plyrInstance.duration);
                if (this.props.onState) {
                    this.props.onState();
                }
            });

            this.plyrInstance.on('enterfullscreen', () => {
                this.plyrInstance.fullscreen.exit();
            });

            this.plyrInstance.on('playing', () => {
                if (this.playBlocked) {
                    this.plyrInstance.pause();
                }
            });
            this.plyrInstance.on('timeupdate', (event: any) => {
                this.props.config.taskState.set('duration', this.plyrInstance.duration);
                if (this.videoDuration !== this.plyrInstance.duration) {
                    this.videoDuration = this.plyrInstance.duration;
                    this.props.onDurationChanged(this.videoDuration);
                }
                this.setState({
                    time: this.getPlyrTime()
                });
                this.props.config.taskState.set('time', this.getPlyrTime());
                this.props.config.taskState.set('duration', get(event, 'detail.plyr.duration', 0));
                if (this.props.onState) {
                    this.props.onState();
                }
            });

            this.requiredBreakpoints = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => (bp.required && !bp.relatedTask));
            this.requiredTasks = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => (bp.relatedTask));
            this.blockerBreakpoints = get(this.props, 'config.breakpoints', []).filter((bp: Breakpoint) => bp.blocker);
        } else {
            this.setState({
                error: true,
                noVideo: true
            });
        }
    }

    public async validateTask(): Promise<any> {
        const validationResponse = await Repository.post('/log/validate', {
            lessonTaskId: this.props.lessonTaskId,
            answer: null
        });
        return Promise.resolve(validationResponse);
    }

    disablePlayButton() {
        this.playBlocked = true;
    }

    enablePlayButton() {
        this.playBlocked = false;
    }

    getPlyrTime(): number {
        return (get(this.plyrInstance, 'currentTime', 0) * 1000);
    }

    onBreakpointBlock(bp: Breakpoint) {
        const existentBp = find(this.blockerBreakpoints, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.plyrInstance.pause();
            this.blockerBreakpoints = this.blockerBreakpoints.filter((breakpoint: Breakpoint) => breakpoint.id !== bp.id);
            this.disablePlayButton();
        }
    }

    onBreakpointRequired(bp: Breakpoint) {
        const existentBp = find(this.requiredBreakpoints, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.plyrInstance.pause();
            this.disablePlayButton();
        }
    }

    onBreakpointRequiredTask(bp: Breakpoint) {
        this.currentTask = new Task(cloneDeep(bp.relatedTask));

        const existentBp = find(this.requiredTasks, (breakpoint: Breakpoint) => {
            return bp.id === breakpoint.id;
        });

        if (existentBp) {
            this.plyrInstance.pause();
            this.taskModalComponent.open(bp);
            this.disablePlayButton();
        }
    }

    onBreakpointSuccess(bp: Breakpoint) {
        this.requiredBreakpoints = this.requiredBreakpoints.filter((breakpoint: Breakpoint) => (breakpoint.id !== bp.id));
        this.plyrInstance.play();
        this.enablePlayButton();
    }

    onTaskCompleted(bp: Breakpoint) {
        this.requiredTasks = this.requiredTasks.filter((breakpoint: Breakpoint) => (breakpoint.id !== bp.id));
        this.plyrInstance.play();
        this.enablePlayButton();
    }

    openBreakpointOpenTask(bp: Breakpoint) {
        this.taskModalComponent.open(bp);
    }

    handleSeeking(timeTo: number) {
        const nextReachableBreakpoins = [...this.requiredBreakpoints, ...this.requiredTasks]
            .filter((bp: Breakpoint) => (bp.start <= timeTo))
            .sort((a: Breakpoint, b: Breakpoint) => (a.start > b.start) ? 1 : -1);

        const nextBp: Breakpoint = get(nextReachableBreakpoins, '[0]', null);

        if (nextBp && this.plyrInstance) {
            this.plyrInstance.pause();
            this.plyrInstance.currentTime = (nextBp.start / 1000) + 0.5;
        }
    }

    render() {
        return <div className="videoPresenter relative task-presenter">
            {(this.state.error && !this.state.noVideo) && <TaskError description="Task configuration is incorrect"/>}
            {(this.state.error && this.state.noVideo) && <TaskError title="No Video uploaded" description=""/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="hd"/>}
            {!this.state.initialized && <Placeholder.Bar size="sm"/>}

            {this.state.initialized && !this.state.FS &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized && !this.state.FS &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}

            <div
                className={`w-100 video-ww position-relative ${this.state.initialized ? 'initialized' : 'uninitialized'}`}>
                {!this.props.editMode && <BreakpointPresenter
                    onBlock={(bp: Breakpoint) => this.onBreakpointBlock(bp)}
                    onRequired={(bp: Breakpoint) => this.onBreakpointRequired(bp)}
                    onTask={(bp: Breakpoint) => this.onBreakpointRequiredTask(bp)}
                    openTask={(bp: Breakpoint) => this.openBreakpointOpenTask(bp)}
                    onBreakpointSuccess={(bp: Breakpoint) => this.onBreakpointSuccess(bp)}
                    time={this.state.time}
                    breakpoints={get(this.props, 'config.breakpoints', [])}
                />}

                <ModalComponent
                    ref={(ref: ModalComponent) => (this.taskModalComponent = ref)}
                    onSuccess={(bp: Breakpoint) => this.onTaskCompleted(bp)}>

                    <TaskPresenter task={this.currentTask}/>
                </ModalComponent>

                <video id={this.canvasDomId}/>
            </div>

            {!this.props.editMode &&
            <SubtitlePresenter time={this.state.time} subtitles={get(this.props, 'config.subtitles', [])}/>}
        </div>;
    }
}
