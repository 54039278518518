import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import * as React from 'react';
import './atas-textfield.scss';

export class AtasTextfield extends React.Component<TextFieldProps, any> {

    render() {
        return React.createElement(TextField, {
            ...this.props,
            className: (this.props.className || '') + ' atas-textfield'
        });
    }
}
