import { get } from 'lodash';
import * as React from 'react';
import { AtasTextfield } from '../../../../src/libs/elements/textfield/atas-textfield';
import { Placeholder } from '../../../libs/elements/placeholder/placeholder';
import { TaskError } from '../../../libs/elements/task-error/task-error';
import { Repository } from '../../../libs/repository/repository';
import { TaskPresenterPropsInterface } from '../task-presenter/task-presenter-props.interface';
import './journal.presenter.scss';

export class JournalPresenter extends React.Component<TaskPresenterPropsInterface, any> {
    public state: any = {
        initialized: false,
        answer: '',
        previousValidations: get(this.props, 'config.previousValidations')
    };

    componentDidMount() {
        if (get(this.props, 'config.title')) {
            this.setState({
                initialized: true,
                error: false
            }, () => {
                this.emitSubmitable();
                this.showPreviousValidation();
            });
        } else {
            this.setState({
                error: true
            });
        }
    }

    handleTaskStateChange(key: string, value: string) {
        this.props.config.taskState.set(key, value);

        this.setState({
            [key]: value
        }, () => {
            this.emitSubmitable();
        });

        if (this.props.onState) {
            this.props.onState();
        }
    }

    public showPreviousValidation() {
        const answer = get(this.state.previousValidations, 'answer');

        if (answer) {
            this.setState({
                answer
            }, () => this.emitSubmitable());
        }
    }

    public async validateTask(): Promise<any> {
        try {

            const validationResponse: any = await Repository.post('/log/validate', {
                lessonTaskId: this.props.lessonTaskId,
                answer: this.state.answer
            });
            return Promise.resolve(validationResponse);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public emitSubmitable() {
        this.props.config.taskState.set('submitable', !!this.state.answer.length);
        if (this.props.onState) {
            this.props.onState();
        }
    }

    public render() {
        return <div className="journalPresenter relative task-presenter">
            {this.state.error && <TaskError description="Task configuration is incorrect"/>}
            {!this.state.initialized && <Placeholder.Bar size="xl"/>}
            {!this.state.initialized && <Placeholder.Bar size="md"/>}
            {!this.state.initialized && <Placeholder.Bar size="huge"/>}
            {this.state.initialized &&
            <h3 className="title lead-text">{get(this.props, 'config.title', 'TASK_TITLE')}</h3>}
            {this.state.initialized &&
            <div className="description"
                 dangerouslySetInnerHTML={{__html: get(this.props, 'config.description', 'TASK_DESCRIPTION')}}/>}
            {this.state.initialized && <div className="w-100">
                <AtasTextfield
                    value={this.state.answer}
                    className="col"
                    multiline={true}
                    onChange={(e: any) => this.handleTaskStateChange('answer', e.target.value)}
                    placeholder="Type your answer here…"/>
            </div>}
        </div>;
    }
}
