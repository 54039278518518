import {createHashHistory} from 'history';
// Ha ráformázol index.scss legyen legalul !!!
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Redirect, Route, RouteProps, Router, Switch} from 'react-router';
import {CompanyDetailsComponent} from './components/company-details/company-details.component';
import {CompanyLessonRoll} from './components/company-lesson-roll/company-lesson-roll';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LessonCreatorComponent} from './components/e-module-creator/lesson-creator/lesson-creator.component';
import {TaskCreatorComponent} from './components/e-module-creator/task.creator.component';
/*
import { JournalPage } from './components/journal-page/journal-page';
*/
import {LessonDetailsComponent} from './components/lesson-details/lesson-details.component';
/*import {ReportsPage} from './components/reports-page/reports-page';
import {Reports} from './components/reports/reports';*/
import {SettingsComponent} from './components/settings/settings.component';
import {AuthorizedLayout} from './layouts/authorized.layout';
import './libs/bootstrap/bootstrap.scss';
import {environment} from './libs/environment';
import './libs/font-awesome/scss/fa-brands.scss';
import './libs/font-awesome/scss/fa-light.scss';
import './libs/font-awesome/scss/fa-regular.scss';
import './libs/font-awesome/scss/fa-solid.scss';
import './libs/font-awesome/scss/fontawesome.scss';
import {Repository} from './libs/repository/repository';
import './libs/slick-carousel/slick/slick-theme.scss';
import './libs/slick-carousel/slick/slick.scss';
import {Translation} from './libs/translation';
import './index.scss';

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    HA MÓDOSÍTASZ AZ INDEXET RAKD A VÉGÉRE
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

if (environment.mock) {
    Repository.init();
}

try {

    Translation.fetchTranslations()
        .then(() => {
            Translation.setLanguage('en');
        })
        .catch((err) => {
            throw err;
        });

} catch (err) {
    alert('can\'t initialize translation');
}
(window as any)._history = createHashHistory();

ReactDOM.render(
    <Router history={(window as any)._history}>
        <Switch>
            <Route path={'/mng/:companySiteSpecId?/:teritory?/:teritoryId?'} component={(properties: RouteProps) => {
                return <AuthorizedLayout routeProps={properties}>
                    <Switch>
                        <Route exact path={'/mng'} component={SettingsComponent}/>
                        <Route exact path={'/mng/:companySiteSpecId?/lesson/:lessonId'}
                               component={LessonCreatorComponent}/>
                        <Route exact path={'/mng/:companySiteSpecId?/task'} component={TaskCreatorComponent}/>
                        <Route exact path={'/mng/:companySiteSpecId?/task/:taskId'}
                               component={() => <h3>MNG Task details</h3>}/>
                        <Route component={() => <Redirect to={'/dashboard'}/>}/>
                    </Switch>
                </AuthorizedLayout>;
            }}/>

            <Route path={'/cl/:companySiteSpecId'} component={(properties: RouteProps) => {
                return <AuthorizedLayout disableNav={true} routeProps={properties}>
                    <Switch>
                        <Route exact path={'/cl/:companySiteSpecId'} component={CompanyLessonRoll}/>
                    </Switch>
                </AuthorizedLayout>;
            }}/>
            <Route path={'/:teritory?/:companySiteSpecId?/:lessonId?'} component={(properties: RouteProps) => {
                return <AuthorizedLayout routeProps={properties}>
                    <Switch>
                        <Route exact path={'/'} component={() => <Redirect to={'/dashboard'}/>}/>
                        <Route exact path={'/dashboard'} component={DashboardComponent}/>
                        <Route exact path={'/reports'} component={() => <Redirect to={'/dashboard'}/>}/>
                        <Route exact path={'/reports-pdf'} component={() => <Redirect to={'/dashboard'}/>}/>
                        <Route exact path={'/journal'} component={() => <Redirect to={'/dashboard'}/>}/>
                        <Route exact path={'/cs/:companySiteSpecId'} component={CompanyDetailsComponent}/>
                        <Route exact path={'/cs/:companySiteSpecId/:lessonId'} component={LessonDetailsComponent}/>
                        <Route component={() => <Redirect to={'/dashboard'}/>}/>
                    </Switch>
                </AuthorizedLayout>;
            }}/>

        </Switch>
    </Router>,
    document.getElementById('appRoot')
);
