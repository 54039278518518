import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isEqual } from 'lodash';
import * as React from 'react';
import { AtasTextfield } from '../../../libs/elements/textfield/atas-textfield';
import { Repository } from '../../../libs/repository/repository';
import { Choice } from '../../../mock/entities/choice';
import './task-setup.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class MultipleChoiceQuestionTypeSetup extends React.Component<any, any> {
    public state: any = {
        task: this.props.task
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!isEqual(prevProps.task, this.props.task)) {
            this.setState({
                task: this.props.task
            });
        }
    }

    handlePropertyChange(prop: string, value: any) {
        this.setState({
            task: {
                ...this.state.task,
                [prop]: value
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handleChangeQuestion(text: string) {
        this.state.task.config.question = text;
        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    handleChangeMulti() {
        this.state.task.config.multi = !this.state.task.config.multi;

        setTimeout(() => {
            if (!this.state.task.config.multi) {
                const firstChecked = (this.state.task.config.choices || []).filter((i: any) => i.correct)[0];

                this.state.task.config.choices = (this.state.task.config.choices || []).map((i: any) => {
                    if (firstChecked && (i.id === firstChecked.id)) {
                        i.correct = true;
                        return i;
                    } else {
                        i.correct = false;
                        return i;
                    }
                });
            }

            setTimeout(() => {
                this.setState({
                    task: this.state.task
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.task);
                    }
                });
            }, 30);
        }, 30);
    }

    templateChoice(choice: Choice, index: number) {
        return <div className="flex" key={index}>
            <div className="flex-1">
                <AtasTextfield
                    onChange={(event: any) => this.onChoiceChange('choice', event.target.value, index)}
                    label="Choice Text"
                    value={choice.choice}
                />
            </div>
            <div>
                <FormControlLabel label="Correct choice" control={
                    <Checkbox
                        checked={choice.correct}
                        onChange={(event: any) => this.onChoiceChange('correct', event.target.value, index)}
                    />
                }/>
            </div>
            <div>
                <i className="fal fa-trash-alt text-red pt-3 pointer" onClick={() => this.deleteNewChoice(choice)}/>
            </div>
        </div>;
    }

    onChoiceChange(property: string, value: any, index: number) {
        if (property === 'correct') {

            if (!this.state.task.config.multi) {
                this.state.task.config.choices = (this.state.task.config.choices || []).map((i: any) => {
                    i.correct = false;
                    return i;
                });
            }

            this.state.task.config.choices[index][property] = !this.state.task.config.choices[index][property];

        } else {
            this.state.task.config.choices[index][property] = value;
        }

        this.setState({
            task: this.state.task
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.task);
            }
        });
    }

    async addNewChoice() {
        try {
            const choice = await Repository.post('/choice', {
                correct: false,
                choice: 'NEW CHOICE'
            });

            this.state.task.config.choices.push(choice);
            this.setState({
                task: this.state.task
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    async deleteNewChoice(choice: Choice) {
        try {
            await Repository.delete(choice['@id']);

            this.state.task.config.choices = this.state.task.config.choices.filter((c: any) => {
                return choice.id !== c.id;
            });

            this.setState({
                task: this.state.task
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.task);
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    public render() {
        return <div className="audioTaskSetup">
            <div className="audio-upload">

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('title', event.target.value)}
                        label="Title"
                        value={this.state.task.title}
                    />
                </div>
                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handlePropertyChange('lead', event.target.value)}
                        label="Short description"
                        value={this.state.task.lead}
                    />
                </div>
                <div className="col-xs-12 wysiwyg-container">
                    <span className="wysiwyg-label">Description</span>

                    <ReactQuill
                        bounds={'.wysiwyg-container'}
                        value={this.state.task.description}
                        onChange={(value: any) => this.handlePropertyChange('description', value)}/>
                </div>

                <div className="col-xs-12">
                    <AtasTextfield
                        onChange={(event: any) => this.handleChangeQuestion(event.target.value)}
                        label="Question"
                        value={this.state.task.config.question}
                    />
                </div>

                <div className="col-xs-12">
                    <FormControlLabel label="Is multiple choice" control={
                        <Checkbox
                            checked={this.state.task.config.multi}
                            onChange={(event: any) => this.handleChangeMulti()}
                        />
                    }/>
                </div>

                <div className="card">
                    <div className="card-header flex justify-content-between align-items-center">
                        <span>Choices</span>
                        <button className="btn-sm btn-atas" onClick={() => this.addNewChoice()}>
                            <i className="fal fa-plus"/>
                            <span>&nbsp; Add</span>
                        </button>
                    </div>
                    <div className="card-body">
                        {(this.state.task.config.choices || []).map((choice: Choice, index: number) => this.templateChoice(choice, index))}
                    </div>
                </div>

            </div>
        </div>;
    }
}
