import * as React from 'react';
import './empty-list.scss';

export class EmptyList extends React.Component<any, any> {

    public render() {
        return <div className="emptyList">
            <div className="text-center">
                <i className="far fa-times-circle" />
                <h3>{this.props.elText}</h3>
            </div>
        </div>;
    }
}
