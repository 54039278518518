import { Map } from 'immutable';
import 'jquery';
import { compact, each, get, sortBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { AtasBtn } from '../../../libs/elements/atas.btn';
import { FeedbackToast } from '../../../libs/elements/feedback-toast/feedback-toast';
import { Loader } from '../../../libs/elements/loader/loader';
import { Repository } from '../../../libs/repository/repository';
import { Storage } from '../../../libs/storage';
import { Lesson } from '../../../mock/entities/lesson';
import { LessonTask } from '../../../mock/entities/lessonTask';
import { HeroComponent } from '../../hero/hero.component';
import { EndOfModulePresenter } from '../end-of-module/end-of-module.presenter';
import { TaskThumbnailPresenter } from '../task-presenter/task-thumbnail.presenter';
import { TaskPresenter } from '../task-presenter/task.presenter';
import './lesson.presenter.scss';

export class LessonPresenter extends React.Component<any, any> {
    private slickRef: Slider;
    private currentTaskRef: TaskPresenter;
    private toastRef: any;
    private storage = new Storage();

    public settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    public state: any = {
        lesson: null,
        company: this.props.company,
        currentTask: 0,
        isLoading: true,
        config: null,
        error: false,
        isRepeatable: true,
        validation: {},
        currentTaskSubmitable: false,
        previousValidations: {}
    };

    public isShowFinishLessonButton() {
        return ((this.state.lesson.lessonTasks || []).filter((item: any) => item.task.previousValidations)).length > 0;
    }

    public async fetchLesson(): Promise<any> {
        try {
            if (!this.props.iri) {
                throw new Error();
            }

            this.setState({isLoading: true});

            const response = await Repository.get(get(this.props, 'iri') + '/full');
            const lesson = new Lesson(response);

            lesson.lessonTasks = (sortBy(get(lesson, 'lessonTasks'), 'position'));
            return Promise.resolve(lesson);
        } catch (err) {
            console.error('Failed to [lesson.presenter].fetchLesson()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
            return Promise.reject(err);

        }
    }

    public async updateLessonState(lesson: any) {
        try {
            const response: any = await Repository.get(`/user/${get(this.storage.getItem('UserProfile'), 'id')}/lesson/${lesson.id}`);

            return Promise.resolve({
                ...lesson,
                lessonTasks: (lesson.lessonTasks || []).map((item: LessonTask, key: any) => {
                    item.task.previousValidations = response.progress[item.id];
                    return item;
                }),
                lessonFinished: !!response.finished
            });
        } catch (err) {
            console.error('Failed to [lesson.presenter].updateLessonState(lesson)', lesson);
            console.error(err);
            return Promise.reject(err);
        }
    }

    public getCurrentTaskId(): string {
        return get(this.state, `lesson.lessonTasks[${this.state.currentTask}].task.id`, -1).toString() + '_t';
    }

    public goToTask(index: number) {
        this.setState({
            currentTask: index
        }, () => this.resetIsValidateValues());
    }

    public resetIsValidateValues() {
        each(this.state.validation, (value: any, key: any) => {
            this.state.validation[key].isValidated = false;
        });

        setTimeout(() => {
            this.setState({
                validation: this.state.validation
            });
        });
    }

    scrollTo(id: string) {
        $('html, body').animate({scrollTop: ($(`#${id}`) as any).offset().top}, 400);
    }

    public async goToNext() {
        try {
            if (this.state.validation[`task-${this.getCurrentTaskId()}`] && this.state.validation[`task-${this.getCurrentTaskId()}`].isValidated) {
                if (get(this.state, 'currentTask', 0) < get(this.state, 'lesson.lessonTasks', []).length - 1) {

                    if (this.slickRef) {
                        this.slickRef.slickGoTo(this.state.currentTask + 1);
                    }

                    this.setState({
                        currentTask: this.state.currentTask + 1,
                        currentTaskSubmitable: false,
                        validation: this.state.validation
                    }, () => this.resetIsValidateValues());
                } else {
                    await this.finalizeLesson();
                }
                this.scrollTo('currentTaskTop');
            } else {

                this.state.validation[`task-${this.getCurrentTaskId()}`] = {
                    isValidated: true,
                    isValid: await this.currentTaskRef.validate()
                };

                this.setState({
                    lesson: await this.updateLessonState(this.state.lesson),
                    validation: this.state.validation
                });
            }
        } catch (err) {
            console.error('Failed to [lesson.presenter].goToNext()');
            console.error(err);
            this.toastRef.showToast('Something went wrong!', 'error');
        }
    }

    public async finalizeLesson() {
        try {
            await Repository.post('/lesson/finish', {
                lessonId: this.state.lesson.id
            });

            this.setState({
                lesson: await this.updateLessonState(this.state.lesson)
            });
        } catch (err) {
            console.error('Failed to [lesson.presenter].finalizeLesson()');
            console.error(err);
            throw err;
        }
    }

    public goToPrev() {
        if (get(this.state, 'currentTask', 0) > 0) {
            if (this.slickRef) {
                this.slickRef.slickGoTo(this.state.currentTask - 1);
            }

            this.setState({
                currentTask: this.state.currentTask - 1
            }, () => this.resetIsValidateValues());
        }
        this.scrollTo('currentTaskTop');
    }

    public async componentDidMount() {
        try {
            const lesson = await this.fetchLesson();
            const rebasedLesson = await this.updateLessonState(lesson);

            this.setState({
                lesson: rebasedLesson,
                isLoading: false
            }, () => {

                const firstUnfinishedLessonIndex = this.getFirstUnfinishedLessonTaskIndex(rebasedLesson.lessonTasks);

                if (firstUnfinishedLessonIndex > -1) {
                    this.goToTask(firstUnfinishedLessonIndex);
                } else {
                    this.goToTask(0);
                }
            });
        } catch (err) {
            console.error('Failed to [lesson.presenter].componentDidMount()');
            console.error(err);
            this.setState({
                error: true,
                isLoading: false
            }, () => {
                this.goToTask(0);
                this.toastRef.showToast('Something went wrong!', 'error');
            });
        }
    }

    handleCurrentTaskStateUpdated(taskState: Map<string, any>) {
        const ts: any = taskState.toJS() || {};
        this.resetIsValidateValues();

        if (ts.submitable !== undefined) {
            this.setState({
                currentTaskSubmitable: ts.submitable
            });
        }
    }

    getFirstUnfinishedLessonTaskIndex(lessonTasks: any): number {
        const l: any = lessonTasks.map((lt: any, index: number) => {
            if (lt.task.previousValidations) {
                return null;
            } else {
                return index + 1;
            }
        });

        return get(compact(l), '[0]', -1) - 1;
    }

    public render() {
        return !this.state.error ? <div id="currentTaskTop">
            <HeroComponent
                cover={get(this.state, 'lesson.cover.images.original')}
                logo={get(this.state, 'company.logo.original')}/>

            {get(this.state, 'lesson.lessonFinished') && <EndOfModulePresenter lesson={this.state.lesson}/>}

            {!get(this.state, 'lesson.lessonFinished') && <div className="lessonPresenter">

                <div className="container">
                    <h1>{get(this.state.lesson, 'title', '')}</h1>
                    <span
                        className="index-text">{((get(this.state.lesson, 'lessonTasks', [])).length) ? this.state.currentTask + 1 : 0} of {get(this.state.lesson, 'lessonTasks', []).length}</span>

                    {!!((get(this.state.lesson, 'lessonTasks', [])).length) && !(this.state.completed) &&
                    <div className="taskContainer">
                        <TaskPresenter
                            disabled={get(this.state, `validation[${`task-${this.getCurrentTaskId()}`}].isValidated`, false)}
                            lessonTask={get(this.state.lesson, `lessonTasks[${this.state.currentTask}]`, null)}
                            ref={(ref: TaskPresenter) => (this.currentTaskRef = ref)}
                            task={get(this.state.lesson, `lessonTasks[${this.state.currentTask}].task`, null)}
                            onState={() => this.handleCurrentTaskStateUpdated(get(this.state.lesson, `lessonTasks[${this.state.currentTask}].task.taskState`))}
                        />
                    </div>}
                </div>

                {!!((get(this.state.lesson, 'lessonTasks', [])).length) && <div>
                    <div className="taskControls flex align-items-center flex-row">
                        <div className="container">
                            <div className="flex">
                                <Link to="/dashboard" className="btn-atas quit-button">Pause Lesson</Link>
                                {this.isShowFinishLessonButton() && <AtasBtn
                                    disabled={((get(this.state.lesson, 'lessonTasks', [])).length) <= this.state.currentTask}
                                    onClick={() => this.finalizeLesson()}>

                                    <span>
                                        Finish
                                    </span>
                                </AtasBtn>}
                            </div>
                            <div className="buttons-wrap">
                                <AtasBtn disabled={0 >= this.state.currentTask} className="btn-atas"
                                         onClick={() => this.goToPrev()}>
                                    <i className="fal fa-chevron-circle-left"/>&nbsp;&nbsp;
                                    <span>Previous</span>
                                </AtasBtn>

                                {this.state.currentTaskSubmitable && <AtasBtn
                                    disabled={((get(this.state.lesson, 'lessonTasks', [])).length) <= this.state.currentTask}
                                    onClick={() => this.goToNext()}>

                                    <span>
                                        {this.state.validation[`task-${this.getCurrentTaskId()}`] && this.state.validation[`task-${this.getCurrentTaskId()}`].isValidated ? 'Next' : 'Submit'}
                                    </span>

                                    &nbsp;
                                    &nbsp;

                                    <i className="fal fa-chevron-circle-right"/>
                                </AtasBtn>}

                                {!this.state.currentTaskSubmitable && <AtasBtn className="disabled">
                                    <span>Submit</span>

                                    &nbsp;
                                    &nbsp;

                                    <i className="fal fa-chevron-circle-right"/>
                                </AtasBtn>}

                            </div>
                        </div>
                    </div>

                    <div className="taskCarousel">
                        <div className="container">
                            <Slider ref={(ref: Slider) => (this.slickRef = ref)} {...this.settings}>

                                {get(this.state.lesson, 'lessonTasks', []).map((lessonTask: LessonTask, index: number) => {
                                    return <div className="col" key={index}>
                                        <TaskThumbnailPresenter
                                            isCurrentTask={index === get(this.state, 'currentTask')}
                                            onClick={() => this.goToTask(index)}
                                            type={get(lessonTask, 'task.type')}
                                            title={get(lessonTask, 'task.title')}
                                            lead={get(lessonTask, 'task.lead')}
                                            description={get(lessonTask, 'task.description')}
                                            isRepeatable={this.state.isRepeatable}
                                        />
                                    </div>;
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>}
                <FeedbackToast ref={(ref: any) => this.toastRef = ref}/>
                <Loader isLoading={this.state.isLoading}/>
            </div>}

        </div> : <h3>Network Error</h3>;
    }
}
